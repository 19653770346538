<template>
  <svg
    class="svg-icon"
    :style="{ width: size + 'px', height: size + 'px' }"
    aria-hidden="true"
  >
    <use :xlink:href="iconName" />
    <path />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "30",
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
  },
};
</script>

<style scoped>
/*此处为所有图标默认显示样式*/
.svg-icon {
  overflow: hidden;
}
</style>
