import Vue from "vue"
import store from "@/store"
import "@/utils/firebase"  //firebase数据库
import "@/utils/mixpanel"  //mixpanel埋点
import "@/utils/facebookPixel" //facebookPixel埋点
import '@/assets/icons/index' //svgIcon
import "@/config/language/index" //多语言配置
import "@/utils/impotconfig"
import utils from "@/utils/utils"
import "@/utils/sentry"
import { initStatsig } from "@/utils/statsig"
import "@/vant/index" //vant按需引入

async function init() {
    const statsigClient = await initStatsig();
    store.commit("setStatsigClient", statsigClient)
}
init()
Vue.prototype.$utils = utils;




