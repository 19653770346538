<template>
  <div class="templateEventCalendar   ">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div @click="showCalendar = false">
          <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
          <div class="sub-title text_2 tc" v-html="templateData.languageConfig[$language].mainText"></div>
        </div>
        <div class="date-warp">
          <div :class="{ active: showCalendar }" @click.stop="showCalendar = !showCalendar"
            class="ipt-warp flex justify-between align-center">
            <div class="content">
              <!-- <div class="ipt-tip">Select Date</div> -->
              <div class="text_2 theme_01">{{ $utils.getDate(templateValue) }}</div>
            </div>
            <svg-icon icon-class="calendar" size="26" />
          </div>
        </div>
        <Calendar v-show="showCalendar" :min-date="minData + ''" class="calendar-date" format="MM/dd/yyyy"
          :value="templateValue" :pane="1" :has-input="false" :on-day-click="dayClick" />
      </div>
    </div>

    <div class="btn-warp" v-show="animationLoading && templateData">
      <div @click="
        updateOnBoardingRecord({
          key: 'eventDateSkip',
          value: false,
        }),
        selectOption($utils.formatTimestamp(templateValue)), setOptionClickAnimation(-1)
        " :class="{ 'shrink-grow-effect': activeIndex === -1 }" class="button active text_1"
        v-html="templateData.languageConfig[$language].buttonText"></div>
      <div @click="
        updateOnBoardingRecord({
          key: 'eventDateSkip',
          value: true,
        }),
        selectOption($utils.formatTimestamp(templateValue))
        " class="jump-link tc text_2 theme_01 pointer">
        {{ templateData.languageConfig[$language].skipText }}
      </div>
    </div>
  </div>
</template>
<script>
// 参数文档：https://www.cnblogs.com/alax/p/10649295.html
import Calendar from "vue2-slot-calendar"; // 日历插件
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
export default {
  name: "templateEventCalendar",
  mixins: [mixinTemplate],
  components: {
    Head,
    Calendar,
  },
  data() {
    return {
      showCalendar: false,
      minData: new Date(),
    };
  },
  created() {
    // 当前日期+1
    var currentDate = new Date();
    this.minData = new Date(currentDate.setDate(currentDate.getDate() + 1))
    if (this.$store.state.onBoardingRecordInfo.eventDateScreen) {
      this.templateValue = this.$utils.formattedDate(
        this.$store.state.onBoardingRecordInfo.eventDateScreen,
        "MM/DD/YYYY"
      );
    } else {
      this.templateValue = this.$utils.getAnyMonth(3); //3个月后的日期
    }
  },
  methods: {
    // 点击日期
    dayClick(day) {
      this.showCalendar = false;
      if (this.$utils.momentDifference(new Date(), day) >= 0) {
        this.templateValue = day;
      }

    },
  },
};
</script>
<style scoped lang="scss">
.template-btn {
  padding-bottom: 150px !important;
}

.templateEventCalendar {

  .date-warp {
    .ipt-warp {
      margin: 24px 0 10px 0;
      padding: 13px 16px;
      width: 100%;
      background: var(--theme_03);
      border-radius: 8px;
      cursor: pointer;
      border: 2px solid var(--theme_03);

      .ipt-tip {
        font-size: 14px;
        color: var(--mainColor);
      }
    }

    .active {
      border: 2px solid var(--mainColor);
    }
  }

  .jump-link {
    padding: 10px 0;
  }
}
</style>
<style>
.calendar-date,
.datepicker-wrapper,
.datepicker-popup,
.datepicker-inner {
  width: 100% !important;
}



.datepicker-body span {
  width: 14%;
  height: 40px;
}

.datepicker-body span div {
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
}

.datepicker-dateRange-item-active,
.datepicker-dateRange-item-active:hover,
.datepicker-dateRange-item-active,
.datepicker-dateRange span:hover {
  background: none !important;
  color: var(--theme_01) !important;
}

.datepicker-dateRange-item-active div {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--theme_03) !important;
  border-radius: 50%;
  background-color: var(--mainColor) !important;
}

.datepicker-dateRange-item-active div div {
  display: none;
}

.datepicker-item-disabled {
  text-decoration: none;
  color: #d1d1d1;
  cursor: default !important;
}

.datepicker-item-disabled :hover {
  color: #d1d1d1 !important;
}

.datepicker-item-disabled div {
  font-weight: 500 !important;
}

@media (max-width: 360px) {
  .datepicker-body span div {
    line-height: 25px;
  }

  .datepicker-dateRange-item-active div {
    margin: 0 auto;
    width: 25px;
    height: 25px;
    line-height: 25px;
    color: var(--theme_03) !important;
    border-radius: 50%;
    background-color: var(--mainColor) !important;
  }
}
</style>
