
import Vue from 'vue'
import Vuex from 'vuex'
import { updateFirestoreData } from "@/utils/firebase";
import { setmMxpanelValue } from "@/utils/mixpanel"
import { setFbqTrackCustom } from "@/utils/facebookPixel"
import { setGtag } from "@/utils/ga4"
import webConfig from '@/config/web.config';
import utils from '@/utils/utils';
Vue.use(Vuex)
const store = new Vuex.Store(
    {
        state: {
            statsigClient: null,
            progress: 0,
            oldProgress: 0,
            userInfo: null,
            showMenu: false, //是否展示菜单
            templateFlow: [], //模版渲染顺序
            boardingFlow: [],
            onBoardingRecordInfo: {
                currentTemplateIndex: 0,//当前模版序号
                currentBoardingIndex: 0, //当前boarding流程模版序号
                userProperty: {
                    user_source: 'web'
                },
                chroincs: [],
                during: "",
                interesteds: [],

                name: '',
                equipment: ['No Equipment']
                // isOfficial: false, //是否正式版

            },// 收集信息
        },
        mutations: {
            //设置statsigClient实例
            setStatsigClient(state, statsigClient) {
                state.statsigClient = statsigClient
            },
            resetOnBoardingRecordInfo(state) {
                state.onBoardingRecordInfo = {
                    currentTemplateIndex: 0,//当前模版序号
                    currentBoardingIndex: 0, //当前boarding流程模版序号
                    userProperty: {
                        user_source: 'web'
                    },
                    equipment: ['No Equipment'],
                    chroincs: [],
                    during: "",
                    interesteds: [],

                }
            },
            updateOldProgress(state, progress) {
                state.oldProgress = progress
            },
            updateProgress(state, progress) {
                state.progress = progress > 100 ? 100 : progress
            },
            // 切换菜单
            toogleMenu(state, bool = false) {
                if (!bool) {
                    state.showMenu = !state.showMenu
                } else {
                    state.showMenu = false
                }
            },
            //初始化模版数据
            initTemplateFlow(state, templateIfo) {
                state.templateFlow = templateIfo.templateFlow;
                state.boardingFlow = state.templateFlow.filter(item => item.isStepPage);
            },

            //跳转下一个模版
            nextTemplate(state, skipeNumber) {
                //模版跳过
                if (skipeNumber) {
                    state.onBoardingRecordInfo.currentTemplateIndex += skipeNumber;
                    window.sessionStorage.setItem('onBoardingRecordInfo', JSON.stringify(state.onBoardingRecordInfo))
                    return
                }
                // 是否为最后一题
                if (state.onBoardingRecordInfo.currentTemplateIndex < state.templateFlow.length - 1) {
                    state.onBoardingRecordInfo.currentTemplateIndex++;
                    let index = state.boardingFlow.findIndex(
                        item => state.templateFlow[state.onBoardingRecordInfo.currentTemplateIndex].screenName == item.screenName
                    )
                    if (index != -1) {
                        state.onBoardingRecordInfo.currentBoardingIndex = index + 1;
                    }
                    window.sessionStorage.setItem('onBoardingRecordInfo', JSON.stringify(state.onBoardingRecordInfo))
                    let currentTemplate = state.templateFlow[state.onBoardingRecordInfo.currentTemplateIndex];
                    let routerLink = currentTemplate.routerLink;
                    if (routerLink) {
                        utils.routerReplace(`/${routerLink}`)
                    }
                }
                // 保存记录
                else {
                    if (!state.onBoardingRecordInfo.uid) {
                        let uid = window.sessionStorage.getItem('uid');
                        uid ? state.onBoardingRecordInfo.uid = uid : ""
                    }
                    setmMxpanelValue(webConfig.mixpanelConfig.onboardingEventKey, 'Completed')
                    setFbqTrackCustom("Onboarding Completed")
                    setGtag("Web Onboarding Completed")
                    utils.routerReplace('/paywall-page')
                }
            },
            //跳转上一个模版
            lastTemplate(state, eventScreenJumpNumber = 1) {
                // 是否为第一题
                if (state.onBoardingRecordInfo.currentTemplateIndex > 0) {
                    state.onBoardingRecordInfo.currentTemplateIndex -= eventScreenJumpNumber;
                    let index = state.boardingFlow.findIndex(
                        item => state.templateFlow[state.onBoardingRecordInfo.currentTemplateIndex].screenName == item.screenName
                    )
                    if (index != -1) {
                        state.onBoardingRecordInfo.currentBoardingIndex = index + 1;
                    }
                    window.sessionStorage.setItem('onBoardingRecordInfo', JSON.stringify(state.onBoardingRecordInfo));
                    let currentTemplate = state.templateFlow[state.onBoardingRecordInfo.currentTemplateIndex];
                    let routerLink = currentTemplate.routerLink;
                    if (routerLink && routerLink != 'generated-questionnaire-page') {
                        utils.routerReplace(`/${routerLink}`)
                    }
                }
            },
            // 页面跳转更新模版下标
            updateOnBoardingIndex(state, screenName) {
                let index = state.templateFlow.findIndex(item => item.screenName == screenName);
                //更新当前模版序号/当前boarding流程模版序号
                state.onBoardingRecordInfo.currentTemplateIndex = index;
                window.sessionStorage.setItem('onBoardingRecordInfo', JSON.stringify(state.onBoardingRecordInfo));
            },
            //更新信息收集选项记录
            updateOnBoardingRecord(state, info) {
                if (info) {
                    let key = webConfig.onBoardingKey[info.key];
                    key = (key ? key : info.key)
                    state.onBoardingRecordInfo[key] = info.value; //存入vuex
                    let obj = {};
                    obj[key] = info.value;
                    //存入本地缓存
                    window.sessionStorage.setItem('onBoardingRecordInfo', JSON.stringify(state.onBoardingRecordInfo))
                    // 存入firebase
                    if (!info.noSet) {
                        updateFirestoreData(state.onBoardingRecordInfo.uid, obj)
                    }

                }
            },
            //更新整体记录
            updateOnBoardingRecordInfo(state, onBoardingRecordInfo) {
                state.onBoardingRecordInfo = onBoardingRecordInfo;
                window.sessionStorage.setItem('onBoardingRecordInfo', JSON.stringify(state.onBoardingRecordInfo))
            },

            // 更新用户信息
            updateuserInfo(state, userInfo) {
                state.userInfo = userInfo;
                userInfo ? window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo)) : window.sessionStorage.removeItem('userInfo')
            }
        },
        getters: {
            // 获取当前模版配置项
            templateData: state => {
                return import(`@/config/onboarding.config/${state.templateFlow[state.onBoardingRecordInfo.currentTemplateIndex].screenName}`)
            },
            nextTemplateData: state => {
                if (state.onBoardingRecordInfo.currentTemplateIndex + 1 > state.templateFlow.length - 1) {
                    return null
                } else {
                    return import(`@/config/onboarding.config/${state.templateFlow[state.onBoardingRecordInfo.currentTemplateIndex + 1].screenName}`)

                }
            },
            // boarding进度总数
            boardingTotal: state => state.boardingFlow.length,
            //用户登陆信息
            userInfo: state => state.userInfo,
            currentTemplateFlow: state => state.templateFlow[state.onBoardingRecordInfo.currentTemplateIndex]
        }
    }
)

Vue.prototype.$store = store;
export default store
