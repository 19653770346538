<template>
    <div class="templateBodypart">

        <Head></Head>
        <div class="template-content" v-if="animationLoading && templateData">
            <div class="animation">
                <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
                <div class="flex content">
                    <div>
                        <img class="img animation-img" :src="require(`@img/template/${templateData.mainImage}`)" alt="">
                    </div>
                    <van-checkbox-group class="option-group" v-model="templateValue">
                        <van-checkbox icon="-" class="option-item short-option-item" :name="item.originName"
                            v-for="(item, index) in templateData.languageConfig[$language].optionList" :key="index">
                            <div class=" tc text_1">
                                {{ item.option }}
                            </div>
                        </van-checkbox>
                    </van-checkbox-group>
                </div>
            </div>
        </div>
        <div class="btn-warp" v-show="animationLoading && templateData">
            <div @click="contimueClick(true), setOptionClickAnimation(-1)" class="button  text_1"
                :class="{ active: templateValue != '', 'shrink-grow-effect': activeIndex === -1 }"
                v-html="templateData.languageConfig[$language].buttonText">
            </div>
        </div>
    </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
export default {
    name: "templateBodypart",
    mixins: [mixinTemplate],
    components: {
        Head
    },

}
</script>
<style scoped lang="scss">
.templateBodypart {
    .img {
        margin-right: 20px;
        width: 150px;
    }



    .content {
        margin-top: 20px;

        .option-group {
            flex: 1;
            max-width: calc(100% - 157px);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;

            .option-item {
                margin-bottom: 15px;
                padding: 18px;
                width: 48%;
                overflow-wrap: break-word;

                /deep/.van-checkbox__icon {
                    display: none !important;
                }
            }
        }
    }


}

@media (max-width: 576px) {
    .templateBodypart {
        .img {
            width: 137px;
        }

        .content {
            .option-group {
                .option-item {
                    margin-bottom: 10px;
                    padding: 13px;
                    width: 100%;
                }
            }
        }
    }
}
</style>