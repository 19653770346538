<template>
  <div ref="templateRef" class="templateSingleOption ">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc"
          v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"></div>
        <div class="sub-title text_2 tc" v-html="templateData.languageConfig[$language].mainText"></div>
        <img v-if="templateData.mainImage" class="main-img" :src="require(`@img/template/${templateData.mainImage}`)"
          alt="" />
        <div class="option-group">
          <div class="option-item" :class="{
            active: item.originName == templateValue, 'margin-left': templateData.imageFull && templateData.imagePosition == 'left',
            'margin-right': templateData.imageFull && templateData.imagePosition == 'right', 'shrink-grow-effect': activeIndex === index
          }" @click.stop="optionClick(item.originName, index, item.option)"
            v-for="(item, index) in templateData.languageConfig[$language].optionList">
            <div class="option flex justify-between align-center pointer">
              <!-- 左侧图片 -->
              <img
                v-if="templateData.imageList && templateData.imageList[index] && templateData.imagePosition == 'left'"
                class="option-img left-img" :height="templateData.imageSize"
                :src="require(`@img/template/${templateData.imageList[index]}`)" alt="" />
              <div class="text-warp flex-auto">
                <div class="text theme_01 text_1" v-html="item.option"></div>
                <div class="sub-text theme_02 text_3" v-html="item.subOption"></div>
              </div>
              <!-- 右侧图片 -->
              <img v-if="
                templateData.imageList &&
                templateData.imageList[index] && templateData.imagePosition == 'right'
              " class="option-img right-img" :height="templateData.imageSize"
                :src="require(`@img/template/${templateData.imageList[index]}`)" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin/index";

export default {
  name: "templateSingleOption",
  mixins: [mixinTemplate],
  components: {
    Head

  },
  methods: {
    optionClick(item, index, languageOption) {
      this.setOptionClickAnimation(index);
      //跳过下一页
      if (this.templateData.screenName == 'eventScreen' && index == this.templateData.languageConfig[this.$language].optionList.length - 1) {
        let number = 2;
        window.sessionStorage.setItem('eventScreenJumpNumber', number);//跳过标识
        this.$store.commit('nextTemplate', number);//跳过下一页
        this.updateOnBoardingRecord({
          key: 'eventDateSkip',
          value: true,
        })
        this.templateValue = item;
        this.setmMxpanelData()//埋点
        this.updateOnBoardingRecord({
          key: this.templateData.screenName,
          value: item,
        })
      } else {
        this.selectOption(item, true, languageOption)

      }
    }
  }
};
</script>
<style scoped lang="scss">
.templateSingleOption {


  .main-img {
    margin: 14px 0 -6px 0;
    width: 100%;
    border-radius: 16px;
  }

  .option-group {
    margin-top: 30px;

    .option-item {
      padding: 0 24px;

      .left-img {
        margin-right: 19px;
      }

      .right-img {
        margin-left: 19px;
      }

      .text-warp {
        padding: 10px 0;
        // padding: 10px 5px;
        // margin-right: 19px;

        .sub-text {
          margin-top: 4px;
        }
      }
    }

    .margin-left {
      overflow: hidden;
      border-width: 0 !important;
      padding-left: 0 !important;
    }

    .margin-right {
      overflow: hidden;
      border-width: 0 !important;
      padding-right: 0 !important;



    }

    .active.margin-right,
    .active.margin-left {
      border-width: 2px !important;
    }
  }
}
</style>
