import sdkConfig from "@/config/web.config";
import "@/assets/js/ga4"

window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', sdkConfig.ga4MeasurementID, { 'debug_mode': sdkConfig.ga4DebugMode });


export function setGtag(eventName, info) {
    gtag('event', sdkConfig.ga4Name + eventName, info);
}