
import Vue from 'vue'
import "vant/lib/index.css";
import { Field, Popup, Toast, Swipe, SwipeItem, Icon, Progress, Circle, Loading, CheckboxGroup, Checkbox, Radio, RadioGroup, Image, Form, Slider } from 'vant'

Vue.use(Field)
    .use(Popup)
    .use(Toast)
    .use(Swipe)
    .use(SwipeItem)
    .use(Icon)
    .use(Progress)
    .use(Circle)
    .use(Loading)
    .use(CheckboxGroup)
    .use(Checkbox)
    .use(Radio)
    .use(RadioGroup)
    .use(Image)
    .use(Form)
    .use(Slider)


