
import Vue from "vue";
import webConfig from "@/config/web.config/index"
import store from "@/store";
let cssContent = "",
    rootColors = "",
    classResult = "";
let userInfo = window.sessionStorage.getItem('userInfo');
if (userInfo) {
    store.commit('updateuserInfo', JSON.parse(userInfo));//更新用户信息
}

import(`@/config/onboarding.config/main.json`).then(cssConfig => {
    if (cssConfig) {
        loadGoogleFont(cssConfig.fontFamilyHref);//加载字体文件
        document.title = cssConfig.appName; //网页标题
        let { fontFamily, colorConfig, fontConfig, templateFlow } = cssConfig;
        getComplateFolw(cssConfig) //引入动态组件
        //全局字体
        cssContent =
            `
        body{
          font-family:${fontFamily}
        }
      `
        // 定义全局颜色变量
        if (colorConfig) {
            for (let key in colorConfig) {
                rootColors += `--${key}:${colorConfig[key]};`
            }
            rootColors += `--logoMarginRight:${webConfig.logoMarginRight}`
            cssContent += `:root{${rootColors}}`
        }
        // 全局字体匹配
        if (fontConfig) {
            let iPhoneConfigRes = "", iPadConfigRes = "", PCConfigRes = "";
            for (let k in fontConfig) {
                let fonts = fontConfig[k]
                if (fonts) {
                    classResult = ""
                    for (let key in fonts) {
                        if (k == "iPhoneConfig") {
                            iPhoneConfigRes = getCssclass(key, fonts[key])
                        } else if (k == "iPadConfig") {
                            iPadConfigRes = getCssclass(key, fonts[key])
                        } else if (k == "PCConfig") {
                            PCConfigRes = getCssclass(key, fonts[key])
                        }
                    }
                }

            }
            cssContent += `
        ${PCConfigRes}
        @media (max-width: 1025px) { ${iPadConfigRes}}
        @media (max-width: 576px) {${iPhoneConfigRes}}
        `
        }

    }
    //挂载
    addCSS(cssContent);
})

function loadGoogleFont(fontUrl) {
    const link = document.createElement('link');
    link.href = fontUrl;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
}
// 动态整理类
function getCssclass(key, value) {
    if (value) {
        let res = "";
        for (let cssKey in value) {
            res += `${cssKey}:${value[cssKey]};`
        }
        classResult += `.${key}{${res}}`
    }
    return classResult
}
// 动态生引入ss文件
function addCSS(cssText) {
    let style = document.createElement('style');
    let head = document.head || document.getElementsByTagName('head')[0];
    let textNode = document.createTextNode(cssText);
    style.appendChild(textNode);
    head.appendChild(style);
}
function getNewArray(arr) {
    let newArr = []
    if (arr && arr.length) {
        newArr = arr.map(item => {
            let info = {
                screenName: item,
                isStepPage: false,
            }
            if (typeof item == 'object') {
                info.screenName = item.screenName;
                info.routerLink = item.routerLink
            }
            return info
        })
    }
    return newArr
}
// 整理模版渲染顺序
function getComplateFolw(config) {
    if (config) {
        // 引入所有组件--优化get-start-page首次加载速度，get-start-page未引入全部组件放在brand-page中引入
        const currentPath = window.location.pathname;
        if (currentPath != '/get-start-page' && currentPath != '/brand-page') {
            let requireComponents = require.context('../components/onBody', false, /\.vue$/);
            requireComponents.keys().forEach(filePath => {
                // 当前组件
                const componentConfig = requireComponents(filePath);
                // 注册组件
                Vue.component(
                    componentConfig.default.name,
                    componentConfig.default
                )
            });
        }
        let templateFlow = [];
        templateFlow = getNewArray(config.beforeBoarding).concat(config.boarding, getNewArray(config.afterBoarding));
        // 模版渲染顺序
        store.commit("initTemplateFlow", {
            templateFlow
        })
    }
}
