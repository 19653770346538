// 文档地址：https://docs.sentry.io/platforms/javascript/guides/vue/

import * as Sentry from '@sentry/vue';
import router from '../router/index'
import webConfig from '@/config/web.config/index'
import Vue from "vue";
if (webConfig.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: webConfig.sentryConfig.dsn,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [window.location.origin],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

}