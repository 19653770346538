var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"templateEventCalendar"},[_c('Head'),(_vm.animationLoading && _vm.templateData)?_c('div',{staticClass:"template-content"},[_c('div',{staticClass:"animation"},[_c('div',{on:{"click":function($event){_vm.showCalendar = false}}},[_c('div',{staticClass:"title title_1 tc",domProps:{"innerHTML":_vm._s(_vm.templateData.languageConfig[_vm.$language].mainTitle)}}),_c('div',{staticClass:"sub-title text_2 tc",domProps:{"innerHTML":_vm._s(_vm.templateData.languageConfig[_vm.$language].mainText)}})]),_c('div',{staticClass:"date-warp"},[_c('div',{staticClass:"ipt-warp flex justify-between align-center",class:{ active: _vm.showCalendar },on:{"click":function($event){$event.stopPropagation();_vm.showCalendar = !_vm.showCalendar}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text_2 theme_01"},[_vm._v(_vm._s(_vm.$utils.getDate(_vm.templateValue)))])]),_c('svg-icon',{attrs:{"icon-class":"calendar","size":"26"}})],1)]),_c('Calendar',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCalendar),expression:"showCalendar"}],staticClass:"calendar-date",attrs:{"min-date":_vm.minData + '',"format":"MM/dd/yyyy","value":_vm.templateValue,"pane":1,"has-input":false,"on-day-click":_vm.dayClick}})],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.animationLoading && _vm.templateData),expression:"animationLoading && templateData"}],staticClass:"btn-warp"},[_c('div',{staticClass:"button active text_1",class:{ 'shrink-grow-effect': _vm.activeIndex === -1 },domProps:{"innerHTML":_vm._s(_vm.templateData.languageConfig[_vm.$language].buttonText)},on:{"click":function($event){_vm.updateOnBoardingRecord({
        key: 'eventDateSkip',
        value: false,
      }),
      _vm.selectOption(_vm.$utils.formatTimestamp(_vm.templateValue)), _vm.setOptionClickAnimation(-1)}}}),_c('div',{staticClass:"jump-link tc text_2 theme_01 pointer",on:{"click":function($event){_vm.updateOnBoardingRecord({
        key: 'eventDateSkip',
        value: true,
      }),
      _vm.selectOption(_vm.$utils.formatTimestamp(_vm.templateValue))}}},[_vm._v(" "+_vm._s(_vm.templateData.languageConfig[_vm.$language].skipText)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }