<template>
  <div class="templalteIntro">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc"
          v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle, 4)"></div>
        <img v-if="templateData.mainImage" class="img" :src="require(`@img/template/${templateData.mainImage}`)"
          alt="" />

        <div class="sub-title text_1 tl" :style="templateData.bgColor ? 'background-color:' + templateData.bgColor : ''"
          :class="{ 'sub-title-padding': templateData.bgColor }"
          v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].richText, 2)"></div>
      </div>
    </div>
    <div class="btn-warp" v-show="animationLoading && templateData">

      <div @click.once="contimueClick(), setOptionClickAnimation(-1)" class="button active text_1"
        :class="{ 'shrink-grow-effect': activeIndex === -1 }"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
export default {
  name: "templalteIntro",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
};
</script>
<style scoped lang="scss">
.templalteIntro {

  .img {
    margin: 10px auto;
    width: 100%;
    border-radius: 8px;
  }


  .sub-title-padding {
    padding: 16px 20px;
    border-radius: 16px;
  }

}
</style>
