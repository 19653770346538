import Vue from "vue";
import webLanguage from "./webLanguage";
async function initLanguage() {
    let currentLanguageCongig = require(`./${webLanguage}.json`)
    Vue.prototype.$t = currentLanguageCongig
    // 日期选择控件多语言配置
    if (currentLanguageCongig && currentLanguageCongig.calendarLanguage) {
        window.VueCalendarLang = function () {
            return currentLanguageCongig.calendarLanguage
        };
    }
    Vue.prototype.$language = webLanguage
}

initLanguage()
