import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/main-page'
    },
    {
        path: '/main-page',
        name: 'mainPage',
        component: () =>
            import('@/views/main-page')
    },
    {
        path: '/login-page',
        name: 'loginPage',
        component: () =>
            import('@/views/login-page')
    },
    {
        path: '/about-us-page',
        name: 'aboutUsPage',
        component: () =>
            import('@/views/about-us-page')
    },
    {
        path: '/user-review-page',
        name: 'userReviewPage',
        component: () =>
            import('@/views/user-review-page')
    },
    {
        path: '/generated-questionnaire-page',
        name: 'generatedQuestionnairePage',
        component: () =>
            import('@/views/onboarding/generated-questionnaire-page.vue')
    },
    {
        path: '/brand-page',
        name: 'brandPagePage',
        component: () =>
            import('@/views/onboarding/brand-page.vue')
    },
    {
        path: '/policy-page',
        name: 'policyPage',
        component: () =>
            import('@/views/policy-page')
    },
    {
        path: '/contact-us-page',
        name: 'contactUsPage',
        component: () =>
            import('@/views/contact-us-page')
    },
    {
        path: '/modify-email-page',
        name: 'modifyEmailPage',
        component: () =>
            import('@/views/modify-email-page')
    },
    {
        path: '/reset-password-page',
        name: 'resetPasswordPage',
        component: () =>
            import('@/views/reset-password-page')
    },
    {
        path: '/paywall-page',
        name: 'paywallPage',
        component: () =>
            import('@/views/paywall/index')
    },
    {
        path: '/discount-paywall-page',
        name: 'paywallDiscountPage',
        component: () =>
            import('@/views/paywall/discount-paywall-page')
    },
    {
        path: '/payment-succeed-page',
        name: 'paymentSucceedPage',
        component: () =>
            import('@/views/payment-succeed-page')
    },
    {
        path: '/create-account-page',
        name: 'createAccountPage',
        component: () =>
            import('@/views/create-account-page')
    },
    {
        path: '/user-profile-page',
        name: 'userProfilePage',
        component: () =>
            import('@/views/user-profile-page')
    },
    {
        path: '/cancel-plan-survey-page',
        name: 'cancelPlanSurveyPage',
        component: () =>
            import('@/views/cancel-plan-survey-page')
    },
    {
        path: '/cancel-confirm-page',
        name: 'cancelConfirmPage',
        component: () =>
            import('@/views/cancel-confirm-page')
    },
    {
        path: '/get-start-page',
        name: 'getStartPage',
        component: () =>
            import('@/views/onboarding/get-start-page.vue')
    },
    {
        path: '/offer-page',
        name: 'offerPage',
        component: () =>
            import('@/views/offer-page.vue')
    },

]

const router = new Router({
    routes,
    mode: 'history'
})
router.afterEach((to, from) => {
    window.scrollTo(0, 0);
});
// router.beforeEach((to, from, next) => {

//     if (router.currentRoute && router.currentRoute.fullPath == to.fullPath) {
//         // 当前路由是目标路由，不需要再次导航
//         next(false);
//     } else {
//         // 目标路由是否已经包含固定参数
//         if (from.path != '/' && ((!to.query.utm_source && from.query.utm_source) || (!to.query.utm_medium && from.query.utm_medium) || (!to.query.utm_campaign && from.query.utm_campaign) || (!to.query.keyword && from.query.keyword))) {
//             next({
//                 path: to.path,
//                 query: { ...to.query, ...from.query },
//             });
//         } else {
//             next();
//         }
//     }

// })

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => console.log(err))
}

export default router
