
// Copyright 2012 Google Inc. All rights reserved.

(function () {

  var data = {
    "resource": {
      "version": "1",

      "macros": [],
      "tags": [],
      "predicates": [],
      "rules": []
    },
    "runtime": [
    ]

    , "blob": { "1": "1" }








  };




  var ba, fa = function (a) { var b = 0; return function () { return b < a.length ? { done: !1, value: a[b++] } : { done: !0 } } }, ia = typeof Object.defineProperties == "function" ? Object.defineProperty : function (a, b, c) { if (a == Array.prototype || a == Object.prototype) return a; a[b] = c.value; return a }, ja = function (a) {
    for (var b = ["object" == typeof globalThis && globalThis, a, "object" == typeof window && window, "object" == typeof self && self, "object" == typeof global && global], c = 0; c < b.length; ++c) { var d = b[c]; if (d && d.Math == Math) return d } throw Error("Cannot find global object");
  }, ka = ja(this), la = function (a, b) { if (b) a: { for (var c = ka, d = a.split("."), e = 0; e < d.length - 1; e++) { var f = d[e]; if (!(f in c)) break a; c = c[f] } var h = d[d.length - 1], l = c[h], m = b(l); m != l && m != null && ia(c, h, { configurable: !0, writable: !0, value: m }) } }, ma = function (a) { var b = typeof Symbol != "undefined" && Symbol.iterator && a[Symbol.iterator]; if (b) return b.call(a); if (typeof a.length == "number") return { next: fa(a) }; throw Error(String(a) + " is not an iterable or ArrayLike"); }, na = function (a) {
    for (var b, c = []; !(b = a.next()).done;)c.push(b.value);
    return c
  }, oa = function (a) { return a instanceof Array ? a : na(ma(a)) }, pa = typeof Object.assign == "function" ? Object.assign : function (a, b) { for (var c = 1; c < arguments.length; c++) { var d = arguments[c]; if (d) for (var e in d) Object.prototype.hasOwnProperty.call(d, e) && (a[e] = d[e]) } return a }; la("Object.assign", function (a) { return a || pa }); var qa = typeof Object.create == "function" ? Object.create : function (a) { var b = function () { }; b.prototype = a; return new b }, ra;
  if (typeof Object.setPrototypeOf == "function") ra = Object.setPrototypeOf; else { var sa; a: { var ta = { a: !0 }, ua = {}; try { ua.__proto__ = ta; sa = ua.a; break a } catch (a) { } sa = !1 } ra = sa ? function (a, b) { a.__proto__ = b; if (a.__proto__ !== b) throw new TypeError(a + " is not extensible"); return a } : null }
  var wa = ra, ya = function (a, b) { a.prototype = qa(b.prototype); a.prototype.constructor = a; if (wa) wa(a, b); else for (var c in b) if (c != "prototype") if (Object.defineProperties) { var d = Object.getOwnPropertyDescriptor(b, c); d && Object.defineProperty(a, c, d) } else a[c] = b[c]; a.gn = b.prototype }, za = function () { for (var a = Number(this), b = [], c = a; c < arguments.length; c++)b[c - a] = arguments[c]; return b };/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
  var Aa = this || self, Ba = function (a, b, c) { return a.call.apply(a.bind, arguments) }, Ca = function (a, b, c) { if (!a) throw Error(); if (arguments.length > 2) { var d = Array.prototype.slice.call(arguments, 2); return function () { var e = Array.prototype.slice.call(arguments); Array.prototype.unshift.apply(e, d); return a.apply(b, e) } } return function () { return a.apply(b, arguments) } }, Ea = function (a, b, c) { Ea = Function.prototype.bind && Function.prototype.bind.toString().indexOf("native code") != -1 ? Ba : Ca; return Ea.apply(null, arguments) }, Fa =
    function (a) { return a };/*
 jQuery (c) 2005, 2012 jQuery Foundation, Inc. jquery.org/license.
*/
  var Ga = /\[object (Boolean|Number|String|Function|Array|Date|RegExp)\]/, Ha = function (a) { if (a == null) return String(a); var b = Ga.exec(Object.prototype.toString.call(Object(a))); return b ? b[1].toLowerCase() : "object" }, Ja = function (a, b) { return Object.prototype.hasOwnProperty.call(Object(a), b) }, Ka = function (a) {
    if (!a || Ha(a) != "object" || a.nodeType || a == a.window) return !1; try { if (a.constructor && !Ja(a, "constructor") && !Ja(a.constructor.prototype, "isPrototypeOf")) return !1 } catch (c) { return !1 } for (var b in a); return b === void 0 ||
      Ja(a, b)
  }, g = function (a, b) { var c = b || (Ha(a) == "array" ? [] : {}), d; for (d in a) if (Ja(a, d)) { var e = a[d]; Ha(e) == "array" ? (Ha(c[d]) != "array" && (c[d] = []), c[d] = g(e, c[d])) : Ka(e) ? (Ka(c[d]) || (c[d] = {}), c[d] = g(e, c[d])) : c[d] = e } return c }; function La() { for (var a = Ma, b = {}, c = 0; c < a.length; ++c)b[a[c]] = c; return b } function Na() { var a = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; a += a.toLowerCase() + "0123456789-_"; return a + "." } var Ma, Oa; function Pa(a) { Ma = Ma || Na(); Oa = Oa || La(); for (var b = [], c = 0; c < a.length; c += 3) { var d = c + 1 < a.length, e = c + 2 < a.length, f = a.charCodeAt(c), h = d ? a.charCodeAt(c + 1) : 0, l = e ? a.charCodeAt(c + 2) : 0, m = f >> 2, n = (f & 3) << 4 | h >> 4, p = (h & 15) << 2 | l >> 6, q = l & 63; e || (q = 64, d || (p = 64)); b.push(Ma[m], Ma[n], Ma[p], Ma[q]) } return b.join("") }
  function Qa(a) { function b(m) { for (; d < a.length;) { var n = a.charAt(d++), p = Oa[n]; if (p != null) return p; if (!/^[\s\xa0]*$/.test(n)) throw Error("Unknown base64 encoding at char: " + n); } return m } Ma = Ma || Na(); Oa = Oa || La(); for (var c = "", d = 0; ;) { var e = b(-1), f = b(0), h = b(64), l = b(64); if (l === 64 && e === -1) return c; c += String.fromCharCode(e << 2 | f >> 4); h !== 64 && (c += String.fromCharCode(f << 4 & 240 | h >> 2), l !== 64 && (c += String.fromCharCode(h << 6 & 192 | l))) } } var Ra = {}; function Sa(a, b) { Ra[a] = Ra[a] || []; Ra[a][b] = !0 } function Ta(a) { var b = Ra[a]; if (!b || b.length === 0) return ""; for (var c = [], d = 0, e = 0; e < b.length; e++)e % 8 === 0 && e > 0 && (c.push(String.fromCharCode(d)), d = 0), b[e] && (d |= 1 << e % 8); d > 0 && c.push(String.fromCharCode(d)); return Pa(c.join("")).replace(/\.+$/, "") } function Ua() { for (var a = [], b = Ra.fdr || [], c = 0; c < b.length; c++)b[c] && a.push(c); return a.length > 0 ? a : void 0 } function Va() { } function Wa(a) { return typeof a === "function" } function k(a) { return typeof a === "string" } function Xa(a) { return typeof a === "number" && !isNaN(a) } function Ya(a) { return Array.isArray(a) ? a : [a] } function Za(a, b) { if (a && Array.isArray(a)) for (var c = 0; c < a.length; c++)if (a[c] && b(a[c])) return a[c] } function $a(a, b) { if (!Xa(a) || !Xa(b) || a > b) a = 0, b = 2147483647; return Math.floor(Math.random() * (b - a + 1) + a) }
  function ab(a, b) { for (var c = new bb, d = 0; d < a.length; d++)c.set(a[d], !0); for (var e = 0; e < b.length; e++)if (c.get(b[e])) return !0; return !1 } function A(a, b) { for (var c in a) Object.prototype.hasOwnProperty.call(a, c) && b(c, a[c]) } function cb(a) { return !!a && (Object.prototype.toString.call(a) === "[object Arguments]" || Object.prototype.hasOwnProperty.call(a, "callee")) } function db(a) { return Math.round(Number(a)) || 0 } function eb(a) { return "false" === String(a).toLowerCase() ? !1 : !!a }
  function fb(a) { var b = []; if (Array.isArray(a)) for (var c = 0; c < a.length; c++)b.push(String(a[c])); return b } function gb(a) { return a ? a.replace(/^\s+|\s+$/g, "") : "" } function hb() { return new Date(Date.now()) } function ib() { return hb().getTime() } var bb = function () { this.prefix = "gtm."; this.values = {} }; bb.prototype.set = function (a, b) { this.values[this.prefix + a] = b }; bb.prototype.get = function (a) { return this.values[this.prefix + a] }; function jb(a, b, c) { return a && a.hasOwnProperty(b) ? a[b] : c }
  function kb(a) { var b = a; return function () { if (b) { var c = b; b = void 0; try { c() } catch (d) { } } } } function lb(a, b) { for (var c in b) b.hasOwnProperty(c) && (a[c] = b[c]) } function mb(a, b) { for (var c = [], d = 0; d < a.length; d++)c.push(a[d]), c.push.apply(c, b[a[d]] || []); return c } function nb(a, b) { for (var c = {}, d = c, e = a.split("."), f = 0; f < e.length - 1; f++)d = d[e[f]] = {}; d[e[e.length - 1]] = b; return c } var ob = /^\w{1,9}$/; function pb(a, b) { a = a || {}; b = b || ","; var c = []; A(a, function (d, e) { ob.test(d) && e && c.push(d) }); return c.join(b) }
  function qb(a, b) { function c() { e && ++d === b && (e(), e = null, c.done = !0) } var d = 0, e = a; c.done = !1; return c } function rb(a) { if (a) { var b = a.split(","); if (b.length === 2 && b[0] === b[1]) return b[0] } return a } var sb, tb = function () { if (sb === void 0) { var a = null, b = Aa.trustedTypes; if (b && b.createPolicy) { try { a = b.createPolicy("goog#html", { createHTML: Fa, createScript: Fa, createScriptURL: Fa }) } catch (c) { Aa.console && Aa.console.error(c.message) } sb = a } else sb = a } return sb }; var ub = function (a) { this.D = a }; ub.prototype.toString = function () { return this.D + "" }; var vb = {};/*

 SPDX-License-Identifier: Apache-2.0
*/
  var wb = /^\s*(?!javascript:)(?:[\w+.-]+:|[^:/?#]*(?:[/?#]|$))/i; var xb = Array.prototype.indexOf ? function (a, b) { return Array.prototype.indexOf.call(a, b, void 0) } : function (a, b) { if (typeof a === "string") return typeof b !== "string" || b.length != 1 ? -1 : a.indexOf(b, 0); for (var c = 0; c < a.length; c++)if (c in a && a[c] === b) return c; return -1 }; var yb = {}, zb = function (a) { this.D = a }; zb.prototype.toString = function () { return this.D.toString() }; function Ab(a) { return a === null ? "null" : a === void 0 ? "undefined" : a } var F = window, H = document, Bb = navigator, Cb = function () { var a; try { a = Bb.serviceWorker } catch (b) { return } return a }, Db = H.currentScript, Eb = Db && Db.src, Fb = function (a, b) { var c = F[a]; F[a] = c === void 0 ? b : c; return F[a] }; function Gb(a) { return (Bb.userAgent || "").indexOf(a) !== -1 }
  var Hb = function (a, b) { b && (a.addEventListener ? a.onload = b : a.onreadystatechange = function () { a.readyState in { loaded: 1, complete: 1 } && (a.onreadystatechange = null, b()) }) }, Ib = { async: 1, nonce: 1, onerror: 1, onload: 1, src: 1, type: 1 }, Jb = { onload: 1, src: 1, width: 1, height: 1, style: 1 }; function Kb(a, b, c) { b && A(b, function (d, e) { d = d.toLowerCase(); c.hasOwnProperty(d) || a.setAttribute(d, e) }) }
  var Lb = function (a, b, c, d, e) {
    var f = H.createElement("script"); Kb(f, d, Ib); f.type = "text/javascript"; f.async = d && d.async === !1 ? !1 : !0; var h, l = Ab(a), m = tb(), n = m ? m.createScriptURL(l) : l; h = new ub(n, vb); f.src = h instanceof ub && h.constructor === ub ? h.D : "type_error:TrustedResourceUrl"; var p, q, t, r = (t = (q = (f.ownerDocument && f.ownerDocument.defaultView || window).document).querySelector) == null ? void 0 : t.call(q, "script[nonce]"); (p = r ? r.nonce || r.getAttribute("nonce") || "" : "") && f.setAttribute("nonce", p); Hb(f, b); c && (f.onerror = c);
    if (e) e.appendChild(f); else { var u = H.getElementsByTagName("script")[0] || H.body || H.head; u.parentNode.insertBefore(f, u) } return f
  }, Mb = function () { if (Eb) { var a = Eb.toLowerCase(); if (a.indexOf("https://") === 0) return 2; if (a.indexOf("http://") === 0) return 3 } return 1 }, Nb = function (a, b, c, d, e) {
    var f; f = f === void 0 ? !0 : f; var h = e, l = !1; h || (h = H.createElement("iframe"), l = !0); Kb(h, c, Jb); d && A(d, function (n, p) { h.dataset[n] = p }); f && (h.height = "0", h.width = "0", h.style.display = "none", h.style.visibility = "hidden"); a !== void 0 && (h.src =
      a); if (l) { var m = H.body && H.body.lastChild || H.body || H.head; m.parentNode.insertBefore(h, m) } Hb(h, b); return h
  }, Ob = function (a, b, c, d) { var e = new Image(1, 1); Kb(e, d, {}); e.onload = function () { e.onload = null; b && b() }; e.onerror = function () { e.onerror = null; c && c() }; e.src = a; return e }, Pb = function (a, b, c, d) { a.addEventListener ? a.addEventListener(b, c, !!d) : a.attachEvent && a.attachEvent("on" + b, c) }, Qb = function (a, b, c) { a.removeEventListener ? a.removeEventListener(b, c, !1) : a.detachEvent && a.detachEvent("on" + b, c) }, J = function (a) {
    F.setTimeout(a,
      0)
  }, Rb = function (a, b) { return a && b && a.attributes && a.attributes[b] ? a.attributes[b].value : null }, Sb = function (a) { var b = a.innerText || a.textContent || ""; b && b != " " && (b = b.replace(/^[\s\xa0]+|[\s\xa0]+$/g, "")); b && (b = b.replace(/(\xa0+|\s{2,}|\n|\r\t)/g, " ")); return b }, Tb = function (a) {
    var b = H.createElement("div"), c = b, d, e = Ab("A<div>" + a + "</div>"), f = tb(), h = f ? f.createHTML(e) : e; d = new zb(h, yb); if (c.nodeType === 1) { var l = c.tagName; if (l === "SCRIPT" || l === "STYLE") throw Error(""); } c.innerHTML = d instanceof zb && d.constructor ===
      zb ? d.D : "type_error:SafeHtml"; b = b.lastChild; for (var m = []; b.firstChild;)m.push(b.removeChild(b.firstChild)); return m
  }, Ub = function (a, b) { for (var c = {}, d = 0; d < b.length; d++)c[b[d]] = !0; for (var e = a, f = 0; e && f <= 100; f++) { if (c[String(e.tagName).toLowerCase()]) return e; e = e.parentElement } return null }, Vb = function (a) { var b; try { b = Bb.sendBeacon && Bb.sendBeacon(a) } catch (c) { Sa("TAGGING", 15) } b || Ob(a) }, Wb = function (a, b) { try { return Bb.sendBeacon(a, b) } catch (c) { Sa("TAGGING", 15) } return !1 }, Xb = {
    cache: "no-store", credentials: "include",
    keepalive: !0, method: "POST", mode: "no-cors", redirect: "follow"
  }, Yb = function (a, b, c) { if ("fetch" in F) { var d = Object.assign({}, Xb); b && (d.body = b); c && (c.attributionReporting && (d.attributionReporting = c.attributionReporting), c.browsingTopics && (d.browsingTopics = c.browsingTopics)); try { return F.fetch(a, d), !0 } catch (e) { } } if (c && c.noFallback) return !1; if (b) return Wb(a, b); Vb(a); return !0 }, Zb = function (a, b) { var c = a[b]; c && typeof c.animVal === "string" && (c = c.animVal); return c }, $b = function () { var a = F.performance; if (a && Wa(a.now)) return a.now() },
    ac = function () { return F.performance || void 0 }; var bc = function (a) { this.message = a }; function cc(a) { var b = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[a]; return b === void 0 ? new bc("Value " + a + " can not be encoded in web-safe base64 dictionary.") : b } function dc(a) { switch (a) { case 1: return "1"; case 2: case 4: return "0"; default: return "-" } } var ec = /^[1-9a-zA-Z_-][1-9a-c][1-9a-v]\d$/; function fc(a, b) { for (var c = "", d = !0; a > 7;) { var e = a & 31; a >>= 5; d ? d = !1 : e |= 32; c = "" + cc(e) + c } a <<= 2; d || (a |= 32); return c = "" + cc(a | b) + c } var gc = function () {
      var a = function (b) { return { toString: function () { return b } } }; return {
        Ti: a("consent"), Ag: a("convert_case_to"), Bg: a("convert_false_to"), Cg: a("convert_null_to"), Dg: a("convert_true_to"), Eg: a("convert_undefined_to"), gm: a("debug_mode_metadata"), na: a("function"), Af: a("instance_name"), Pj: a("live_only"), Qj: a("malware_disabled"), Rj: a("metadata"), Uj: a("original_activity_id"), Bm: a("original_vendor_template_id"), Am: a("once_on_load"), Tj: a("once_per_event"), Rh: a("once_per_load"), Fm: a("priority_override"),
        Gm: a("respected_consent_types"), Zh: a("setup_tags"), vd: a("tag_id"), hi: a("teardown_tags")
      }
    }(); var Cc; var Dc = [], Ec = [], Fc = [], Gc = [], Hc = [], Ic = {}, Jc, Kc; function Lc(a) { Kc = Kc || a }
  function Mc(a) { } var Nc, Oc = [], Pc = []; function Qc(a, b, c) { try { return Jc(Rc(a, b, c)) } catch (d) { JSON.stringify(a) } return 2 }
  function Sc(a) { var b = a[gc.na]; if (!b) throw Error("Error: No function name given for function call."); return !!Ic[b] }
  var Rc = function (a, b, c) { c = c || []; var d = {}, e; for (e in a) a.hasOwnProperty(e) && (d[e] = Tc(a[e], b, c)); return d }, Tc = function (a, b, c) {
    if (Array.isArray(a)) {
      var d; switch (a[0]) {
        case "function_id": return a[1]; case "list": d = []; for (var e = 1; e < a.length; e++)d.push(Tc(a[e], b, c)); return d; case "macro": var f = a[1]; if (c[f]) return; var h = Dc[f]; if (!h || b.isBlocked(h)) return; c[f] = !0; var l = String(h[gc.Af]); try {
          var m = Rc(h, b, c); m.vtp_gtmEventId = b.id; b.priorityId && (m.vtp_gtmPriorityId = b.priorityId); d = Uc(m, {
            event: b, index: f, type: 2,
            name: l
          }); Nc && (d = Nc.qk(d, m))
        } catch (x) { b.logMacroError && b.logMacroError(x, Number(f), l), d = !1 } c[f] = !1; return d; case "map": d = {}; for (var n = 1; n < a.length; n += 2)d[Tc(a[n], b, c)] = Tc(a[n + 1], b, c); return d; case "template": d = []; for (var p = !1, q = 1; q < a.length; q++) { var t = Tc(a[q], b, c); Kc && (p = p || Kc.bl(t)); d.push(t) } return Kc && p ? Kc.tk(d) : d.join(""); case "escape": d = Tc(a[1], b, c); if (Kc && Array.isArray(a[1]) && a[1][0] === "macro" && Kc.fl(a)) return Kc.Al(d); d = String(d); for (var r = 2; r < a.length; r++)hc[a[r]] && (d = hc[a[r]](d)); return d;
        case "tag": var u = a[1]; if (!Gc[u]) throw Error("Unable to resolve tag reference " + u + "."); return { ri: a[2], index: u }; case "zb": var v = { arg0: a[2], arg1: a[3], ignore_case: a[5] }; v[gc.na] = a[1]; var w = Qc(v, b, c), y = !!a[4]; return y || w !== 2 ? y !== (w === 1) : null; default: throw Error("Attempting to expand unknown Value type: " + a[0] + ".");
      }
    } return a
  }, Uc = function (a, b) {
    var c = a[gc.na], d = b && b.event; if (!c) throw Error("Error: No function name given for function call."); var e = Ic[c], f = b && b.type === 2 && (d == null ? void 0 : d.reportMacroDiscrepancy) &&
      e && Oc.indexOf(c) !== -1, h = {}, l; for (l in a) a.hasOwnProperty(l) && l.indexOf("vtp_") === 0 && e && (h[l] = a[l]); e && d && d.cachedModelValues && (h.vtp_gtmCachedValues = d.cachedModelValues); if (b) { if (b.name == null) { var m; a: { var n = b.type, p = b.index; if (p == null) m = ""; else { var q; switch (n) { case 2: q = Dc[p]; break; case 1: q = Gc[p]; break; default: m = ""; break a }var t = q && q[gc.Af]; m = t ? String(t) : "" } } b.name = m } e && (h.vtp_gtmEntityIndex = b.index, h.vtp_gtmEntityName = b.name) } var r, u, v; if (f && Pc.indexOf(c) === -1) {
        Pc.push(c); var w = ib(); r = e(h); var y =
          ib() - w, x = ib(); u = Cc(c, b); v = y - (ib() - x)
      } else if (e && (r = e(h)), !e || f) u = Cc(c, b); if (f && d) {
        d.reportMacroDiscrepancy(d.id, c, void 0, !0); var B; a: { var z = r; if (z == void 0 || Array.isArray(z) || Ka(z)) B = !0; else { switch (typeof z) { case "boolean": case "number": case "string": case "function": B = !0; break a }B = !1 } } B ? (Array.isArray(r) ? Array.isArray(u) : Ka(r) ? Ka(u) : typeof r === "function" ? typeof u === "function" : r === u) || d.reportMacroDiscrepancy(d.id, c) : r !== u && d.reportMacroDiscrepancy(d.id, c); v !== void 0 && d.reportMacroDiscrepancy(d.id,
          c, v)
      } return e ? r : u
  }; var Xc = function (a) { function b(t) { for (var r = 0; r < t.length; r++)d[t[r]] = !0 } for (var c = [], d = [], e = Vc(a), f = 0; f < Ec.length; f++) { var h = Ec[f], l = Wc(h, e); if (l) { for (var m = h.add || [], n = 0; n < m.length; n++)c[m[n]] = !0; b(h.block || []) } else l === null && b(h.block || []); } for (var p = [], q = 0; q < Gc.length; q++)c[q] && !d[q] && (p[q] = !0); return p }, Wc = function (a, b) {
    for (var c = a["if"] || [], d = 0; d < c.length; d++) {
      var e = b(c[d]); if (e === 0) return !1;
      if (e === 2) return null
    } for (var f = a.unless || [], h = 0; h < f.length; h++) { var l = b(f[h]); if (l === 2) return null; if (l === 1) return !1 } return !0
  }, Vc = function (a) { var b = []; return function (c) { b[c] === void 0 && (b[c] = Qc(Fc[c], a)); return b[c] } }; var Yc = { qk: function (a, b) { b[gc.Ag] && typeof a === "string" && (a = b[gc.Ag] == 1 ? a.toLowerCase() : a.toUpperCase()); b.hasOwnProperty(gc.Cg) && a === null && (a = b[gc.Cg]); b.hasOwnProperty(gc.Eg) && a === void 0 && (a = b[gc.Eg]); b.hasOwnProperty(gc.Dg) && a === !0 && (a = b[gc.Dg]); b.hasOwnProperty(gc.Bg) && a === !1 && (a = b[gc.Bg]); return a } }; var fd = {}, gd = (fd.uaa = !0, fd.uab = !0, fd.uafvl = !0, fd.uamb = !0, fd.uam = !0, fd.uap = !0, fd.uapv = !0, fd.uaw = !0, fd); var od = ["matches", "webkitMatchesSelector", "mozMatchesSelector", "msMatchesSelector", "oMatchesSelector"], pd = new bb; function wd(a) { return xd ? H.querySelectorAll(a) : null }
  function yd(a, b) { if (!xd) return null; if (Element.prototype.closest) try { return a.closest(b) } catch (e) { return null } var c = Element.prototype.matches || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector, d = a; if (!H.documentElement.contains(d)) return null; do { try { if (c.call(d, b)) return d } catch (e) { break } d = d.parentElement || d.parentNode } while (d !== null && d.nodeType === 1); return null } var zd = !1;
  if (H.querySelectorAll) try { var Ad = H.querySelectorAll(":root"); Ad && Ad.length == 1 && Ad[0] == H.documentElement && (zd = !0) } catch (a) { } var xd = zd; var Bd = /^[0-9A-Fa-f]{64}$/; function Cd(a) { try { return (new TextEncoder).encode(a) } catch (e) { for (var b = [], c = 0; c < a.length; c++) { var d = a.charCodeAt(c); d < 128 ? b.push(d) : d < 2048 ? b.push(192 | d >> 6, 128 | d & 63) : d < 55296 || d >= 57344 ? b.push(224 | d >> 12, 128 | d >> 6 & 63, 128 | d & 63) : (d = 65536 + ((d & 1023) << 10 | a.charCodeAt(++c) & 1023), b.push(240 | d >> 18, 128 | d >> 12 & 63, 128 | d >> 6 & 63, 128 | d & 63)) } return new Uint8Array(b) } }
  function Dd(a) { if (a === "" || a === "e0") return Promise.resolve(a); var b; if ((b = F.crypto) == null ? 0 : b.subtle) { if (Bd.test(a)) return Promise.resolve(a); try { var c = Cd(a); return F.crypto.subtle.digest("SHA-256", c).then(function (d) { var e = Array.from(new Uint8Array(d)).map(function (f) { return String.fromCharCode(f) }).join(""); return F.btoa(e).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "") }).catch(function () { return "e2" }) } catch (d) { return Promise.resolve("e2") } } else return Promise.resolve("e1") } function L(a) { Sa("GTM", a) } var M = {
    g: {
      oa: "ad_personalization", O: "ad_storage", N: "ad_user_data", R: "analytics_storage", Qd: "region", Kb: "consent_updated", vg: "wait_for_update", Wi: "app_remove", Xi: "app_store_refund", Yi: "app_store_subscription_cancel", Zi: "app_store_subscription_convert", aj: "app_store_subscription_renew", bj: "consent_update", Gg: "add_payment_info", Hg: "add_shipping_info", kc: "add_to_cart", mc: "remove_from_cart", Ig: "view_cart", Lb: "begin_checkout", nc: "select_item", cb: "view_item_list", ub: "select_promotion", eb: "view_promotion",
      Ha: "purchase", oc: "refund", La: "view_item", Jg: "add_to_wishlist", cj: "exception", dj: "first_open", ej: "first_visit", Z: "gtag.config", Pa: "gtag.get", fj: "in_app_purchase", Mb: "page_view", gj: "screen_view", ij: "session_start", jj: "timing_complete", kj: "track_social", Oc: "user_engagement", lj: "user_id_update", fb: "gclgb", Qa: "gclid", Kg: "gclgs", Lg: "gclst", ba: "ads_data_redaction", Mg: "gad_source", Pc: "gclid_url", Ng: "gclsrc", Ye: "gbraid", Rd: "wbraid", ka: "allow_ad_personalization_signals", Ze: "allow_custom_scripts", af: "allow_display_features",
      Sd: "allow_enhanced_conversions", hb: "allow_google_signals", Ba: "allow_interest_groups", mj: "app_id", nj: "app_installer_id", oj: "app_name", pj: "app_version", vb: "auid", qj: "auto_detection_enabled", Nb: "aw_remarketing", bf: "aw_remarketing_only", Td: "discount", Ud: "aw_feed_country", Vd: "aw_feed_language", aa: "items", Wd: "aw_merchant_id", Og: "aw_basket_type", Qc: "campaign_content", Rc: "campaign_id", Sc: "campaign_medium", Tc: "campaign_name", Uc: "campaign", Vc: "campaign_source", Wc: "campaign_term", ib: "client_id", Pg: "rnd", cf: "consent_update_type",
      rj: "content_group", sj: "content_type", Va: "conversion_cookie_prefix", Xc: "conversion_id", qa: "conversion_linker", Qg: "conversion_linker_disabled", Ob: "conversion_api", Xd: "cookie_deprecation", Ra: "cookie_domain", Sa: "cookie_expires", Wa: "cookie_flags", qc: "cookie_name", wb: "cookie_path", Ma: "cookie_prefix", rc: "cookie_update", sc: "country", xa: "currency", Yd: "customer_lifetime_value", Yc: "custom_map", df: "gcldc", Zc: "dclid", tj: "debug_mode", ja: "developer_id", uj: "disable_merchant_reported_purchases", bd: "dc_custom_params",
      vj: "dc_natural_search", Rg: "dynamic_event_settings", Sg: "affiliation", Zd: "checkout_option", ef: "checkout_step", Tg: "coupon", dd: "item_list_name", ff: "list_name", wj: "promotions", ed: "shipping", hf: "tax", ae: "engagement_time_msec", be: "enhanced_client_id", ce: "enhanced_conversions", Ug: "enhanced_conversions_automatic_settings", de: "estimated_delivery_date", jf: "euid_logged_in_state", fd: "event_callback", xj: "event_category", kb: "event_developer_id_string", yj: "event_label", uc: "event", ee: "event_settings", fe: "event_timeout",
      zj: "description", Aj: "fatal", Bj: "experiments", kf: "firebase_id", vc: "first_party_collection", he: "_x_20", lb: "_x_19", Vg: "fledge_drop_reason", Wg: "fledge", Xg: "flight_error_code", Yg: "flight_error_message", Zg: "fl_activity_category", ah: "fl_activity_group", lf: "fl_advertiser_id", bh: "fl_ar_dedupe", nf: "match_id", eh: "fl_random_number", fh: "tran", gh: "u", ie: "gac_gclid", wc: "gac_wbraid", hh: "gac_wbraid_multiple_conversions", ih: "ga_restrict_domain", jh: "ga_temp_client_id", xc: "gdpr_applies", kh: "geo_granularity", xb: "value_callback",
      nb: "value_key", jm: "google_ng", km: "google_ono", Pb: "google_signals", lh: "google_tld", je: "groups", mh: "gsa_experiment_id", nh: "gtm_up", yb: "iframe_state", gd: "ignore_referrer", pf: "internal_traffic_results", zb: "is_legacy_converted", Ab: "is_legacy_loaded", ke: "is_passthrough", yc: "_lps", Na: "language", me: "legacy_developer_id_string", ra: "linker", zc: "accept_incoming", pb: "decorate_forms", T: "domains", Bb: "url_position", oh: "method", Cj: "name", hd: "new_customer", qh: "non_interaction", Dj: "optimize_id", Ej: "page_hostname",
      jd: "page_path", Ca: "page_referrer", Cb: "page_title", rh: "passengers", sh: "phone_conversion_callback", Fj: "phone_conversion_country_code", th: "phone_conversion_css_class", Gj: "phone_conversion_ids", uh: "phone_conversion_number", vh: "phone_conversion_options", qf: "_protected_audience_enabled", kd: "quantity", ne: "redact_device_info", rf: "referral_exclusion_definition", Qb: "restricted_data_processing", Hj: "retoken", Ij: "sample_rate", tf: "screen_name", Db: "screen_resolution", Jj: "search_term", Ia: "send_page_view", Rb: "send_to",
      Ac: "server_container_url", ld: "session_duration", oe: "session_engaged", uf: "session_engaged_time", qb: "session_id", pe: "session_number", qe: "_shared_user_id", md: "delivery_postal_code", lm: "temporary_client_id", vf: "topmost_url", Kj: "tracking_id", wf: "traffic_type", ya: "transaction_id", Eb: "transport_url", wh: "trip_type", Sb: "update", Ta: "url_passthrough", se: "_user_agent_architecture", te: "_user_agent_bitness", ue: "_user_agent_full_version_list", ve: "_user_agent_mobile", we: "_user_agent_model", xe: "_user_agent_platform",
      ye: "_user_agent_platform_version", ze: "_user_agent_wow64", Da: "user_data", xh: "user_data_auto_latency", yh: "user_data_auto_meta", zh: "user_data_auto_multi", Ah: "user_data_auto_selectors", Bh: "user_data_auto_status", nd: "user_data_mode", Ae: "user_data_settings", za: "user_id", Xa: "user_properties", Ch: "_user_region", Be: "us_privacy_string", la: "value", Dh: "wbraid_multiple_conversions", Lh: "_host_name", Mh: "_in_page_command", Nh: "_is_passthrough_cid", rb: "non_personalized_ads", ud: "_sst_parameters", jb: "conversion_label",
      sa: "page_location", ob: "global_developer_id_string", Bc: "tc_privacy_string"
    }
  }, be = {}, ce = Object.freeze((be[M.g.ka] = 1, be[M.g.af] = 1, be[M.g.Sd] = 1, be[M.g.hb] = 1, be[M.g.aa] = 1, be[M.g.Ra] = 1, be[M.g.Sa] = 1, be[M.g.Wa] = 1, be[M.g.qc] = 1, be[M.g.wb] = 1, be[M.g.Ma] = 1, be[M.g.rc] = 1, be[M.g.Yc] = 1, be[M.g.ja] = 1, be[M.g.Rg] = 1, be[M.g.fd] = 1, be[M.g.ee] = 1, be[M.g.fe] = 1, be[M.g.vc] = 1, be[M.g.ih] = 1, be[M.g.Pb] = 1, be[M.g.lh] = 1, be[M.g.je] = 1, be[M.g.pf] = 1, be[M.g.zb] = 1, be[M.g.Ab] = 1, be[M.g.ra] = 1, be[M.g.rf] = 1, be[M.g.Qb] = 1, be[M.g.Ia] = 1, be[M.g.Rb] =
    1, be[M.g.Ac] = 1, be[M.g.ld] = 1, be[M.g.uf] = 1, be[M.g.md] = 1, be[M.g.Eb] = 1, be[M.g.Sb] = 1, be[M.g.Ae] = 1, be[M.g.Xa] = 1, be[M.g.ud] = 1, be)); Object.freeze([M.g.sa, M.g.Ca, M.g.Cb, M.g.Na, M.g.tf, M.g.za, M.g.kf, M.g.rj]);
  var de = {}, ee = Object.freeze((de[M.g.Wi] = 1, de[M.g.Xi] = 1, de[M.g.Yi] = 1, de[M.g.Zi] = 1, de[M.g.aj] = 1, de[M.g.dj] = 1, de[M.g.ej] = 1, de[M.g.fj] = 1, de[M.g.ij] = 1, de[M.g.Oc] = 1, de)), fe = {}, ge = Object.freeze((fe[M.g.Gg] = 1, fe[M.g.Hg] = 1, fe[M.g.kc] = 1, fe[M.g.mc] = 1, fe[M.g.Ig] = 1, fe[M.g.Lb] = 1, fe[M.g.nc] = 1, fe[M.g.cb] = 1, fe[M.g.ub] = 1, fe[M.g.eb] = 1, fe[M.g.Ha] = 1, fe[M.g.oc] = 1, fe[M.g.La] = 1, fe[M.g.Jg] = 1, fe)), he = Object.freeze([M.g.ka, M.g.hb, M.g.rc, M.g.vc, M.g.gd, M.g.Ia, M.g.Sb]), ie = Object.freeze([].concat(oa(he))), je = Object.freeze([M.g.Sa,
  M.g.fe, M.g.ld, M.g.uf, M.g.ae]), ke = Object.freeze([].concat(oa(je))), le = {}, me = (le[M.g.O] = "1", le[M.g.R] = "2", le[M.g.N] = "3", le[M.g.oa] = "4", le), ne = {}, oe = Object.freeze((ne[M.g.ka] = 1, ne[M.g.Sd] = 1, ne[M.g.Ba] = 1, ne[M.g.Nb] = 1, ne[M.g.bf] = 1, ne[M.g.Td] = 1, ne[M.g.Ud] = 1, ne[M.g.Vd] = 1, ne[M.g.aa] = 1, ne[M.g.Wd] = 1, ne[M.g.Va] = 1, ne[M.g.qa] = 1, ne[M.g.Ra] = 1, ne[M.g.Sa] = 1, ne[M.g.Wa] = 1, ne[M.g.Ma] = 1, ne[M.g.xa] = 1, ne[M.g.Yd] = 1, ne[M.g.ja] = 1, ne[M.g.uj] = 1, ne[M.g.ce] = 1, ne[M.g.de] = 1, ne[M.g.kf] = 1, ne[M.g.vc] = 1, ne[M.g.zb] = 1, ne[M.g.Ab] = 1, ne[M.g.Na] =
    1, ne[M.g.hd] = 1, ne[M.g.sa] = 1, ne[M.g.Ca] = 1, ne[M.g.sh] = 1, ne[M.g.th] = 1, ne[M.g.uh] = 1, ne[M.g.vh] = 1, ne[M.g.Qb] = 1, ne[M.g.Ia] = 1, ne[M.g.Rb] = 1, ne[M.g.Ac] = 1, ne[M.g.md] = 1, ne[M.g.ya] = 1, ne[M.g.Eb] = 1, ne[M.g.Sb] = 1, ne[M.g.Ta] = 1, ne[M.g.Da] = 1, ne[M.g.za] = 1, ne[M.g.la] = 1, ne)), pe = {}, qe = Object.freeze((pe.search = "s", pe.youtube = "y", pe.playstore = "p", pe.shopping = "h", pe.ads = "a", pe.maps = "m", pe)); Object.freeze(M.g); var re = {}, se = F.google_tag_manager = F.google_tag_manager || {}; re.Bf = "46c0"; re.sd = Number("0") || 0; re.Ga = "dataLayer"; re.dm = "ChEI8LGqswYQ+Kf4vfGkl9fkARIZAHxDt9GgTpQS3T9lF/AyO9QWSS3TosJRjRoCvMk\x3d"; var te = { __cl: 1, __ecl: 1, __ehl: 1, __evl: 1, __fal: 1, __fil: 1, __fsl: 1, __hl: 1, __jel: 1, __lcl: 1, __sdl: 1, __tl: 1, __ytl: 1 }, ue = { __paused: 1, __tg: 1 }, ve; for (ve in te) te.hasOwnProperty(ve) && (ue[ve] = 1); var we = eb(""), xe, ye = !1; ye = !0; xe = ye;
  var ze, Ae = !1; ze = Ae; var Be, Ce = !1; Be = Ce; re.Nc = "www.googletagmanager.com"; var De = "" + re.Nc + (xe ? "/gtag/js" : "/gtm.js"), Ee = null, Fe = null, Ge = {}, He = {}; function Ie() { var a = se.sequence || 1; se.sequence = a + 1; return a } re.Ui = ""; var Je = ""; re.Fe = Je; var Ke = new function () { this.D = ""; this.K = this.F = !1; this.Ja = this.P = this.U = this.H = "" }; function Le() { var a = Ke.H.length; return Ke.H[a - 1] === "/" ? Ke.H.substring(0, a - 1) : Ke.H } function Me(a) { for (var b = {}, c = ma(a.split("|")), d = c.next(); !d.done; d = c.next())b[d.value] = !0; return b } var Ne = new bb, Oe = {}, Pe = {}, Se = { name: re.Ga, set: function (a, b) { g(nb(a, b), Oe); Qe() }, get: function (a) { return Re(a, 2) }, reset: function () { Ne = new bb; Oe = {}; Qe() } }; function Re(a, b) { return b != 2 ? Ne.get(a) : Ve(a) } function Ve(a) { var b, c = a.split("."); b = b || []; for (var d = Oe, e = 0; e < c.length; e++) { if (d === null) return !1; if (d === void 0) break; d = d[c[e]]; if (b.indexOf(d) !== -1) return } return d } function We(a, b) { Pe.hasOwnProperty(a) || (Ne.set(a, b), g(nb(a, b), Oe), Qe()) }
  function Qe(a) { A(Pe, function (b, c) { Ne.set(b, c); g(nb(b), Oe); g(nb(b, c), Oe); a && delete Pe[b] }) } function Xe(a, b) { var c, d = (b === void 0 ? 2 : b) !== 1 ? Ve(a) : Ne.get(a); Ha(d) === "array" || Ha(d) === "object" ? c = g(d) : c = d; return c } function af(a, b) { if (a === "") return b; var c = Number(a); return isNaN(c) ? b : c } var bf = [], cf = {}; function df(a) { return bf[a] === void 0 ? !1 : bf[a] } var ef = []; function ff(a) { switch (a) { case 0: return 0; case 29: return 7; case 37: return 1; case 38: return 2; case 49: return 3; case 57: return 6; case 60: return 9; case 69: return 4; case 74: return 5; case 79: return 8 } } function P(a) { ef[a] = !0; var b = ff(a); b !== void 0 && (bf[b] = !0) }
  P(26); P(22); P(23); P(24); P(25);
  P(39); P(63); P(47); P(59);
  P(28); P(14); P(82); P(13); P(87); P(81); P(50); P(70);
  P(6); P(40); P(4);
  P(67); P(77); P(56); P(53); P(68);
  P(91);
  P(88); P(69); P(5);
  P(74);
  cf[1] = af('1', 6E4); cf[3] = af('10', 1); cf[2] = af('', 50); P(11); P(52);
  P(85); P(78); P(29);


  P(43); function R(a) { return !!ef[a] } var kf = /:[0-9]+$/, lf = /^\d+\.fls\.doubleclick\.net$/, mf = function (a, b, c) { for (var d = ma(a.split("&")), e = d.next(); !e.done; e = d.next()) { var f = ma(e.value.split("=")), h = f.next().value, l = na(f); if (decodeURIComponent(h.replace(/\+/g, " ")) === b) { var m = l.join("="); return c ? m : decodeURIComponent(m.replace(/\+/g, " ")) } } }, pf = function (a, b, c, d, e) {
    b && (b = String(b).toLowerCase()); if (b === "protocol" || b === "port") a.protocol = nf(a.protocol) || nf(F.location.protocol); b === "port" ? a.port = String(Number(a.hostname ? a.port : F.location.port) ||
      (a.protocol === "http" ? 80 : a.protocol === "https" ? 443 : "")) : b === "host" && (a.hostname = (a.hostname || F.location.hostname).replace(kf, "").toLowerCase()); return of(a, b, c, d, e)
  }, of = function (a, b, c, d, e) {
    var f, h = nf(a.protocol); b && (b = String(b).toLowerCase()); switch (b) {
      case "url_no_fragment": f = qf(a); break; case "protocol": f = h; break; case "host": f = a.hostname.replace(kf, "").toLowerCase(); if (c) { var l = /^www\d*\./.exec(f); l && l[0] && (f = f.substr(l[0].length)) } break; case "port": f = String(Number(a.port) || (h === "http" ? 80 : h === "https" ?
        443 : "")); break; case "path": a.pathname || a.hostname || Sa("TAGGING", 1); f = a.pathname.substr(0, 1) === "/" ? a.pathname : "/" + a.pathname; var m = f.split("/"); (d || []).indexOf(m[m.length - 1]) >= 0 && (m[m.length - 1] = ""); f = m.join("/"); break; case "query": f = a.search.replace("?", ""); e && (f = mf(f, e)); break; case "extension": var n = a.pathname.split("."); f = n.length > 1 ? n[n.length - 1] : ""; f = f.split("/")[0]; break; case "fragment": f = a.hash.replace("#", ""); break; default: f = a && a.href
    }return f
  }, nf = function (a) {
    return a ? a.replace(":", "").toLowerCase() :
      ""
  }, qf = function (a) { var b = ""; if (a && a.href) { var c = a.href.indexOf("#"); b = c < 0 ? a.href : a.href.substr(0, c) } return b }, rf = {}, sf = 0, tf = function (a) { var b = rf[a]; if (!b) { var c = H.createElement("a"); a && (c.href = a); var d = c.pathname; d[0] !== "/" && (a || Sa("TAGGING", 1), d = "/" + d); var e = c.hostname.replace(kf, ""); b = { href: c.href, protocol: c.protocol, host: c.host, hostname: e, pathname: d, search: c.search, hash: c.hash, port: c.port }; sf < 5 && (rf[a] = b, sf++) } return b }, uf = function (a) {
    function b(n) {
      var p = n.split("=")[0]; return d.indexOf(p) < 0 ? n :
        p + "=0"
    } function c(n) { return n.split("&").map(b).filter(function (p) { return p !== void 0 }).join("&") } var d = "gclid dclid gbraid wbraid gclaw gcldc gclha gclgf gclgb _gl".split(" "), e = tf(a), f = a.split(/[?#]/)[0], h = e.search, l = e.hash; h[0] === "?" && (h = h.substring(1)); l[0] === "#" && (l = l.substring(1)); h = c(h); l = c(l); h !== "" && (h = "?" + h); l !== "" && (l = "#" + l); var m = "" + f + h + l; m[m.length - 1] === "/" && (m = m.substring(0, m.length - 1)); return m
  }, vf = function (a) {
    var b = tf(F.location.href), c = pf(b, "host", !1); if (c && c.match(lf)) {
      var d = pf(b,
        "path").split(a + "="); if (d.length > 1) return d[1].split(";")[0].split("?")[0]
    }
  }; var wf = { "https://www.google.com": "/g", "https://www.googleadservices.com": "/as", "https://pagead2.googlesyndication.com": "/gs" }; function xf(a, b) { if (a) { var c = "" + a; c.indexOf("http://") !== 0 && c.indexOf("https://") !== 0 && (c = "https://" + c); c[c.length - 1] === "/" && (c = c.substring(0, c.length - 1)); return tf("" + c + b).href } } function yf() { return !!re.Fe && re.Fe.split("@@").join("") !== "SGTM_TOKEN" } function zf(a) { for (var b = ma([M.g.Ac, M.g.Eb]), c = b.next(); !c.done; c = b.next()) { var d = S(a, c.value); if (d) return d } }
  function Af(a, b) { return Ke.F ? "" + Le() + (b ? wf[a] || "" : "") : a } function Bf(a) { var b = String(a[gc.na] || "").replace(/_/g, ""); b.indexOf("cvt") === 0 && (b = "cvt"); return b } var Cf = F.location.search.indexOf("?gtm_latency=") >= 0 || F.location.search.indexOf("&gtm_latency=") >= 0; var Df = { sampleRate: "0.005000", Qi: "", am: "" }; function Ef() { var a = Df.sampleRate; return Number(a) } var Ff = Math.random(), Gf = Cf || Ff < Ef(), Hf = Cf || !R(42) && Gf || R(42) && !Gf && Ff < Ef() + Number(Df.am); function If(a) { var b = Jf(); b.pending || (b.pending = []); Za(b.pending, function (c) { return c.target.ctid === a.ctid && c.target.isDestination === a.isDestination }) || b.pending.push({ target: a, onLoad: void 0 }) } var Kf = function () { this.container = {}; this.destination = {}; this.canonical = {}; this.pending = []; this.siloed = [] }; function Jf() { var a = Fb("google_tag_data", {}), b = a.tidr; b || (b = new Kf, a.tidr = b); return b } var Lf = {}, Mf = !1, Nf = { ctid: "", canonicalContainerId: "", Bi: "", Ci: "" }; Lf.pd = eb(""); function Of() { var a = Pf(); return Mf ? a.map(Qf) : a } function Rf() { var a = Sf(); return Mf ? a.map(Qf) : a } function Tf() { return Uf(Nf.ctid) } function Vf() { return Uf(Nf.canonicalContainerId || "_" + Nf.ctid) } function Pf() { return Nf.Bi ? Nf.Bi.split("|") : [Nf.ctid] } function Sf() { return Nf.Ci ? Nf.Ci.split("|") : [] }
  function Wf() { var a = Xf(Yf()), b = a && a.parent; if (b) return Xf(b) } function Zf() { var a = Xf(Yf()); if (a) { for (; a.parent;) { var b = Xf(a.parent); if (!b) break; a = b } return a } } function Xf(a) { var b = Jf(); return a.isDestination ? b.destination[a.ctid] : b.container[a.ctid] } function Uf(a) { return Mf ? Qf(a) : a } function Qf(a) { return "siloed_" + a } function $f(a) { return Mf ? ag(a) : a } function ag(a) { a = String(a); return a.indexOf("siloed_") === 0 ? a.substring(7) : a }
  function bg() { var a = !1; if (a) { var b = Jf(); if (b.siloed) { for (var c = [], d = Pf().map(Qf), e = Sf().map(Qf), f = {}, h = 0; h < b.siloed.length; f = { Je: void 0 }, h++)f.Je = b.siloed[h], !Mf && Za(f.Je.isDestination ? e : d, function (l) { return function (m) { return m === l.Je.ctid } }(f)) ? Mf = !0 : c.push(f.Je); b.siloed = c } } }
  function cg() { var a = Jf(); if (a.pending) { for (var b, c = [], d = !1, e = Of(), f = Rf(), h = {}, l = 0; l < a.pending.length; h = { Nd: void 0 }, l++)h.Nd = a.pending[l], Za(h.Nd.target.isDestination ? f : e, function (m) { return function (n) { return n === m.Nd.target.ctid } }(h)) ? d || (b = h.Nd.onLoad, d = !0) : c.push(h.Nd); a.pending = c; if (b) try { b(Vf()) } catch (m) { } } }
  function dg() { for (var a = Nf.ctid, b = Of(), c = Rf(), d = function (n, p) { var q = { canonicalContainerId: Nf.canonicalContainerId, scriptContainerId: a, state: 2, containers: b.slice(), destinations: c.slice() }; Db && (q.scriptElement = Db); Eb && (q.scriptSource = Eb); var t = p ? e.destination : e.container, r = t[n]; r ? (p && r.state === 0 && L(93), Object.assign(r, q)) : t[n] = q }, e = Jf(), f = ma(b), h = f.next(); !h.done; h = f.next())d(h.value, !1); for (var l = ma(c), m = l.next(); !m.done; m = l.next())d(m.value, !0); e.canonical[Vf()] = {}; cg() }
  function Yf() { return { ctid: Tf(), isDestination: Lf.pd } } function eg(a) { var b = Jf(); (b.siloed = b.siloed || []).push(a) } function fg() { var a = Jf().container, b; for (b in a) if (a.hasOwnProperty(b) && a[b].state === 1) return !0; return !1 } function gg() { var a = {}; A(Jf().destination, function (b, c) { c.state === 0 && (a[ag(b)] = c) }); return a } function hg(a) { return !!(a && a.parent && a.context && a.context.source === 1 && a.parent.ctid.indexOf("GTM-") !== 0) } var ig = { Pi: Number("5"), hn: Number("") }, jg = [], kg = []; function og(a) { jg.push(a) } var pg = !1, qg = "?id=" + Nf.ctid, rg = void 0, sg = {}, tg = void 0, ug = new function () { var a = 5; ig.Pi > 0 && (a = ig.Pi); this.F = a; this.D = 0; this.H = [] }, vg = 1E3;
  function wg(a, b, c, d) { var e = rg; if (e === void 0) if (a) e = 0; else if (c) e = Ie(); else return ""; for (var f = [Af("https://www.googletagmanager.com"), a ? "/td" : "/a", qg], h = ma(a ? kg : jg), l = h.next(); !l.done; l = h.next())for (var m = l.value, n = m({ eventId: e, Oa: !!b, oi: !!d, fc: function () { pg = !0 } }), p = ma(n), q = p.next(); !q.done; q = p.next()) { var t = ma(q.value), r = t.next().value, u = t.next().value; f.push("&" + r + "=" + u) } f.push("&z=0"); return f.join("") } function xg() { if (Hf) { var a = wg(!0, !0); pg && (Ob(a), pg = !1) } }
  function yg() { tg && (F.clearTimeout(tg), tg = void 0); if (rg !== void 0 && zg) { R(43) || xg(); var a; (a = sg[rg]) || (a = ug.D < ug.F ? !1 : ib() - ug.H[ug.D % ug.F] < 1E3); if (a || vg-- <= 0) L(1), sg[rg] = !0; else { var b = ug.D++ % ug.F; ug.H[b] = ib(); var c = wg(!1, !0); Ob(c); zg = pg = !1 } } } function Ag() { if (Hf) { var a = wg(!0, !0, !0, !0); pg && (Yb(a), pg = !1) } } var zg = !1; function Bg(a) { sg[a] && !R(43) ? xg() : (a !== rg && (yg(), rg = a), zg = !0, tg || (tg = F.setTimeout(yg, 500)), wg(!1).length >= 2022 && yg()) } var Cg = $a(); function Dg() { Cg = $a() }
  function Eg() { return [["v", "3"], ["t", "t"], ["pid", String(Cg)]] } var Fg = "https://www.googletagmanager.com/td?id=" + Nf.ctid, Gg = ["v", "t", "pid", "dl", "tdp"], Hg = {}, Ig = {}; function Jg(a, b) { Ig[a] = b; Hg[a] === void 0 && (Hg[a] = !0) } function Kg() { var a = Object.keys(Hg).filter(function (b) { return Hg[b] === !0 && Ig[b] !== void 0 }).map(function (b) { var c = Ig[b]; typeof c === "function" && (c = c()); return c ? "&" + b + "=" + c : "" }).join(""); return "" + Fg + a + "&z=0" } function Lg() { Object.keys(Hg).forEach(function (a) { Gg.indexOf(a) < 0 && (Hg[a] = !1) }) } function Mg() { Hf && R(43) && (R(41) ? (Ob(Kg()), Lg()) : xg()) } var Ng = $a();
  function Og() { Ng = $a() } function Pg() { Hf && (R(41) ? (Jg("v", "3"), Jg("t", "t"), Jg("pid", function () { return String(Ng) }), F.setInterval(Og, 864E5)) : kg.push(Eg)) } var Qg = function (a, b) { var c = function () { }; c.prototype = a.prototype; var d = new c; a.apply(d, Array.prototype.slice.call(arguments, 1)); return d }, Rg = function (a) { var b = a; return function () { if (b) { var c = b; b = null; c() } } }; var Sg = function (a, b, c) { a.addEventListener && a.addEventListener(b, c, !1) }, Tg = function (a, b, c) { a.removeEventListener && a.removeEventListener(b, c, !1) }; var Ug, Vg; a: { for (var Wg = ["CLOSURE_FLAGS"], Xg = Aa, Yg = 0; Yg < Wg.length; Yg++)if (Xg = Xg[Wg[Yg]], Xg == null) { Vg = null; break a } Vg = Xg } var Zg = Vg && Vg[610401301]; Ug = Zg != null ? Zg : !1; var $g, ah = Aa.navigator; $g = ah ? ah.userAgentData || null : null; function bh(a) { return Ug ? $g ? $g.brands.some(function (b) { var c; return (c = b.brand) && c.indexOf(a) != -1 }) : !1 : !1 } function ch(a) { var b; a: { var c = Aa.navigator; if (c) { var d = c.userAgent; if (d) { b = d; break a } } b = "" } return b.indexOf(a) != -1 } function dh() { return Ug ? !!$g && $g.brands.length > 0 : !1 } function eh() { return dh() ? bh("Chromium") : (ch("Chrome") || ch("CriOS")) && !(dh() ? 0 : ch("Edge")) || ch("Silk") } var fh = function (a) { fh[" "](a); return a }; fh[" "] = function () { }; var gh = function (a, b, c, d) { for (var e = b, f = c.length; (e = a.indexOf(c, e)) >= 0 && e < d;) { var h = a.charCodeAt(e - 1); if (h == 38 || h == 63) { var l = a.charCodeAt(e + f); if (!l || l == 61 || l == 38 || l == 35) return e } e += f + 1 } return -1 }, hh = /#|$/, ih = function (a, b) { var c = a.search(hh), d = gh(a, 0, b, c); if (d < 0) return null; var e = a.indexOf("&", d); if (e < 0 || e > c) e = c; d += b.length + 1; return decodeURIComponent(a.slice(d, e !== -1 ? e : 0).replace(/\+/g, " ")) }, jh = /[?&]($|#)/, kh = function (a, b, c) {
    for (var d, e = a.search(hh), f = 0, h, l = []; (h = gh(a, f, b, e)) >= 0;)l.push(a.substring(f,
      h)), f = Math.min(a.indexOf("&", h) + 1 || e, e); l.push(a.slice(f)); d = l.join("").replace(jh, "$1"); var m, n = c != null ? "=" + encodeURIComponent(String(c)) : ""; var p = b + n; if (p) { var q, t = d.indexOf("#"); t < 0 && (t = d.length); var r = d.indexOf("?"), u; r < 0 || r > t ? (r = t, u = "") : u = d.substring(r + 1, t); q = [d.slice(0, r), u, d.slice(t)]; var v = q[1]; q[1] = p ? v ? v + "&" + p : p : v; m = q[0] + (q[1] ? "?" + q[1] : "") + q[2] } else m = d; return m
  }; var lh = function (a) { try { var b; if (b = !!a && a.location.href != null) a: { try { fh(a.foo); b = !0; break a } catch (c) { } b = !1 } return b } catch (c) { return !1 } }, mh = function (a, b) { if (a) for (var c in a) Object.prototype.hasOwnProperty.call(a, c) && b(a[c], c, a) }; function nh(a) { if (!a || !H.head) return null; var b = oh("META"); H.head.appendChild(b); b.httpEquiv = "origin-trial"; b.content = a; return b }
  var ph = function (a) { if (F.top == F) return 0; if (a === void 0 ? 0 : a) { var b = F.location.ancestorOrigins; if (b) return b[b.length - 1] == F.location.origin ? 1 : 2 } return lh(F.top) ? 1 : 2 }, oh = function (a, b) { b = b === void 0 ? document : b; return b.createElement(String(a).toLowerCase()) }; var qh = "", rh, sh = [], th = !1; function uh() { var a = tf(F.location.href); return a.hostname + a.pathname } function vh() { var a = []; qh && a.push(["dl", encodeURIComponent(qh)]); sh.length > 0 && a.push(["tdp", sh.join(".")]); rh !== void 0 && a.push(["frm", String(rh)]); return a } var wh = function (a) { var b = th ? [] : vh(); !th && a.Oa && (th = !0, b.length && a.fc()); return b };
  function xh() { if (R(41)) { var a = uh(); a && Jg("dl", encodeURIComponent(a)); Jg("tdp", function () { return sh.length > 0 ? sh.join(".") : void 0 }); var b = ph(!0); b !== void 0 && Jg("frm", String(b)) } else kg.push(wh) } var yh = [], zh = []; function Ah(a) { if (R(41)) Jg(a, "1"); else { if (zh.indexOf(a) !== -1) return; yh.push(a); zh.push(a) } Mg() } function Bh(a) { if (!yh.length) return []; for (var b = vh(), c = ma(yh), d = c.next(); !d.done; d = c.next())b.push([d.value, "1"]); a.Oa && (a.fc(), yh.length = 0); return b } function Ch(a) { Sa("HEALTH", a) } var Dh; try { Dh = JSON.parse(Qa("eyIwIjoiQ04iLCIxIjoiIiwiMiI6dHJ1ZSwiMyI6Imdvb2dsZS5jbiIsIjQiOiIiLCI1Ijp0cnVlLCI2IjpmYWxzZSwiNyI6ImFkX3N0b3JhZ2V8YW5hbHl0aWNzX3N0b3JhZ2V8YWRfdXNlcl9kYXRhfGFkX3BlcnNvbmFsaXphdGlvbiJ9")) } catch (a) { L(123), Ch(2), Dh = {} } function Eh() { return Dh["1"] || "" } function Fh() { var a = !1; return a } function Gh() { var a = ""; return a } function Hh() { var a = !1; return a }
  function Ih() { var a = ""; return a } var Jh = new function (a, b) { this.D = a; this.defaultValue = b === void 0 ? !1 : b }(1933); function Kh() { var a = Fb("google_tag_data", {}); return a.ics = a.ics || new Lh } var Lh = function () { this.entries = {}; this.waitPeriodTimedOut = this.wasSetLate = this.accessedAny = this.accessedDefault = this.usedImplicit = this.usedUpdate = this.usedDefault = this.usedDeclare = this.active = !1; this.D = [] };
  Lh.prototype.default = function (a, b, c, d, e, f, h) { this.usedDefault || this.usedDeclare || !this.accessedDefault && !this.accessedAny || (this.wasSetLate = !0); this.usedDefault = this.active = !0; Sa("TAGGING", 19); b == null ? Sa("TAGGING", 18) : Mh(this, a, b === "granted", c, d, e, f, h) }; Lh.prototype.waitForUpdate = function (a, b, c) { for (var d = 0; d < a.length; d++)Mh(this, a[d], void 0, void 0, "", "", b, c) };
  var Mh = function (a, b, c, d, e, f, h, l) {
    var m = a.entries, n = m[b] || {}, p = n.region, q = d && k(d) ? d.toUpperCase() : void 0; e = e.toUpperCase(); f = f.toUpperCase(); if (e === "" || q === f || (q === e ? p !== f : !q && !p)) {
      var t = !!(h && h > 0 && n.update === void 0), r = { region: q, declare_region: n.declare_region, implicit: n.implicit, default: c !== void 0 ? c : n.default, declare: n.declare, update: n.update, quiet: t }; if (e !== "" || n.default !== !1) m[b] = r; t && F.setTimeout(function () {
        m[b] === r && r.quiet && (Sa("TAGGING", 2), a.waitPeriodTimedOut = !0, a.clearTimeout(b, void 0, l),
          a.notifyListeners())
      }, h)
    }
  }; ba = Lh.prototype; ba.clearTimeout = function (a, b, c) { var d = [a], e = (c == null ? void 0 : c.delegatedConsentTypes) || {}, f; for (f in e) e.hasOwnProperty(f) && e[f] === a && d.push(f); var h = this.entries[a] || {}, l = this.getConsentState(a, c); if (h.quiet) { h.quiet = !1; for (var m = ma(d), n = m.next(); !n.done; n = m.next())Nh(this, n.value) } else if (b !== void 0 && l !== b) for (var p = ma(d), q = p.next(); !q.done; q = p.next())Nh(this, q.value) };
  ba.update = function (a, b, c) { this.usedDefault || this.usedDeclare || this.usedUpdate || !this.accessedAny || (this.wasSetLate = !0); this.usedUpdate = this.active = !0; if (b != null) { var d = this.getConsentState(a, c), e = this.entries; (e[a] = e[a] || {}).update = b === "granted"; this.clearTimeout(a, d, c) } };
  ba.declare = function (a, b, c, d, e) { this.usedDeclare = this.active = !0; var f = this.entries, h = f[a] || {}, l = h.declare_region, m = c && k(c) ? c.toUpperCase() : void 0; d = d.toUpperCase(); e = e.toUpperCase(); if (d === "" || m === e || (m === d ? l !== e : !m && !l)) { var n = { region: h.region, declare_region: m, declare: b === "granted", implicit: h.implicit, default: h.default, update: h.update, quiet: h.quiet }; if (d !== "" || h.declare !== !1) f[a] = n } };
  ba.implicit = function (a, b) { this.usedImplicit = !0; var c = this.entries, d = c[a] = c[a] || {}; d.implicit !== !1 && (d.implicit = b === "granted") };
  ba.getConsentState = function (a, b) { var c = this.entries, d = c[a] || {}, e = d.update; if (e !== void 0) return e ? 1 : 2; e = d.default; if (e !== void 0) return e ? 1 : 2; if (b == null ? 0 : b.delegatedConsentTypes.hasOwnProperty(a)) { var f = c[b.delegatedConsentTypes[a]] || {}; e = f.update; if (e !== void 0) return e ? 1 : 2; e = f.default; if (e !== void 0) return e ? 1 : 2 } e = d.declare; if (e !== void 0) return e ? 1 : 2; e = d.implicit; return e !== void 0 ? e ? 3 : 4 : 0 }; ba.addListener = function (a, b) { this.D.push({ consentTypes: a, Bk: b }) };
  var Nh = function (a, b) { for (var c = 0; c < a.D.length; ++c) { var d = a.D[c]; Array.isArray(d.consentTypes) && d.consentTypes.indexOf(b) !== -1 && (d.Di = !0) } }; Lh.prototype.notifyListeners = function (a, b) { for (var c = 0; c < this.D.length; ++c) { var d = this.D[c]; if (d.Di) { d.Di = !1; try { d.Bk({ consentEventId: a, consentPriorityId: b }) } catch (e) { } } } }; var Ph = function () { var a = Oh, b = "Wf"; if (a.Wf && a.hasOwnProperty(b)) return a.Wf; var c = new a; return a.Wf = c }; var Oh = function () { var a = {}; this.D = function () { var b = Jh.D, c = Jh.defaultValue; return a[b] != null ? a[b] : c }; this.F = function () { a[Jh.D] = !0 } }; var Qh = !1, Rh = !1, Sh = { delegatedConsentTypes: {}, corePlatformServices: {}, usedCorePlatformServices: !1 }, Th = function (a) { var b = Kh(); b.accessedAny = !0; return (k(a) ? [a] : a).every(function (c) { switch (b.getConsentState(c, Sh)) { case 1: case 3: return !0; case 2: case 4: return !1; default: return !0 } }) }, Uh = function (a) { var b = Kh(); b.accessedAny = !0; return b.getConsentState(a, Sh) }, Vh = function (a) { for (var b = {}, c = ma(a), d = c.next(); !d.done; d = c.next()) { var e = d.value; b[e] = Sh.corePlatformServices[e] !== !1 } return b }, Wh = function (a) {
    var b =
      Kh(); b.accessedAny = !0; return !(b.entries[a] || {}).quiet
  }, ci = function () { if (!Ph().D()) return !1; var a = Kh(); a.accessedAny = !0; return a.active }, di = function (a, b) { Kh().addListener(a, b) }, ei = function (a, b) { Kh().notifyListeners(a, b) }, fi = function (a, b) { function c() { for (var e = 0; e < b.length; e++)if (!Wh(b[e])) return !0; return !1 } if (c()) { var d = !1; di(b, function (e) { d || c() || (d = !0, a(e)) }) } else a({}) }, gi = function (a, b) {
    function c() { for (var l = [], m = 0; m < e.length; m++) { var n = e[m]; Th(n) && !f[n] && l.push(n) } return l } function d(l) {
      for (var m =
        0; m < l.length; m++)f[l[m]] = !0
    } var e = k(b) ? [b] : b, f = {}, h = c(); h.length !== e.length && (d(h), di(e, function (l) { function m(q) { q.length !== 0 && (d(q), l.consentTypes = q, a(l)) } var n = c(); if (n.length !== 0) { var p = Object.keys(f).length; n.length + p >= e.length ? m(n) : F.setTimeout(function () { m(c()) }, 500) } }))
  }; var hi = [M.g.O, M.g.R, M.g.N, M.g.oa], ii, ji; function ki(a) { for (var b = a[M.g.Qd], c = Array.isArray(b) ? b : [b], d = { Cd: 0 }; d.Cd < c.length; d = { Cd: d.Cd }, ++d.Cd)A(a, function (e) { return function (f, h) { if (f !== M.g.Qd) { var l = c[e.Cd], m = Dh["0"] || "", n = Eh(); Rh = !0; Qh && Sa("TAGGING", 20); Kh().declare(f, h, l, m, n) } } }(d)) }
  function li(a) { !ji && ii && Ah("crc"); ji = !0; var b = a[M.g.Qd]; b && L(40); var c = a[M.g.vg]; c && L(41); for (var d = Array.isArray(b) ? b : [b], e = { Dd: 0 }; e.Dd < d.length; e = { Dd: e.Dd }, ++e.Dd)A(a, function (f) { return function (h, l) { if (h !== M.g.Qd && h !== M.g.vg) { var m = d[f.Dd], n = Number(c), p = Dh["0"] || "", q = Eh(); n = n === void 0 ? 0 : n; Qh = !0; Rh && Sa("TAGGING", 20); Kh().default(h, l, m, p, q, n, Sh) } } }(e)) } function mi(a, b) { ii = !0; A(a, function (c, d) { Qh = !0; Rh && Sa("TAGGING", 20); Kh().update(c, d, Sh) }); ei(b.eventId, b.priorityId) }
  function T(a) { Array.isArray(a) || (a = [a]); return a.every(function (b) { return Th(b) }) } function ni(a, b) { gi(a, b) } function oi(a, b) { fi(a, b) } function pi() { var a = [M.g.O, M.g.oa, M.g.N]; Kh().waitForUpdate(a, 500, Sh) } function qi(a) { for (var b = ma(a), c = b.next(); !c.done; c = b.next()) { var d = c.value; Kh().clearTimeout(d, void 0, Sh) } ei() } var ri = function () { if (se.pscdl === void 0) { var a = function (b) { se.pscdl = b }; try { "cookieDeprecationLabel" in Bb ? (a("pending"), Bb.cookieDeprecationLabel.getValue().then(a)) : a("noapi") } catch (b) { a("error") } } }; var si = /[A-Z]+/, ti = /\s/; function ui(a, b) { if (k(a)) { a = gb(a); var c = a.indexOf("-"); if (!(c < 0)) { var d = a.substring(0, c); if (si.test(d)) { var e = a.substring(c + 1), f; if (b) { var h = function (n) { var p = n.indexOf("/"); return p < 0 ? [n] : [n.substring(0, p), n.substring(p + 1)] }; f = h(e); if (d === "DC" && f.length === 2) { var l = h(f[1]); l.length === 2 && (f[1] = l[0], f.push(l[1])) } } else { f = e.split("/"); for (var m = 0; m < f.length; m++)if (!f[m] || ti.test(f[m]) && (d !== "AW" || m !== 1)) return } return { id: a, prefix: d, da: d + "-" + f[0], fa: f } } } } }
  function vi(a, b) { for (var c = {}, d = 0; d < a.length; ++d) { var e = ui(a[d], b); e && (c[e.id] = e) } wi(c); var f = []; A(c, function (h, l) { f.push(l) }); return f } function wi(a) { var b = [], c; for (c in a) if (a.hasOwnProperty(c)) { var d = a[c]; d.prefix === "AW" && d.fa[xi[2]] && b.push(d.da) } for (var e = 0; e < b.length; ++e)delete a[b[e]] } var yi = {}, xi = (yi[0] = 0, yi[1] = 0, yi[2] = 1, yi[3] = 0, yi[4] = 1, yi[5] = 2, yi[6] = 0, yi[7] = 0, yi[8] = 0, yi); var zi = Number('') || 500, Ai = [], Bi = {}, Ci = {}, Di = { initialized: 11, complete: 12, interactive: 13 }, Ei = {}, Fi = Object.freeze((Ei[M.g.Ia] = !0, Ei)), Gi = H.location.search.indexOf("?gtm_diagnostics=") >= 0 || H.location.search.indexOf("&gtm_diagnostics=") >= 0, Hi = void 0;
  function Ii(a, b) { if (b.length && Hf) if (R(43)) { var c; (c = Bi)[a] != null || (c[a] = []); Ci[a] != null || (Ci[a] = []); var d = b.filter(function (e) { return !Ci[a].includes(e) }); Bi[a].push.apply(Bi[a], oa(d)); Ci[a].push.apply(Ci[a], oa(d)); !Hi && d.length > 0 && (Pb(F, "pagehide", Ji), Hi = F.setTimeout(function () { Qb(F, "pagehide", Ji); Mg(); Ai.length = 0; Bi = {}; Hi = void 0 }, zi)) } else Ai.push(a + "*" + b.join(".")) }
  function Ki(a, b, c) { if (Hf && a === "config") { var d, e = (d = ui(b)) == null ? void 0 : d.fa; if (!(e && e.length > 1)) { var f, h = Fb("google_tag_data", {}); h.td || (h.td = {}); f = h.td; var l = g(c.K); g(c.D, l); var m = [], n; for (n in f) if (f.hasOwnProperty(n)) { var p = Li(f[n], l); p.length && (Gi && console.log(p), m.push(n)) } m.length && (Ii(b, m), Sa("TAGGING", Di[H.readyState] || 14)); f[b] = l } } } function Mi(a, b) { var c = {}, d; for (d in b) b.hasOwnProperty(d) && (c[d] = !0); for (var e in a) a.hasOwnProperty(e) && (c[e] = !0); return c }
  function Li(a, b, c, d) { c = c === void 0 ? {} : c; d = d === void 0 ? "" : d; if (a === b) return []; var e = function (t, r) { var u; Ha(r) === "object" ? u = r[t] : Ha(r) === "array" && (u = r[t]); return u === void 0 ? Fi[t] : u }, f = Mi(a, b), h; for (h in f) if (f.hasOwnProperty(h)) { var l = (d ? d + "." : "") + h, m = e(h, a), n = e(h, b), p = Ha(m) === "object" || Ha(m) === "array", q = Ha(n) === "object" || Ha(n) === "array"; if (p && q) Li(m, n, c, l); else if (p || q || m !== n) c[l] = !0 } return Object.keys(c) }
  function Ni(a) { var b = []; if (R(43)) { if (Object.keys(Bi).length === 0) return []; for (var c in Bi) Bi.hasOwnProperty(c) && b.push(c + "*" + Bi[c].join(".")) } else { if (!Ai.length) return []; b = Ai } var d = [["tdc", b.join("!")]]; a.Oa && (a.fc(), Ai.length = 0); return d } function Ji() { Object.keys(Bi).length !== 0 && (F.clearTimeout(Hi), Ag()) } var Oi = function (a, b, c, d, e, f, h, l, m, n, p) { this.eventId = a; this.priorityId = b; this.D = c; this.P = d; this.H = e; this.K = f; this.F = h; this.eventMetadata = l; this.onSuccess = m; this.onFailure = n; this.isGtmEvent = p }, Pi = function (a, b) { var c = []; switch (b) { case 3: c.push(a.D); c.push(a.P); c.push(a.H); c.push(a.K); c.push(a.F); break; case 2: c.push(a.D); break; case 1: c.push(a.P); c.push(a.H); c.push(a.K); c.push(a.F); break; case 4: c.push(a.D), c.push(a.P), c.push(a.H), c.push(a.K) }return c }, S = function (a, b, c, d) {
    for (var e = ma(Pi(a, d === void 0 ? 3 :
      d)), f = e.next(); !f.done; f = e.next()) { var h = f.value; if (h[b] !== void 0) return h[b] } return c
  }, Qi = function (a) { for (var b = {}, c = Pi(a, 4), d = ma(c), e = d.next(); !e.done; e = d.next())for (var f = Object.keys(e.value), h = ma(f), l = h.next(); !l.done; l = h.next())b[l.value] = 1; return Object.keys(b) }, Ri = function (a, b, c) { function d(n) { Ka(n) && A(n, function (p, q) { f = !0; e[p] = q }) } var e = {}, f = !1, h = Pi(a, c === void 0 ? 3 : c); h.reverse(); for (var l = ma(h), m = l.next(); !m.done; m = l.next())d(m.value[b]); return f ? e : void 0 }, Si = function (a) {
    for (var b = [M.g.Uc,
    M.g.Qc, M.g.Rc, M.g.Sc, M.g.Tc, M.g.Vc, M.g.Wc], c = Pi(a, 3), d = ma(c), e = d.next(); !e.done; e = d.next()) { for (var f = e.value, h = {}, l = !1, m = ma(b), n = m.next(); !n.done; n = m.next()) { var p = n.value; f[p] !== void 0 && (h[p] = f[p], l = !0) } var q = l ? h : void 0; if (q) return q } return {}
  }, Ti = function (a, b) { this.eventId = a; this.priorityId = b; this.F = {}; this.P = {}; this.D = {}; this.H = {}; this.U = {}; this.K = {}; this.eventMetadata = {}; this.isGtmEvent = !1; this.onSuccess = function () { }; this.onFailure = function () { } }, Ui = function (a, b) { a.F = b; return a }, Vi = function (a,
    b) { a.P = b; return a }, Wi = function (a, b) { a.D = b; return a }, Xi = function (a, b) { a.H = b; return a }, Yi = function (a, b) { a.U = b; return a }, Zi = function (a, b) { a.K = b; return a }, $i = function (a, b) { a.eventMetadata = b || {}; return a }, aj = function (a, b) { a.onSuccess = b; return a }, bj = function (a, b) { a.onFailure = b; return a }, cj = function (a, b) { a.isGtmEvent = b; return a }, dj = function (a) { return new Oi(a.eventId, a.priorityId, a.F, a.P, a.D, a.H, a.K, a.eventMetadata, a.onSuccess, a.onFailure, a.isGtmEvent) }; var ej = {}; function fj(a, b, c) { Gf && a !== void 0 && (ej[a] = ej[a] || [], ej[a].push(c + b), Bg(a)) } function gj(a) { var b = a.eventId, c = a.Oa, d = [], e = ej[b] || []; e.length && d.push(["epr", e.join(".")]); c && delete ej[b]; return d } function hj(a, b, c, d) { var e = ui(c, d.isGtmEvent); e && ij.push("event", [b, a], e, d) } function jj(a, b, c, d) { var e = ui(c, d.isGtmEvent); e && ij.push("get", [a, b], e, d) }
  var kj = function () { this.K = {}; this.D = {}; this.P = {}; this.U = null; this.H = {}; this.F = !1; this.status = 1 }, lj = function (a, b, c, d) { this.F = ib(); this.D = b; this.args = c; this.messageContext = d; this.type = a }, mj = function () { this.destinations = {}; this.F = {}; this.D = [] }, nj = function (a, b) { var c = b.da; return a.destinations[c] = a.destinations[c] || new kj }, oj = function (a, b, c, d) {
    if (d.D) {
      var e = nj(a, d.D), f = e.U; if (f) {
        var h = g(c, null), l = g(e.K[d.D.id], null), m = g(e.H, null), n = g(e.D, null), p = g(a.F, null), q = {}; if (Gf) try { q = g(Oe) } catch (v) { L(72) } var t =
          d.D.prefix, r = function (v) { fj(d.messageContext.eventId, t, v) }, u = dj(cj(bj(aj($i(Yi(Xi(Zi(Wi(Vi(Ui(new Ti(d.messageContext.eventId, d.messageContext.priorityId), h), l), m), n), p), q), d.messageContext.eventMetadata), function () { if (r) { var v = r; r = void 0; v("2"); if (d.messageContext.onSuccess) d.messageContext.onSuccess() } }), function () { if (r) { var v = r; r = void 0; v("3"); if (d.messageContext.onFailure) d.messageContext.onFailure() } }), !!d.messageContext.isGtmEvent)); try {
            fj(d.messageContext.eventId, t, "1"), Ki(d.type, d.D.id, u),
            f(d.D.id, b, d.F, u)
          } catch (v) { fj(d.messageContext.eventId, t, "4") }
      }
    }
  }; mj.prototype.register = function (a, b, c) { var d = nj(this, a); d.status !== 3 && (d.U = b, d.status = 3, c && (g(d.D, c), d.D = c), this.flush()) }; mj.prototype.push = function (a, b, c, d) { c !== void 0 && (nj(this, c).status === 1 && (nj(this, c).status = 2, this.push("require", [{}], c, {})), nj(this, c).F && (d.deferrable = !1)); this.D.push(new lj(a, c, b, d)); d.deferrable || this.flush() };
  mj.prototype.flush = function (a) {
    for (var b = this, c = [], d = !1, e = {}; this.D.length; e = { Ub: void 0, Nf: void 0 }) {
      var f = this.D[0], h = f.D; if (f.messageContext.deferrable) !h || nj(this, h).F ? (f.messageContext.deferrable = !1, this.D.push(f)) : c.push(f), this.D.shift(); else {
        switch (f.type) {
          case "require": if (nj(this, h).status !== 3 && !a) { this.D.push.apply(this.D, c); return } break; case "set": A(f.args[0], function (t, r) { g(nb(t, r), b.F) }); break; case "config": var l = nj(this, h); e.Ub = {}; A(f.args[0], function (t) {
            return function (r, u) {
              g(nb(r, u),
                t.Ub)
            }
          }(e)); var m = !!e.Ub[M.g.Sb]; delete e.Ub[M.g.Sb]; var n = h.da === h.id; m || (n ? l.H = {} : l.K[h.id] = {}); l.F && m || oj(this, M.g.Z, e.Ub, f); l.F = !0; n ? g(e.Ub, l.H) : (g(e.Ub, l.K[h.id]), L(70)); d = !0; break; case "event": e.Nf = {}; A(f.args[0], function (t) { return function (r, u) { g(nb(r, u), t.Nf) } }(e)); oj(this, f.args[1], e.Nf, f); break; case "get": var p = {}, q = (p[M.g.nb] = f.args[0], p[M.g.xb] = f.args[1], p); oj(this, M.g.Pa, q, f)
        }this.D.shift(); pj(this, f)
      }
    } this.D.push.apply(this.D, c); d && this.flush()
  };
  var pj = function (a, b) { if (b.type !== "require") if (b.D) for (var c = nj(a, b.D).P[b.type] || [], d = 0; d < c.length; d++)c[d](); else for (var e in a.destinations) if (a.destinations.hasOwnProperty(e)) { var f = a.destinations[e]; if (f && f.P) for (var h = f.P[b.type] || [], l = 0; l < h.length; l++)h[l]() } }, qj = function (a, b) { var c = ij, d = g(b, null); g(nj(c, a).D, d); nj(c, a).D = d }, ij = new mj; function rj(a, b, c, d) { d = d === void 0 ? !1 : d; a.google_image_requests || (a.google_image_requests = []); var e = oh("IMG", a.document); if (c) { var f = function () { if (c) { var h = a.google_image_requests, l = xb(h, e); l >= 0 && Array.prototype.splice.call(h, l, 1) } Tg(e, "load", f); Tg(e, "error", f) }; Sg(e, "load", f); Sg(e, "error", f) } d && (e.attributionSrc = ""); e.src = b; a.google_image_requests.push(e) }
  var tj = function (a) { var b; b = b === void 0 ? !1 : b; var c = "https://pagead2.googlesyndication.com/pagead/gen_204?id=tcfe"; mh(a, function (d, e) { if (d || d === 0) c += "&" + e + "=" + encodeURIComponent("" + d) }); sj(c, b) }, sj = function (a, b) {
    var c = window, d; b = b === void 0 ? !1 : b; d = d === void 0 ? !1 : d; if (c.fetch) {
      var e = { keepalive: !0, credentials: "include", redirect: "follow", method: "get", mode: "no-cors" }; d && (e.mode = "cors", "setAttributionReporting" in XMLHttpRequest.prototype ? e.attributionReporting = { eventSourceEligible: "true", triggerEligible: "false" } :
        e.headers = { "Attribution-Reporting-Eligible": "event-source" }); c.fetch(a, e)
    } else rj(c, a, b === void 0 ? !1 : b, d === void 0 ? !1 : d)
  }; var uj = function () { this.P = this.P; this.F = this.F }; uj.prototype.P = !1; uj.prototype.dispose = function () { this.P || (this.P = !0, this.Ja()) }; uj.prototype.addOnDisposeCallback = function (a, b) { this.P ? b !== void 0 ? a.call(b) : a() : (this.F || (this.F = []), this.F.push(b !== void 0 ? Ea(a, b) : a)) }; uj.prototype.Ja = function () { if (this.F) for (; this.F.length;)this.F.shift()() }; var vj = function (a) { a.addtlConsent !== void 0 && typeof a.addtlConsent !== "string" && (a.addtlConsent = void 0); a.gdprApplies !== void 0 && typeof a.gdprApplies !== "boolean" && (a.gdprApplies = void 0); return a.tcString !== void 0 && typeof a.tcString !== "string" || a.listenerId !== void 0 && typeof a.listenerId !== "number" ? 2 : a.cmpStatus && a.cmpStatus !== "error" ? 0 : 3 }, wj = function (a, b) {
    b = b === void 0 ? {} : b; uj.call(this); this.H = a; this.D = null; this.U = {}; this.Dc = 0; var c; this.Gb = (c = b.Vl) != null ? c : 500; var d; this.Fb = (d = b.Om) != null ? d : !1; this.K =
      null
  }; ya(wj, uj); wj.prototype.Ja = function () { this.U = {}; this.K && (Tg(this.H, "message", this.K), delete this.K); delete this.U; delete this.H; delete this.D; uj.prototype.Ja.call(this) }; var yj = function (a) { return typeof a.H.__tcfapi === "function" || xj(a) != null };
  wj.prototype.addEventListener = function (a) {
    var b = this, c = { internalBlockOnErrors: this.Fb }, d = Rg(function () { return a(c) }), e = 0; this.Gb !== -1 && (e = setTimeout(function () { c.tcString = "tcunavailable"; c.internalErrorState = 1; d() }, this.Gb)); var f = function (h, l) { clearTimeout(e); h ? (c = h, c.internalErrorState = vj(c), c.internalBlockOnErrors = b.Fb, l && c.internalErrorState === 0 || (c.tcString = "tcunavailable", l || (c.internalErrorState = 3))) : (c.tcString = "tcunavailable", c.internalErrorState = 3); a(c) }; try { zj(this, "addEventListener", f) } catch (h) {
      c.tcString =
      "tcunavailable", c.internalErrorState = 3, e && (clearTimeout(e), e = 0), d()
    }
  }; wj.prototype.removeEventListener = function (a) { a && a.listenerId && zj(this, "removeEventListener", null, a.listenerId) };
  var Bj = function (a, b, c) {
    var d; d = d === void 0 ? "755" : d; var e; a: { if (a.publisher && a.publisher.restrictions) { var f = a.publisher.restrictions[b]; if (f !== void 0) { e = f[d === void 0 ? "755" : d]; break a } } e = void 0 } var h = e; if (h === 0) return !1; var l = c; c === 2 ? (l = 0, h === 2 && (l = 1)) : c === 3 && (l = 1, h === 1 && (l = 0)); var m; if (l === 0) if (a.purpose && a.vendor) { var n = Aj(a.vendor.consents, d === void 0 ? "755" : d); m = n && b === "1" && a.purposeOneTreatment && a.publisherCC === "CH" ? !0 : n && Aj(a.purpose.consents, b) } else m = !0; else m = l === 1 ? a.purpose && a.vendor ? Aj(a.purpose.legitimateInterests,
      b) && Aj(a.vendor.legitimateInterests, d === void 0 ? "755" : d) : !0 : !0; return m
  }, Aj = function (a, b) { return !(!a || !a[b]) }, zj = function (a, b, c, d) { c || (c = function () { }); if (typeof a.H.__tcfapi === "function") { var e = a.H.__tcfapi; e(b, 2, c, d) } else if (xj(a)) { Cj(a); var f = ++a.Dc; a.U[f] = c; if (a.D) { var h = {}; a.D.postMessage((h.__tcfapiCall = { command: b, version: 2, callId: f, parameter: d }, h), "*") } } else c({}, !1) }, xj = function (a) {
    if (a.D) return a.D; var b; a: {
      for (var c = a.H, d = 0; d < 50; ++d) {
        var e; try { e = !(!c.frames || !c.frames.__tcfapiLocator) } catch (l) {
          e =
          !1
        } if (e) { b = c; break a } var f; b: { try { var h = c.parent; if (h && h != c) { f = h; break b } } catch (l) { } f = null } if (!(c = f)) break
      } b = null
    } a.D = b; return a.D
  }, Cj = function (a) { a.K || (a.K = function (b) { try { var c; c = (typeof b.data === "string" ? JSON.parse(b.data) : b.data).__tcfapiReturn; a.U[c.callId](c.returnValue, c.success) } catch (d) { } }, Sg(a.H, "message", a.K)) }, Dj = function (a) {
    if (a.gdprApplies === !1) return !0; a.internalErrorState === void 0 && (a.internalErrorState = vj(a)); return a.cmpStatus === "error" || a.internalErrorState !== 0 ? a.internalBlockOnErrors ?
      (tj({ e: String(a.internalErrorState) }), !1) : !0 : a.cmpStatus !== "loaded" || a.eventStatus !== "tcloaded" && a.eventStatus !== "useractioncomplete" ? !1 : !0
  }; var Ej = { 1: 0, 3: 0, 4: 0, 7: 3, 9: 3, 10: 3 }; function Fj() { var a = se.tcf || {}; return se.tcf = a }
  var Gj = function () { return new wj(F, { Vl: -1 }) }, Mj = function () {
    var a = Fj(), b = Gj(); yj(b) && !Hj() && !Ij() && L(124); if (!a.active && yj(b)) {
      Hj() && (a.active = !0, a.Jb = {}, a.cmpId = 0, a.tcfPolicyVersion = 0, Kh().active = !0, a.tcString = "tcunavailable"); pi(); try {
        b.addEventListener(function (c) {
          if (c.internalErrorState !== 0) Jj(a), qi([M.g.O, M.g.oa, M.g.N]), Kh().active = !0; else if (a.gdprApplies = c.gdprApplies, a.cmpId = c.cmpId, a.enableAdvertiserConsentMode = c.enableAdvertiserConsentMode, Ij() && (a.active = !0), !Kj(c) || Hj() || Ij()) {
            a.tcfPolicyVersion =
            c.tcfPolicyVersion; var d; if (c.gdprApplies === !1) { var e = {}, f; for (f in Ej) Ej.hasOwnProperty(f) && (e[f] = !0); d = e; b.removeEventListener(c) } else if (Kj(c)) { var h = {}, l; for (l in Ej) if (Ej.hasOwnProperty(l)) if (l === "1") { var m, n = c, p = { Fk: !0 }; p = p === void 0 ? {} : p; m = Dj(n) ? n.gdprApplies === !1 ? !0 : n.tcString === "tcunavailable" ? !p.ui : (p.ui || n.gdprApplies !== void 0 || p.Fk) && (p.ui || typeof n.tcString === "string" && n.tcString.length) ? Bj(n, "1", 0) : !0 : !1; h["1"] = m } else h[l] = Bj(c, l, Ej[l]); d = h } if (d) {
              a.tcString = c.tcString || "tcempty"; a.Jb =
                d; var q = {}, t = (q[M.g.O] = a.Jb["1"] ? "granted" : "denied", q); a.gdprApplies !== !0 ? (qi([M.g.O, M.g.oa, M.g.N]), Kh().active = !0) : (t[M.g.oa] = a.Jb["3"] && a.Jb["4"] ? "granted" : "denied", typeof a.tcfPolicyVersion === "number" && a.tcfPolicyVersion >= 4 ? t[M.g.N] = a.Jb["1"] && a.Jb["7"] ? "granted" : "denied" : qi([M.g.N]), mi(t, { eventId: 0 }, { gdprApplies: a ? a.gdprApplies : void 0, tcString: Lj() || "" }))
            }
          } else qi([M.g.O, M.g.oa, M.g.N])
        })
      } catch (c) { Jj(a), qi([M.g.O, M.g.oa, M.g.N]), Kh().active = !0 }
    }
  };
  function Jj(a) { a.type = "e"; a.tcString = "tcunavailable" } function Kj(a) { return a.eventStatus === "tcloaded" || a.eventStatus === "useractioncomplete" || a.eventStatus === "cmpuishown" } var Hj = function () { return F.gtag_enable_tcf_support === !0 }; function Ij() { return Fj().enableAdvertiserConsentMode === !0 }
  var Lj = function () { var a = Fj(); if (a.active) return a.tcString }, Nj = function () { var a = Fj(); if (a.active && a.gdprApplies !== void 0) return a.gdprApplies ? "1" : "0" }, Oj = function (a) { if (!Ej.hasOwnProperty(String(a))) return !0; var b = Fj(); return b.active && b.Jb ? !!b.Jb[String(a)] : !0 }; var Pj = [M.g.O, M.g.R, M.g.N, M.g.oa], Qj = {}, Rj = (Qj[M.g.O] = 1, Qj[M.g.R] = 2, Qj); function Sj(a) { if (a === void 0) return 0; switch (S(a, M.g.ka)) { case void 0: return 1; case !1: return 3; default: return 2 } }
  var Tj = function (a) { if (R(60) && Eh() === "US-CO" && Bb.globalPrivacyControl === !0) return !1; var b = Sj(a); if (b === 3) return !1; switch (Uh(M.g.oa)) { case 1: case 3: return !0; case 2: return !1; case 4: return b === 2; case 0: return !0; default: return !1 } }, Uj = function () { return ci() || !Th(M.g.O) || !Th(M.g.R) }, Vj = function () { var a = {}, b; for (b in Rj) Rj.hasOwnProperty(b) && (a[Rj[b]] = Uh(b)); return "G1" + dc(a[1] || 0) + dc(a[2] || 0) }, Wj = {}, Xj = (Wj[M.g.O] = 0, Wj[M.g.R] = 1, Wj[M.g.N] = 2, Wj[M.g.oa] = 3, Wj);
  function Yj(a) { switch (a) { case void 0: return 1; case !0: return 3; case !1: return 2; default: return 0 } }
  var Zj = function (a) {
    for (var b = "1", c = 0; c < Pj.length; c++) { var d = b, e, f = Pj[c], h = Sh.delegatedConsentTypes[f]; e = h === void 0 ? 0 : Xj.hasOwnProperty(h) ? 12 | Xj[h] : 8; var l = Kh(); l.accessedAny = !0; var m = l.entries[f] || {}; e = e << 2 | Yj(m.implicit); b = d + ("" + "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[e] + "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[Yj(m.declare) << 4 | Yj(m.default) << 2 | Yj(m.update)]) } var n = b, p; p = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[(ci() ?
      1 : 0) << 2 | Sj(a)]; return n + p
  }, ak = function () { if (!Th(M.g.N)) return "-"; for (var a = Object.keys(qe), b = Vh(a), c = "", d = ma(a), e = d.next(); !e.done; e = d.next()) { var f = e.value; b[f] && (c += qe[f]) } return c || "-" }, bk = function () { return Dh["6"] !== !1 || (Hj() || Ij()) && Nj() === "1" ? "1" : "0" }, ck = function () { return (Dh["6"] !== !1 ? !0 : !(!Hj() && !Ij()) && Nj() === "1") || !Th(M.g.N) }, dk = function () {
    var a = "0", b = "0", c; var d = Fj(); c = d.active ? d.cmpId : void 0; typeof c === "number" && c >= 0 && c <= 4095 && (a = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[c >>
      6 & 63], b = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[c & 63]); var e = "0", f; var h = Fj(); f = h.active ? h.tcfPolicyVersion : void 0; typeof f === "number" && f >= 0 && f <= 63 && (e = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[f]); var l = 0; Dh["6"] !== !1 && (l |= 1); Nj() === "1" && (l |= 2); Hj() && (l |= 4); var m; var n = Fj(); m = n.enableAdvertiserConsentMode !== void 0 ? n.enableAdvertiserConsentMode ? "1" : "0" : void 0; m === "1" && (l |= 8); Kh().waitPeriodTimedOut && (l |= 16); return "1" + a + b + e + "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"[l]
  }; function ek() { var a = !1; return a } var fk = { UA: 1, AW: 2, DC: 3, G: 4, GF: 5, GT: 12, GTM: 14, HA: 6, MC: 7 };
  function gk(a) {
    a = a === void 0 ? {} : a; var b = Nf.ctid.split("-")[0].toUpperCase(), c = {}; c.ctid = Nf.ctid; c.Hl = re.sd; c.Ll = re.Bf; c.jl = Lf.pd ? 2 : 1; c.Ql = a.Ki; c.li = Nf.canonicalContainerId; c.li !== a.wa && (c.wa = a.wa); var d = Wf(); c.tl = d ? d.canonicalContainerId : void 0; xe ? (c.Re = fk[b], c.Re || (c.Re = 0)) : c.Re = Be ? 13 : 10; Ke.K ? (c.Pe = 0, c.ik = 2) : ze ? c.Pe = 1 : ek() ? c.Pe = 2 : c.Pe = 3; var e = {}; e[6] = Mf; c.pk = e; var f = a.Ef, h; var l = c.Re, m = c.Pe; l === void 0 ? h = "" : (m || (m = 0), h = "" + fc(1, 1) + cc(l << 2 | m)); var n = c.ik, p = "4" + h + (n ? "" + fc(2, 1) + cc(n) : ""), q, t = c.Ll; q = t &&
      ec.test(t) ? "" + fc(3, 2) + t : ""; var r, u = c.Hl; r = u ? "" + fc(4, 1) + cc(u) : ""; var v; var w = c.ctid; if (w && f) { var y = w.split("-"), x = y[0].toUpperCase(); if (x !== "GTM" && x !== "OPT") v = ""; else { var B = y[1]; v = "" + fc(5, 3) + cc(1 + B.length) + (c.jl || 0) + B } } else v = ""; var z = c.Ql, C = c.li, G = c.wa, D = c.fn, E = p + q + r + v + (z ? "" + fc(6, 1) + cc(z) : "") + (C ? "" + fc(7, 3) + cc(C.length) + C : "") + (G ? "" + fc(8, 3) + cc(G.length) + G : "") + (D ? "" + fc(9, 3) + cc(D.length) + D : ""), K; var I = c.pk; I = I === void 0 ? {} : I; for (var N = [], Q = ma(Object.keys(I)), aa = Q.next(); !aa.done; aa = Q.next()) {
        var W = aa.value;
        N[Number(W)] = I[W]
      } if (N.length) { var O = fc(10, 3), ha; if (N.length === 0) ha = cc(0); else { for (var ea = [], ca = 0, da = !1, Da = 0; Da < N.length; Da++) { da = !0; var va = Da % 6; N[Da] && (ca |= 1 << va); va === 5 && (ea.push(cc(ca)), ca = 0, da = !1) } da && ea.push(cc(ca)); ha = ea.join("") } var xa = ha; K = "" + O + cc(xa.length) + xa } else K = ""; var Ia = c.tl; return E + K + (Ia ? "" + fc(11, 3) + cc(Ia.length) + Ia : "")
  } var hk = { Yh: "service_worker_endpoint", ai: "shared_user_id", Wj: "shared_user_id_requested", Ge: "shared_user_id_source" }, ik; function jk(a) { if (!ik) { ik = {}; for (var b = ma(Object.keys(hk)), c = b.next(); !c.done; c = b.next())ik[hk[c.value]] = !0 } return !!ik[a] }
  function kk(a, b) { b = b === void 0 ? !1 : b; if (jk(a)) { var c, d, e = (d = (c = Fb("google_tag_data", {})).xcd) != null ? d : c.xcd = {}; if (e[a]) return e[a]; if (b) { var f = void 0, h = 1, l = {}, m = { set: function (n) { f = n; m.notify() }, get: function () { return f }, subscribe: function (n) { l[String(h)] = n; return h++ }, unsubscribe: function (n) { var p = String(n); return l.hasOwnProperty(p) ? (delete l[p], !0) : !1 }, notify: function () { for (var n = ma(Object.keys(l)), p = n.next(); !p.done; p = n.next()) { var q = p.value; try { l[q](a, f) } catch (t) { } } } }; return e[a] = m } } }
  function lk() { var a = kk(hk.Wj, !0); a && a.set(!0) } function mk(a) { var b; return (b = kk(a)) == null ? void 0 : b.get() } function nk(a) { var b = hk.Ge; if (typeof a === "function") { var c; return (c = kk(b, !0)) == null ? void 0 : c.subscribe(a) } } function ok(a) { var b = kk(hk.Ge); b && b.unsubscribe(a) } function pk(a) { return a.origin !== "null" } function qk(a, b, c, d) { var e; if (rk(d)) { for (var f = [], h = String(b || sk()).split(";"), l = 0; l < h.length; l++) { var m = h[l].split("="), n = m[0].replace(/^\s*|\s*$/g, ""); if (n && n === a) { var p = m.slice(1).join("=").replace(/^\s*|\s*$/g, ""); p && c && (p = decodeURIComponent(p)); f.push(p) } } e = f } else e = []; return e }
  function tk(a, b, c, d, e) { if (rk(e)) { var f = uk(a, d, e); if (f.length === 1) return f[0].id; if (f.length !== 0) { f = vk(f, function (h) { return h.yk }, b); if (f.length === 1) return f[0].id; f = vk(f, function (h) { return h.wl }, c); return f[0] ? f[0].id : void 0 } } } function wk(a, b, c, d) { var e = sk(), f = window; pk(f) && (f.document.cookie = a); var h = sk(); return e !== h || c !== void 0 && qk(b, h, !1, d).indexOf(c) >= 0 }
  function xk(a, b, c) {
    function d(r, u, v) { if (v == null) return delete h[u], r; h[u] = v; return r + "; " + u + "=" + v } function e(r, u) { if (u == null) return r; h[u] = !0; return r + "; " + u } if (!rk(c.ab)) return 2; var f; b == null ? f = a + "=deleted; expires=" + (new Date(0)).toUTCString() : (c.encode && (b = encodeURIComponent(b)), b = yk(b), f = a + "=" + b); var h = {}; f = d(f, "path", c.path); var l; c.expires instanceof Date ? l = c.expires.toUTCString() : c.expires != null && (l = "" + c.expires); f = d(f, "expires", l); f = d(f, "max-age", c.Vm); f = d(f, "samesite", c.bn); c.dn && (f = e(f,
      "secure")); var m = c.domain; if (m && m.toLowerCase() === "auto") { for (var n = zk(), p = 0; p < n.length; ++p) { var q = n[p] !== "none" ? n[p] : void 0, t = d(f, "domain", q); t = e(t, c.flags); if (!Ak(q, c.path) && wk(t, a, b, c.ab)) return 0 } return 1 } m && m.toLowerCase() !== "none" && (f = d(f, "domain", m)); f = e(f, c.flags); return Ak(m, c.path) ? 1 : wk(f, a, b, c.ab) ? 0 : 1
  } function Bk(a, b, c) { c.path == null && (c.path = "/"); c.domain || (c.domain = "auto"); return xk(a, b, c) }
  function vk(a, b, c) { for (var d = [], e = [], f, h = 0; h < a.length; h++) { var l = a[h], m = b(l); m === c ? d.push(l) : f === void 0 || m < f ? (e = [l], f = m) : m === f && e.push(l) } return d.length > 0 ? d : e } function uk(a, b, c) { for (var d = [], e = qk(a, void 0, void 0, c), f = 0; f < e.length; f++) { var h = e[f].split("."), l = h.shift(); if (!b || !l || b.indexOf(l) !== -1) { var m = h.shift(); if (m) { var n = m.split("-"); d.push({ id: h.join("."), yk: Number(n[0]) || 1, wl: Number(n[1]) || 1 }) } } } return d } function yk(a) { a && a.length > 1200 && (a = a.substring(0, 1200)); return a }
  var Ck = /^(www\.)?google(\.com?)?(\.[a-z]{2})?$/, Dk = /(^|\.)doubleclick\.net$/i; function Ak(a, b) { return a !== void 0 && (Dk.test(window.document.location.hostname) || b === "/" && Ck.test(a)) } function Ek(a) { if (!a) return 1; a = a.indexOf(".") === 0 ? a.substring(1) : a; return a.split(".").length } function Fk(a) { if (!a || a === "/") return 1; a[0] !== "/" && (a = "/" + a); a[a.length - 1] !== "/" && (a += "/"); return a.split("/").length - 1 } function Gk(a, b) { var c = "" + Ek(a), d = Fk(b); d > 1 && (c += "-" + d); return c }
  var sk = function () { return pk(window) ? window.document.cookie : "" }, rk = function (a) { return a && Ph().D() ? (Array.isArray(a) ? a : [a]).every(function (b) { return Wh(b) && Th(b) }) : !0 }, zk = function () { var a = [], b = window.document.location.hostname.split("."); if (b.length === 4) { var c = b[b.length - 1]; if (Number(c).toString() === c) return ["none"] } for (var d = b.length - 2; d >= 0; d--)a.push(b.slice(d).join(".")); var e = window.document.location.hostname; Dk.test(e) || Ck.test(e) || a.push("none"); return a }; function Hk(a) { var b = Math.round(Math.random() * 2147483647), c; if (a) { var d = 1, e, f, h; if (a) for (d = 0, f = a.length - 1; f >= 0; f--)h = a.charCodeAt(f), d = (d << 6 & 268435455) + h + (h << 14), e = d & 266338304, d = e !== 0 ? d ^ e >> 21 : d; c = String(b ^ d & 2147483647) } else c = String(b); return c } function Ik(a) { return [Hk(a), Math.round(ib() / 1E3)].join(".") } function Jk(a, b, c, d) { var e, f = Number(a.Za != null ? a.Za : void 0); f !== 0 && (e = new Date((b || ib()) + 1E3 * (f || 7776E3))); return { path: a.path, domain: a.domain, flags: a.flags, encode: !!c, expires: e, ab: d } } var Kk; function Lk() { function a(h) { c(h.target || h.srcElement || {}) } function b(h) { d(h.target || h.srcElement || {}) } var c = Mk, d = Nk, e = Ok(); if (!e.init) { Pb(H, "mousedown", a); Pb(H, "keyup", a); Pb(H, "submit", b); var f = HTMLFormElement.prototype.submit; HTMLFormElement.prototype.submit = function () { d(this); f.call(this) }; e.init = !0 } } function Pk(a, b, c, d, e) { var f = { callback: a, domains: b, fragment: c === 2, placement: c, forms: d, sameHost: e }; Ok().decorators.push(f) }
  function Qk(a, b, c) { for (var d = Ok().decorators, e = {}, f = 0; f < d.length; ++f) { var h = d[f], l; if (l = !c || h.forms) a: { var m = h.domains, n = a, p = !!h.sameHost; if (m && (p || n !== H.location.hostname)) for (var q = 0; q < m.length; q++)if (m[q] instanceof RegExp) { if (m[q].test(n)) { l = !0; break a } } else if (n.indexOf(m[q]) >= 0 || p && m[q].indexOf(n) >= 0) { l = !0; break a } l = !1 } if (l) { var t = h.placement; t === void 0 && (t = h.fragment ? 2 : 1); t === b && lb(e, h.callback()) } } return e }
  function Ok() { var a = Fb("google_tag_data", {}), b = a.gl; b && b.decorators || (b = { decorators: [] }, a.gl = b); return b } var Rk = /(.*?)\*(.*?)\*(.*)/, Sk = /^https?:\/\/([^\/]*?)\.?cdn\.ampproject\.org\/?(.*)/, Tk = /^(?:www\.|m\.|amp\.)+/, Uk = /([^?#]+)(\?[^#]*)?(#.*)?/; function Vk(a) { var b = Uk.exec(a); if (b) return { ig: b[1], query: b[2], fragment: b[3] } }
  function Wk(a, b) { var c = [Bb.userAgent, (new Date).getTimezoneOffset(), Bb.userLanguage || Bb.language, Math.floor(ib() / 60 / 1E3) - (b === void 0 ? 0 : b), a].join("*"), d; if (!(d = Kk)) { for (var e = Array(256), f = 0; f < 256; f++) { for (var h = f, l = 0; l < 8; l++)h = h & 1 ? h >>> 1 ^ 3988292384 : h >>> 1; e[f] = h } d = e } Kk = d; for (var m = 4294967295, n = 0; n < c.length; n++)m = m >>> 8 ^ Kk[(m ^ c.charCodeAt(n)) & 255]; return ((m ^ -1) >>> 0).toString(36) }
  function Xk() { return function (a) { var b = tf(F.location.href), c = b.search.replace("?", ""), d = mf(c, "_gl", !0) || ""; a.query = Yk(d) || {}; var e = pf(b, "fragment"), f; var h = -1; if (e.substring(0, 4) === "_gl=") h = 4; else { var l = e.indexOf("&_gl="); l > 0 && (h = l + 3 + 2) } if (h < 0) f = void 0; else { var m = e.indexOf("&", h); f = m < 0 ? e.substring(h) : e.substring(h, m) } a.fragment = Yk(f || "") || {} } } function Zk(a) { var b = Xk(), c = Ok(); c.data || (c.data = { query: {}, fragment: {} }, b(c.data)); var d = {}, e = c.data; e && (lb(d, e.query), a && lb(d, e.fragment)); return d }
  var Yk = function (a) { try { var b = $k(a, 3); if (b !== void 0) { for (var c = {}, d = b ? b.split("*") : [], e = 0; e + 1 < d.length; e += 2) { var f = d[e], h = Qa(d[e + 1]); c[f] = h } Sa("TAGGING", 6); return c } } catch (l) { Sa("TAGGING", 8) } }; function $k(a, b) { if (a) { var c; a: { for (var d = a, e = 0; e < 3; ++e) { var f = Rk.exec(d); if (f) { c = f; break a } d = decodeURIComponent(d) } c = void 0 } var h = c; if (h && h[1] === "1") { var l = h[3], m; a: { for (var n = h[2], p = 0; p < b; ++p)if (n === Wk(l, p)) { m = !0; break a } m = !1 } if (m) return l; Sa("TAGGING", 7) } } }
  function al(a, b, c, d, e) { function f(p) { var q = p, t = (new RegExp("(.*?)(^|&)" + a + "=([^&]*)&?(.*)")).exec(q), r = q; if (t) { var u = t[2], v = t[4]; r = t[1]; v && (r = r + u + v) } p = r; var w = p.charAt(p.length - 1); p && w !== "&" && (p += "&"); return p + n } d = d === void 0 ? !1 : d; e = e === void 0 ? !1 : e; var h = Vk(c); if (!h) return ""; var l = h.query || "", m = h.fragment || "", n = a + "=" + b; d ? m.substring(1).length !== 0 && e || (m = "#" + f(m.substring(1))) : l = "?" + f(l.substring(1)); return "" + h.ig + l + m }
  function bl(a, b) {
    function c(n, p, q) { var t; a: { for (var r in n) if (n.hasOwnProperty(r)) { t = !0; break a } t = !1 } if (t) { var u, v = [], w; for (w in n) if (n.hasOwnProperty(w)) { var y = n[w]; y !== void 0 && y === y && y !== null && y.toString() !== "[object Object]" && (v.push(w), v.push(Pa(String(y)))) } var x = v.join("*"); u = ["1", Wk(x), x].join("*"); d ? (df(3) || df(1) || !p) && cl("_gl", u, a, p, q) : dl("_gl", u, a, p, q) } } var d = (a.tagName || "").toUpperCase() === "FORM", e = Qk(b, 1, d), f = Qk(b, 2, d), h = Qk(b, 4, d), l = Qk(b, 3, d); c(e, !1, !1); c(f, !0, !1); df(1) && c(h, !0, !0); for (var m in l) l.hasOwnProperty(m) &&
      el(m, l[m], a)
  } function el(a, b, c) { c.tagName.toLowerCase() === "a" ? dl(a, b, c) : c.tagName.toLowerCase() === "form" && cl(a, b, c) } function dl(a, b, c, d, e) { d = d === void 0 ? !1 : d; e = e === void 0 ? !1 : e; var f; if (f = c.href) { var h; if (!(h = !df(4) || d)) { var l = F.location.href, m = Vk(c.href), n = Vk(l); h = !(m && n && m.ig === n.ig && m.query === n.query && m.fragment) } f = h } if (f) { var p = al(a, b, c.href, d, e); wb.test(p) && (c.href = p) } }
  function cl(a, b, c, d, e) { d = d === void 0 ? !1 : d; e = e === void 0 ? !1 : e; if (c && c.action) { var f = (c.method || "").toLowerCase(); if (f !== "get" || d) { if (f === "get" || f === "post") { var h = al(a, b, c.action, d, e); wb.test(h) && (c.action = h) } } else { for (var l = c.childNodes || [], m = !1, n = 0; n < l.length; n++) { var p = l[n]; if (p.name === a) { p.setAttribute("value", b); m = !0; break } } if (!m) { var q = H.createElement("input"); q.setAttribute("type", "hidden"); q.setAttribute("name", a); q.setAttribute("value", b); c.appendChild(q) } } } }
  function Mk(a) { try { var b; a: { for (var c = a, d = 100; c && d > 0;) { if (c.href && c.nodeName.match(/^a(?:rea)?$/i)) { b = c; break a } c = c.parentNode; d-- } b = null } var e = b; if (e) { var f = e.protocol; f !== "http:" && f !== "https:" || bl(e, e.hostname) } } catch (h) { } } function Nk(a) { try { if (a.action) { var b = pf(tf(a.action), "host"); bl(a, b) } } catch (c) { } } function fl(a, b, c, d) { Lk(); var e = c === "fragment" ? 2 : 1; d = !!d; Pk(a, b, e, d, !1); e === 2 && Sa("TAGGING", 23); d && Sa("TAGGING", 24) } function gl(a, b) { Lk(); Pk(a, [of(F.location, "host", !0)], b, !0, !0) }
  function hl() { var a = H.location.hostname, b = Sk.exec(H.referrer); if (!b) return !1; var c = b[2], d = b[1], e = ""; if (c) { var f = c.split("/"), h = f[1]; e = h === "s" ? decodeURIComponent(f[2]) : decodeURIComponent(h) } else if (d) { if (d.indexOf("xn--") === 0) return !1; e = d.replace(/-/g, ".").replace(/\.\./g, "-") } var l = a.replace(Tk, ""), m = e.replace(Tk, ""), n; if (!(n = l === m)) { var p = "." + m; n = l.substring(l.length - p.length, l.length) === p } return n } function il(a, b) { return a === !1 ? !1 : a || b || hl() } var jl = ["1"], kl = {}, ll = {}; function ml(a, b) { b = b === void 0 ? !0 : b; var c = nl(a.prefix); if (!kl[c]) if (yl(c, a.path, a.domain)) { var d = ll[nl(a.prefix)]; zl(a, d ? d.id : void 0, d ? d.eg : void 0) } else { var e = vf("auiddc"); e ? (Sa("TAGGING", 17), kl[c] = e) : b && (Al(nl(a.prefix), Ik(), a), yl(c, a.path, a.domain)) } } function zl(a, b, c) { var d = nl(a.prefix), e = kl[d]; if (e) { var f = e.split("."); if (f.length === 2) { var h = Number(f[1]) || 0; if (h) { var l = e; b && (l = e + "." + b + "." + (c ? c : Math.floor(ib() / 1E3))); Al(d, l, a, h * 1E3) } } } }
  function Al(a, b, c, d) { var e = ["1", Gk(c.domain, c.path), b].join("."), f = Jk(c, d); f.ab = Bl(); Bk(a, e, f) } function yl(a, b, c) { var d = tk(a, Ek(b), Fk(c), jl, Bl()); if (!d) return !1; Cl(a, d); return !0 } function Cl(a, b) { var c = b.split("."); c.length === 5 ? (kl[a] = c.slice(0, 2).join("."), ll[a] = { id: c.slice(2, 4).join("."), eg: Number(c[4]) || 0 }) : c.length === 3 ? ll[a] = { id: c.slice(0, 2).join("."), eg: Number(c[2]) || 0 } : kl[a] = b } function nl(a) { return (a || "_gcl") + "_au" }
  function Dl(a) { function b() { Th(c) && a() } var c = Bl(); fi(function () { b(); Th(c) || gi(b, c) }, c) } function El(a) { var b = Zk(!0), c = nl(a.prefix); Dl(function () { var d = b[c]; if (d) { Cl(c, d); var e = Number(kl[c].split(".")[1]) * 1E3; if (e) { Sa("TAGGING", 16); var f = Jk(a, e); f.ab = Bl(); Bk(c, ["1", Gk(a.domain, a.path), d].join("."), f) } } }) } function Fl(a, b, c, d, e) { e = e || {}; var f = function () { var h = {}, l = tk(a, Ek(e.path), Fk(e.domain), jl, Bl()); l && (h[a] = l); return h }; Dl(function () { fl(f, b, c, d) }) }
  function Bl() { return ["ad_storage", "ad_user_data"] } function Gl(a) { for (var b = [], c = H.cookie.split(";"), d = new RegExp("^\\s*" + (a || "_gac") + "_(UA-\\d+-\\d+)=\\s*(.+?)\\s*$"), e = 0; e < c.length; e++) { var f = c[e].match(d); f && b.push({ rg: f[1], value: f[2], timestamp: Number(f[2].split(".")[1]) || 0 }) } b.sort(function (h, l) { return l.timestamp - h.timestamp }); return b }
  function Hl(a, b) { var c = Gl(a), d = {}; if (!c || !c.length) return d; for (var e = 0; e < c.length; e++) { var f = c[e].value.split("."); if (!(f[0] !== "1" || b && f.length < 3 || !b && f.length !== 3) && Number(f[1])) { d[c[e].rg] || (d[c[e].rg] = []); var h = { version: f[0], timestamp: Number(f[1]) * 1E3, X: f[2] }; b && f.length > 3 && (h.labels = f.slice(3)); d[c[e].rg].push(h) } } return d } var Il = {}, Jl = (Il.k = { Fa: /^[\w-]+$/ }, Il.b = { Fa: /^[\w-]+$/, mg: !0 }, Il.i = { Fa: /^[1-9]\d*$/ }, Il); var Kl = {}, Nl = (Kl[5] = { Ri: { 2: Ll }, Hf: ["k", "i", "b"] }, Kl[4] = { Ri: { 2: Ll, GCL: Ml }, Hf: ["k", "i", "b"] }, Kl); function Ol(a) { var b = Nl[5]; if (b) { var c = a.split(".")[0]; if (c) { var d = b.Ri[c]; if (d) return d(a, 5) } } } function Ll(a, b) { var c = a.split("."); if (c.length === 3) { var d = {}, e = Nl[b]; if (e) { for (var f = e.Hf, h = ma(c[2].split("$")), l = h.next(); !l.done; l = h.next()) { var m = l.value, n = m[0]; if (f.indexOf(n) !== -1) try { var p = decodeURIComponent(m.substring(1)), q = Jl[n]; q && (q.mg ? (d[n] = d[n] || [], d[n].push(p)) : d[n] = p) } catch (t) { } } return d } } }
  function Pl(a, b) { var c = Nl[5]; if (c) { for (var d = [], e = ma(c.Hf), f = e.next(); !f.done; f = e.next()) { var h = f.value, l = Jl[h]; if (l) { var m = a[h]; if (m !== void 0) if (l.mg && Array.isArray(m)) for (var n = ma(m), p = n.next(); !p.done; p = n.next())d.push(encodeURIComponent("" + h + p.value)); else d.push(encodeURIComponent("" + h + m)) } } return ["2", b || "1", d.join("$")].join(".") } } function Ml(a) { var b = a.split("."); b.shift(); var c = b.shift(), d = b.shift(), e = {}; return e.k = d, e.i = c, e.b = b, e } var Ql = new Map([[5, "ad_storage"], [4, ["ad_storage", "ad_user_data"]]]); function Rl(a) { if (Nl[5]) { for (var b = [], c = qk(a, void 0, void 0, Ql.get(5)), d = ma(c), e = d.next(); !e.done; e = d.next()) { var f = Ol(e.value); f && (Sl(f), b.push(f)) } return b } } function Tl(a, b, c, d) { c = c || {}; var e = Pl(b, Gk(c.domain, c.path)); if (e) { var f = Jk(c, d, void 0, Ql.get(5)); Bk(a, e, f) } } function Ul(a, b) { var c = b.Fa; return typeof c === "function" ? c(a) : c.test(a) }
  function Sl(a) { for (var b = ma(Object.keys(a)), c = b.next(), d = {}; !c.done; d = { yd: void 0 }, c = b.next()) { var e = c.value, f = a[e]; d.yd = Jl[e]; d.yd ? d.yd.mg ? a[e] = Array.isArray(f) ? f.filter(function (h) { return function (l) { return Ul(l, h.yd) } }(d)) : void 0 : typeof f === "string" && Ul(f, d.yd) || (a[e] = void 0) : a[e] = void 0 } } var Vl = /^\w+$/, Wl = /^[\w-]+$/, Xl = {}, Yl = (Xl.aw = "_aw", Xl.dc = "_dc", Xl.gf = "_gf", Xl.gp = "_gp", Xl.gs = "_gs", Xl.ha = "_ha", Xl.ag = "_ag", Xl.gb = "_gb", Xl); function Zl() { return ["ad_storage", "ad_user_data"] } function $l(a) { return !Ph().D() || Th(a) } function am(a, b) { function c() { var d = $l(b); d && a(); return d } fi(function () { c() || gi(c, b) }, b) } function bm(a) { return cm(a).map(function (b) { return b.X }) } function dm(a) { return em(a).filter(function (b) { return b.X }).map(function (b) { return b.X }) }
  function em(a) { var b = fm(a.prefix), c = gm("gb", b), d = gm("ag", b); if (!d || !c) return []; var e = function (l) { return function (m) { m.type = l; return m } }, f = cm(c).map(e("gb")), h = (df(6) ? hm(d) : []).map(e("ag")); return f.concat(h).sort(function (l, m) { return m.timestamp - l.timestamp }) } function im(a, b, c, d, e) { var f = Za(a, function (h) { return h.X === c }); f ? (f.timestamp = Math.max(f.timestamp, d), f.labels = jm(f.labels || [], e || [])) : a.push({ version: b, X: c, timestamp: d, labels: e }) }
  function hm(a) { for (var b = Rl(a) || [], c = [], d = ma(b), e = d.next(); !e.done; e = d.next()) { var f = e.value, h = f, l = km(f); l && im(c, "2", h.k, l, h.b || []) } return c.sort(function (m, n) { return n.timestamp - m.timestamp }) } function cm(a) { for (var b = [], c = qk(a, H.cookie, void 0, Zl()), d = ma(c), e = d.next(); !e.done; e = d.next()) { var f = lm(e.value); if (f != null) { var h = f; im(b, h.version, h.X, h.timestamp, h.labels) } } b.sort(function (l, m) { return m.timestamp - l.timestamp }); return mm(b) }
  function jm(a, b) { if (!a.length) return b; if (!b.length) return a; var c = {}; return a.concat(b).filter(function (d) { return c.hasOwnProperty(d) ? !1 : c[d] = !0 }) } function fm(a) { return a && typeof a === "string" && a.match(Vl) ? a : "_gcl" }
  function nm(a, b) { var c = df(6), d = df(7), e = tf(a), f = pf(e, "query", !1, void 0, "gclid"), h = pf(e, "query", !1, void 0, "gclsrc"), l = pf(e, "query", !1, void 0, "wbraid"); df(8) && (l = rb(l)); var m; c && (m = pf(e, "query", !1, void 0, "gbraid")); var n; d && (n = pf(e, "query", !1, void 0, "gad_source")); var p = pf(e, "query", !1, void 0, "dclid"); if (b && (!f || !h || !l || c && !m)) { var q = e.hash.replace("#", ""); f = f || mf(q, "gclid"); h = h || mf(q, "gclsrc"); l = l || mf(q, "wbraid"); c && (m = m || mf(q, "gbraid")); d && (n = n || mf(q, "gad_source")) } return om(f, h, p, l, m, n) }
  function pm() { return nm(F.location.href, !0) }
  function om(a, b, c, d, e, f) { var h = {}, l = function (m, n) { h[n] || (h[n] = []); h[n].push(m) }; h.gclid = a; h.gclsrc = b; h.dclid = c; if (a !== void 0 && a.match(Wl)) switch (b) { case void 0: l(a, "aw"); break; case "aw.ds": l(a, "aw"); l(a, "dc"); break; case "ds": l(a, "dc"); break; case "3p.ds": l(a, "dc"); break; case "gf": l(a, "gf"); break; case "ha": l(a, "ha") }c && l(c, "dc"); d !== void 0 && Wl.test(d) && (h.wbraid = d, l(d, "gb")); df(6) && e !== void 0 && Wl.test(e) && (h.gbraid = e, l(e, "ag")); df(7) && f !== void 0 && Wl.test(f) && (h.gad_source = f, l(f, "gs")); return h }
  function qm(a) { var b = pm(); if (df(5)) { for (var c = !0, d = ma(Object.keys(b)), e = d.next(); !e.done; e = d.next())if (b[e.value] !== void 0) { c = !1; break } c && (b = nm(F.document.referrer, !1)) } rm(b, !1, a) }
  function rm(a, b, c, d, e) {
    c = c || {}; e = e || []; var f = fm(c.prefix), h = d || ib(), l = Math.round(h / 1E3), m = Zl(), n = !1, p = !1, q = function () {
      if ($l(m)) {
        var t = Jk(c, h, !0); t.ab = m; for (var r = function (E, K) { var I = gm(E, f); I && (Bk(I, K, t), E !== "gb" && (n = !0)) }, u = function (E) { var K = ["GCL", l, E]; e.length > 0 && K.push(e.join(".")); return K.join(".") }, v = ma(["aw", "dc", "gf", "ha", "gp"]), w = v.next(); !w.done; w = v.next()) { var y = w.value; a[y] && r(y, u(a[y][0])) } if (!n && a.gb) {
          var x = a.gb[0], B = gm("gb", f); !b && cm(B).some(function (E) {
            return E.X === x && E.labels && E.labels.length >
              0
          }) || r("gb", u(x))
        }
      } if (!p && df(6) && a.gbraid && $l("ad_storage") && (p = !0, !n)) { var z = a.gbraid, C = gm("ag", f); if (b || !(df(6) ? hm(C) : []).some(function (E) { return E.X === z && E.labels && E.labels.length > 0 })) { var G = {}, D = (G.k = z, G.i = "" + l, G.b = e, G); Tl(C, D, c, h) } } sm(a, f, h, c)
    }; fi(function () { q(); $l(m) || gi(q, m) }, m)
  } function sm(a, b, c, d) { if (df(7) && a.gad_source !== void 0 && $l("ad_storage")) { var e = gm("gs", b); if (e) { var f = Math.round((ib() - ($b() || 0)) / 1E3), h = {}, l = (h.k = a.gad_source, h.i = "" + f, h); Tl(e, l, d, c) } } }
  function tm(a, b) { var c = Zk(!0); am(function () { for (var d = fm(b.prefix), e = 0; e < a.length; ++e) { var f = a[e]; if (Yl[f] !== void 0) { var h = gm(f, d), l = c[h]; if (l) { var m = Math.min(um(l), ib()), n; b: { for (var p = m, q = qk(h, H.cookie, void 0, Zl()), t = 0; t < q.length; ++t)if (um(q[t]) > p) { n = !0; break b } n = !1 } if (!n) { var r = Jk(b, m, !0); r.ab = Zl(); Bk(h, l, r) } } } } rm(om(c.gclid, c.gclsrc), !1, b) }, Zl()) }
  function vm(a) { var b = []; df(6) && b.push("ag"); if (b.length !== 0) { var c = Zk(!0), d = fm(a.prefix); am(function () { for (var e = 0; e < b.length; ++e) { var f = gm(b[e], d); if (f) { var h = c[f]; if (h) { var l = Ol(h); if (l) { var m = km(l); m || (m = ib()); var n; a: { for (var p = m, q = Rl(f), t = 0; t < q.length; ++t)if (km(q[t]) > p) { n = !0; break a } n = !1 } if (n) break; l.i = "" + Math.round(m / 1E3); Tl(f, l, a, m) } } } } }, ["ad_storage"]) } } function gm(a, b) { var c = Yl[a]; if (c !== void 0) return b + c }
  function um(a) { return wm(a.split(".")).length !== 0 ? (Number(a.split(".")[1]) || 0) * 1E3 : 0 } function km(a) { return a ? (Number(a.i) || 0) * 1E3 : 0 } function lm(a) { var b = wm(a.split(".")); return b.length === 0 ? null : { version: b[0], X: b[2], timestamp: (Number(b[1]) || 0) * 1E3, labels: b.slice(3) } } function wm(a) { return a.length < 3 || a[0] !== "GCL" && a[0] !== "1" || !/^\d+$/.test(a[1]) || !Wl.test(a[2]) ? [] : a }
  function xm(a, b, c, d, e) { if (Array.isArray(b) && pk(F)) { var f = fm(e), h = function () { for (var l = {}, m = 0; m < a.length; ++m) { var n = gm(a[m], f); if (n) { var p = qk(n, H.cookie, void 0, Zl()); p.length && (l[n] = p.sort()[p.length - 1]) } } return l }; am(function () { fl(h, b, c, d) }, Zl()) } }
  function ym(a, b, c, d) { if (Array.isArray(a) && pk(F)) { var e = []; df(6) && e.push("ag"); if (e.length !== 0) { var f = fm(d), h = function () { for (var l = {}, m = 0; m < e.length; ++m) { var n = gm(e[m], f); if (!n) return {}; var p = Rl(n); if (p.length) { var q = p.sort(function (t, r) { return km(r) - km(t) })[0]; l[n] = Pl(q) } } return l }; am(function () { fl(h, a, b, c) }, ["ad_storage"]) } } } function mm(a) { return a.filter(function (b) { return Wl.test(b.X) }) }
  function zm(a, b) { if (pk(F)) { for (var c = fm(b.prefix), d = {}, e = 0; e < a.length; e++)Yl[a[e]] && (d[a[e]] = Yl[a[e]]); am(function () { A(d, function (f, h) { var l = qk(c + h, H.cookie, void 0, Zl()); l.sort(function (r, u) { return um(u) - um(r) }); if (l.length) { var m = l[0], n = um(m), p = wm(m.split(".")).length !== 0 ? m.split(".").slice(3) : [], q = {}, t; t = wm(m.split(".")).length !== 0 ? m.split(".")[2] : void 0; q[f] = [t]; rm(q, !0, b, n, p) } }) }, Zl()) } }
  function Am(a) { var b = [], c = []; df(6) && (b.push("ag"), c.push("gbraid")); b.length !== 0 && am(function () { for (var d = fm(a.prefix), e = 0; e < b.length; ++e) { var f = gm(b[e], d); if (!f) break; var h = Rl(f); if (h.length) { var l = h.sort(function (q, t) { return km(t) - km(q) })[0], m = km(l), n = l.b, p = {}; p[c[e]] = l.k; rm(p, !0, a, m, n) } } }, ["ad_storage"]) } function Bm(a, b) { for (var c = 0; c < b.length; ++c)if (a[b[c]]) return !0; return !1 }
  function Cm(a) { function b(e, f, h) { h && (e[f] = h) } if (ci()) { var c = pm(); if (Bm(c, a)) { var d = {}; b(d, "gclid", c.gclid); b(d, "dclid", c.dclid); b(d, "gclsrc", c.gclsrc); b(d, "wbraid", c.wbraid); df(6) && b(d, "gbraid", c.gbraid); gl(function () { return d }, 3); gl(function () { var e = {}; return e._up = "1", e }, 1) } } }
  function Dm(a) { if (!df(1)) return null; var b = Zk(!0).gad_source; if (b != null) return F.location.hash = "", b; if (df(2)) { var c = tf(F.location.href); b = pf(c, "query", !1, void 0, "gad_source"); if (b != null) return b; var d = pm(); if (Bm(d, a)) return "0" } return null } function Em(a) { var b = Dm(a); b != null && gl(function () { var c = {}; return c.gad_source = b, c }, 4) }
  function Fm(a, b, c) { var d = []; if (b.length === 0) return d; for (var e = {}, f = 0; f < b.length; f++) { var h = b[f], l = h.type ? h.type : "gcl"; (h.labels || []).indexOf(c) === -1 ? (a.push(0), e[l] || d.push(h)) : a.push(1); e[l] = !0 } return d } function Gm(a, b, c, d) { var e = []; c = c || {}; if (!$l(Zl())) return e; var f = cm(a), h = Fm(e, f, b); if (h.length && !d) for (var l = ma(h), m = l.next(); !m.done; m = l.next()) { var n = m.value, p = n.timestamp, q = [n.version, Math.round(p / 1E3), n.X].concat(n.labels || [], [b]).join("."), t = Jk(c, p, !0); t.ab = Zl(); Bk(a, q, t) } return e }
  function Hm(a, b) { var c = []; b = b || {}; var d = em(b), e = Fm(c, d, a); if (e.length) for (var f = ma(e), h = f.next(); !h.done; h = f.next()) { var l = h.value, m = fm(b.prefix), n = gm(l.type, m); if (!n) break; var p = l, q = p.version, t = p.X, r = p.labels, u = p.timestamp, v = Math.round(u / 1E3); if (l.type === "ag") { var w = {}, y = (w.k = t, w.i = "" + v, w.b = (r || []).concat([a]), w); Tl(n, y, b, u) } else if (l.type === "gb") { var x = [q, v, t].concat(r || [], [a]).join("."), B = Jk(b, u, !0); B.ab = Zl(); Bk(n, x, B) } } return c }
  function Im(a, b) { var c = fm(b), d = gm(a, c); if (!d) return 0; var e; e = a === "ag" ? df(6) ? hm(d) : [] : cm(d); for (var f = 0, h = 0; h < e.length; h++)f = Math.max(f, e[h].timestamp); return f } function Jm(a) { for (var b = 0, c = ma(Object.keys(a)), d = c.next(); !d.done; d = c.next())for (var e = a[d.value], f = 0; f < e.length; f++)b = Math.max(b, Number(e[f].timestamp)); return b } function Km(a, b) { var c = Math.max(Im("aw", a), Jm($l(Zl()) ? Hl() : {})), d = Math.max(Im("gb", a), Jm($l(Zl()) ? Hl("_gac_gb", !0) : {})); df(6) && b && (d = Math.max(d, Im("ag", a))); return d > c } var Ym, Zm = !1; function $m(a) { if (!Zm) { Zm = !0; Ym = Ym || {} } return Ym[a] } var an = function (a, b, c) { this.eventName = b; this.m = c; this.o = {}; this.isAborted = !1; this.target = a; this.metadata = g(c.eventMetadata || {}, {}) }; an.prototype.copyToHitData = function (a, b, c) { var d = S(this.m, a); d === void 0 && (d = b); if (d !== void 0 && c !== void 0 && k(d) && R(53)) try { d = c(d) } catch (e) { } d !== void 0 && (this.o[a] = d) }; var bn = function (a, b, c) { var d = $m(a.target.da); return d && d[b] !== void 0 ? d[b] : c }; function cn() { se.dedupe_gclid || (se.dedupe_gclid = Ik()); return se.dedupe_gclid } var dn = /^(www\.)?google(\.com?)?(\.[a-z]{2}t?)?$/, en = /^www.googleadservices.com$/; function fn(a) { a || (a = gn()); return a.Yl ? !1 : a.Pk || a.Qk || a.Sk || a.Rk || a.Uf || a.Pf || a.Ek || a.Hk ? !0 : !1 }
  function gn() { var a = {}, b = Zk(!0); a.Yl = !!b._up; var c = pm(); a.Pk = c.aw !== void 0; a.Qk = c.dc !== void 0; a.Sk = c.wbraid !== void 0; a.Rk = c.gbraid !== void 0; var d = tf(F.location.href), e = pf(d, "query", !1, void 0, "gad"); a.Uf = e !== void 0; if (!a.Uf) { var f = d.hash.replace("#", ""), h = mf(f, "gad"); a.Uf = h !== void 0 } a.Pf = pf(d, "query", !1, void 0, "gad_source"); if (a.Pf === void 0) { var l = d.hash.replace("#", ""), m = mf(l, "gad_source"); a.Pf = m } var n = H.referrer ? pf(tf(H.referrer), "host") : ""; a.Hk = dn.test(n); a.Ek = en.test(n); return a } var hn = RegExp("^UA-\\d+-\\d+%3A[\\w-]+(?:%2C[\\w-]+)*(?:%3BUA-\\d+-\\d+%3A[\\w-]+(?:%2C[\\w-]+)*)*$"), jn = /^~?[\w-]+(?:\.~?[\w-]+)*$/, kn = /^\d+\.fls\.doubleclick\.net$/, ln = /;gac=([^;?]+)/, mn = /;gacgb=([^;?]+)/;
  function nn(a, b) { if (kn.test(H.location.host)) { var c = H.location.href.match(b); return c && c.length === 2 && c[1].match(hn) ? decodeURIComponent(c[1]) : "" } for (var d = [], e = ma(Object.keys(a)), f = e.next(); !f.done; f = e.next()) { for (var h = f.value, l = [], m = a[h], n = 0; n < m.length; n++)l.push(m[n].X); d.push(h + ":" + l.join(",")) } return d.length > 0 ? d.join(";") : "" }
  function on(a, b, c) { for (var d = $l(Zl()) ? Hl("_gac_gb", !0) : {}, e = [], f = !1, h = ma(Object.keys(d)), l = h.next(); !l.done; l = h.next()) { var m = l.value, n = Gm("_gac_gb_" + m, a, b, c); f = f || n.length !== 0 && n.some(function (p) { return p === 1 }); e.push(m + ":" + n.join(",")) } return { Dk: f ? e.join(";") : "", Ck: nn(d, mn) } } function pn(a) { var b = H.location.href.match(new RegExp(";" + a + "=([^;?]+)")); return b && b.length === 2 && b[1].match(jn) ? b[1] : void 0 }
  function qn(a) { var b = { Qf: void 0, Rf: void 0 }, c, d; kn.test(H.location.host) && (c = pn("gclgs"), d = pn("gclst")); if (c && d) b.Qf = c, b.Rf = d; else { var e = ib(), f = hm((a || "_gcl") + "_gs"), h = f.map(function (m) { return m.X }), l = f.map(function (m) { return e - m.timestamp }); h.length > 0 && l.length > 0 && (b.Qf = h.join("."), b.Rf = l.join(".")) } return b }
  function rn(a, b, c) { if (kn.test(H.location.host)) { var d = pn(c); if (d) return [{ X: d }] } else { if (b === "gclid") return cm((a || "_gcl") + "_aw"); if (b === "wbraid") return cm((a || "_gcl") + "_gb"); if (b === "braids") return em({ prefix: a }) } return [] } function sn(a) { return rn(a, "gclid", "gclaw").map(function (b) { return b.X }).join(".") } function tn(a) { return rn(a, "wbraid", "gclgb").map(function (b) { return b.X }).join(".") } function un(a) { return rn(a, "braids", "gclgb").map(function (b) { return b.X }).join(".") }
  function vn(a, b) { return kn.test(H.location.host) ? !(pn("gclaw") || pn("gac")) : Km(a, b) } function wn(a, b, c) { var d; d = c ? Hm(a, b) : Gm((b && b.prefix || "_gcl") + "_gb", a, b); return d.length === 0 || d.every(function (e) { return e === 0 }) ? "" : d.join(".") } var xn = function () { if (Wa(F.__uspapi)) { var a = ""; try { F.__uspapi("getUSPData", 1, function (b, c) { if (c && b) { var d = b.uspString; d && RegExp("^[\\da-zA-Z-]{1,20}$").test(d) && (a = d) } }) } catch (b) { } return a } }; function Fn(a) { var b = S(a.m, M.g.Ab), c = S(a.m, M.g.zb); b && !c ? (a.eventName !== M.g.Z && a.eventName !== M.g.Oc && L(131), a.isAborted = !0) : !b && c && (L(132), a.isAborted = !0) } function Gn(a) { var b = T(M.g.O) ? se.pscdl : "denied"; b != null && (a.o[M.g.Xd] = b) } function Hn(a) { var b = ph(!0); a.o[M.g.yb] = b } function On(a, b, c, d) { var e = Mb(), f; if (e === 1) a: { var h = De; h = h.toLowerCase(); for (var l = "https://" + h, m = "http://" + h, n = 1, p = H.getElementsByTagName("script"), q = 0; q < p.length && q < 100; q++) { var t = p[q].src; if (t) { t = t.toLowerCase(); if (t.indexOf(m) === 0) { f = 3; break a } n === 1 && t.indexOf(l) === 0 && (n = 2) } } f = n } else f = e; return (f === 2 || d || "http:" != F.location.protocol ? a : b) + c } function ao(a) {
    return {
      getDestinationId: function () { return a.target.da }, getEventName: function () { return a.eventName }, setEventName: function (b) { a.eventName = b }, getHitData: function (b) { return a.o[b] }, setHitData: function (b, c) { a.o[b] = c }, setHitDataIfNotDefined: function (b, c) { a.o[b] === void 0 && (a.o[b] = c) }, copyToHitData: function (b, c) { a.copyToHitData(b, c) }, getMetadata: function (b) { return a.metadata[b] }, setMetadata: function (b, c) { a.metadata[b] = c }, isAborted: function () { return a.isAborted }, abort: function () {
        a.isAborted =
        !0
      }, getFromEventContext: function (b) { return S(a.m, b) }, Um: function () { return a }, getHitKeys: function () { return Object.keys(a.o) }
    }
  } function ho() { var a = F.screen; return { width: a ? a.width : 0, height: a ? a.height : 0 } }
  function io(a) {
    if (H.hidden) return !0; var b = a.getBoundingClientRect(); if (b.top === b.bottom || b.left === b.right || !F.getComputedStyle) return !0; var c = F.getComputedStyle(a, null); if (c.visibility === "hidden") return !0; for (var d = a, e = c; d;) {
      if (e.display === "none") return !0; var f = e.opacity, h = e.filter; if (h) { var l = h.indexOf("opacity("); l >= 0 && (h = h.substring(l + 8, h.indexOf(")", l)), h.charAt(h.length - 1) === "%" && (h = h.substring(0, h.length - 1)), f = String(Math.min(Number(h), Number(f)))) } if (f !== void 0 && Number(f) <= 0) return !0; (d = d.parentElement) &&
        (e = F.getComputedStyle(d, null))
    } return !1
  } var ip = Number('') || 5, jp = Number('') || 50, kp = $a(); var pp = { Yj: Number('') || 500, Mj: Number('') || 5E3, Oh: Number('20') || 10, Vi: Number('') || 5E3 }; function qp(a) { return a.performance && a.performance.now() || Date.now() }
  var rp = function (a, b) { var c; return c }; var sp = "https://" + re.Nc + "/gtm/static/", tp;
  function yp(a, b) { }
  function zp(a, b, c, d) { }
  function Ap(a, b, c, d) { }
  function Bp(a, b, c, d) { } var Cp = void 0; function Dp(a) { var b = []; return b } !ch("Android") || eh(); eh(); ch("Safari") && (eh() || (dh() ? 0 : ch("Coast")) || (dh() ? 0 : ch("Opera")) || (dh() ? 0 : ch("Edge")) || (dh() ? bh("Microsoft Edge") : ch("Edg/")) || dh() && bh("Opera")); var Ep = {}, Fp = null, Gp = function (a) {
    for (var b = [], c = 0, d = 0; d < a.length; d++) { var e = a.charCodeAt(d); e > 255 && (b[c++] = e & 255, e >>= 8); b[c++] = e } var f = 4; f === void 0 && (f = 0); if (!Fp) { Fp = {}; for (var h = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".split(""), l = ["+/=", "+/", "-_=", "-_.", "-_"], m = 0; m < 5; m++) { var n = h.concat(l[m].split("")); Ep[m] = n; for (var p = 0; p < n.length; p++) { var q = n[p]; Fp[q] === void 0 && (Fp[q] = p) } } } for (var t = Ep[f], r = Array(Math.floor(b.length / 3)), u = t[64] || "", v = 0, w = 0; v < b.length - 2; v += 3) {
      var y = b[v],
      x = b[v + 1], B = b[v + 2], z = t[y >> 2], C = t[(y & 3) << 4 | x >> 4], G = t[(x & 15) << 2 | B >> 6], D = t[B & 63]; r[w++] = "" + z + C + G + D
    } var E = 0, K = u; switch (b.length - v) { case 2: E = b[v + 1], K = t[(E & 15) << 2] || u; case 1: var I = b[v]; r[w] = "" + t[I >> 2] + t[(I & 3) << 4 | E >> 4] + K + u }return r.join("")
  }; var Hp = function () { }; Object.freeze(new function () { }); Object.freeze(new Hp); Object.freeze(new Hp); Object.freeze({}); var Ip = "platform platformVersion architecture model uaFullVersion bitness fullVersionList wow64".split(" "); function Jp() { var a; return (a = F.google_tag_data) != null ? a : F.google_tag_data = {} } function Kp() { var a = F.google_tag_data, b; if (a != null && a.uach) { var c = a.uach, d = Object.assign({}, c); c.fullVersionList && (d.fullVersionList = c.fullVersionList.slice(0)); b = d } else b = null; return b } function Lp() { var a, b; return (b = (a = F.google_tag_data) == null ? void 0 : a.uach_promise) != null ? b : null }
  function Mp() { var a, b; return typeof ((a = F.navigator) == null ? void 0 : (b = a.userAgentData) == null ? void 0 : b.getHighEntropyValues) === "function" } function Np() { if (!Mp()) return null; var a = Jp(); if (a.uach_promise) return a.uach_promise; var b = F.navigator.userAgentData.getHighEntropyValues(Ip).then(function (c) { a.uach != null || (a.uach = c); return c }); return a.uach_promise = b } function Tp(a) { var b; b = b === void 0 ? document : b; var c; return !((c = b.featurePolicy) == null || !c.allowedFeatures().includes(a)) } var Up = !1; function Vp() { if (Tp("join-ad-interest-group") && Wa(Bb.joinAdInterestGroup)) return !0; Up || (nh(''), Up = !0); return Tp("join-ad-interest-group") && Wa(Bb.joinAdInterestGroup) }
  function Wp(a, b) {
    var c = cf[3] === void 0 ? 1 : cf[3], d = 'iframe[data-tagging-id="' + b + '"]', e = []; try { if (c === 1) { var f = H.querySelector(d); f && (e = [f]) } else e = Array.from(H.querySelectorAll(d)) } catch (q) { } var h; a: { try { h = H.querySelectorAll('iframe[allow="join-ad-interest-group"][data-tagging-id*="-"]'); break a } catch (q) { } h = void 0 } var l = h, m = ((l == null ? void 0 : l.length) || 0) >= (cf[2] === void 0 ? 50 : cf[2]), n; if (n = e.length >= 1) {
      var p = Number(e[e.length - 1].dataset.loadTime); p !== void 0 && ib() - p < (cf[1] === void 0 ? 6E4 : cf[1]) ? (Sa("TAGGING",
        9), n = !0) : n = !1
    } if (!n) { if (c === 1) if (e.length >= 1) Xp(e[0]); else { if (m) { Sa("TAGGING", 10); return } } else e.length >= c ? Xp(e[0]) : m && Xp(l[0]); Nb(a, void 0, { allow: "join-ad-interest-group" }, { taggingId: b, loadTime: ib() }) }
  } function Xp(a) { try { a.parentNode.removeChild(a) } catch (b) { } } function Yp() { return "https://td.doubleclick.net" } var Tq = {
    J: {
      ug: "ads_conversion_hit", Mc: "container_execute_start", yg: "container_setup_end", Ve: "container_setup_start", wg: "container_blocking_end", xg: "container_execute_end", zg: "container_yield_end", We: "container_yield_start", Fh: "event_execute_end", Eh: "event_evaluation_end", xf: "event_evaluation_start", Gh: "event_setup_end", od: "event_setup_start", Ih: "ga4_conversion_hit", qd: "page_load", Cm: "pageview", Hb: "snippet_load", di: "tag_callback_error", ei: "tag_callback_failure", fi: "tag_callback_success", gi: "tag_execute_end",
      Ec: "tag_execute_start"
    }
  }; function Uq() { function a(c, d) { var e = Ta(d); e && b.push([c, e]) } var b = []; a("u", "GTM"); a("ut", "TAGGING"); a("h", "HEALTH"); return b } var Vq = !1;
  var es = function (a, b) { }, fs = function (a, b) { }, gs = function (a, b) { }, hs = function (a, b) { }, is = function () { var a = {}; return a }, Wr = function (a) { a = a === void 0 ? !0 : a; var b = {}; return b }, js = function () { }, ks = function (a, b) { }, ls = function (a, b, c) { }; function ms(a, b) { var c, d = F.GooglebQhCsO; d || (d = {}, F.GooglebQhCsO = d); c = d; if (c[a]) return !1; c[a] = []; c[a][0] = b; return !0 } var ns = function (a, b, c) { var d = ih(a, "fmt"); if (b) { var e = ih(a, "random"), f = ih(a, "label") || ""; if (!e) return !1; var h = Gp(decodeURIComponent(f.replace(/\+/g, " ")) + ":" + decodeURIComponent(e.replace(/\+/g, " "))); if (!ms(h, b)) return !1 } d && d != 4 && (a = kh(a, "rfmt", d)); var l = kh(a, "fmt", 4); Lb(l, function () { F.google_noFurtherRedirects && b && b.call && (F.google_noFurtherRedirects = null, b()) }, void 0, c, H.getElementsByTagName("script")[0].parentElement || void 0); return !0 }; function Es(a, b) { if (data.entities) { var c = data.entities[a]; if (c) return c[b] } } function Fs(a) { var b = Vf(), c; c = c === void 0 ? !1 : c; Gs().addRestriction(0, b, a, c) } function Hs(a) { var b = Vf(), c; c = c === void 0 ? !1 : c; Gs().addRestriction(1, b, a, c) } function Is() { var a = Vf(); return Gs().getRestrictions(1, a) } var Js = function () { this.D = {}; this.F = {} }, Ks = function (a, b) { var c = a.D[b]; c || (c = { _entity: { internal: [], external: [] }, _event: { internal: [], external: [] } }, a.D[b] = c); return c };
  Js.prototype.addRestriction = function (a, b, c, d) { d = d === void 0 ? !1 : d; if (!d || !this.F[b]) { var e = Ks(this, b); a === 0 ? d ? e._entity.external.push(c) : e._entity.internal.push(c) : a === 1 && (d ? e._event.external.push(c) : e._event.internal.push(c)) } };
  Js.prototype.getRestrictions = function (a, b) { var c = Ks(this, b); if (a === 0) { var d, e; return [].concat(oa((c == null ? void 0 : (d = c._entity) == null ? void 0 : d.internal) || []), oa((c == null ? void 0 : (e = c._entity) == null ? void 0 : e.external) || [])) } if (a === 1) { var f, h; return [].concat(oa((c == null ? void 0 : (f = c._event) == null ? void 0 : f.internal) || []), oa((c == null ? void 0 : (h = c._event) == null ? void 0 : h.external) || [])) } return [] };
  Js.prototype.getExternalRestrictions = function (a, b) { var c = Ks(this, b), d, e; return a === 0 ? (c == null ? void 0 : (d = c._entity) == null ? void 0 : d.external) || [] : (c == null ? void 0 : (e = c._event) == null ? void 0 : e.external) || [] }; Js.prototype.removeExternalRestrictions = function (a) { var b = Ks(this, a); b._event && (b._event.external = []); b._entity && (b._entity.external = []); this.F[a] = !0 }; function Gs() { var a = se.r; a || (a = new Js, se.r = a); return a } var Ls = new RegExp(/^(.*\.)?(google|youtube|blogger|withgoogle)(\.com?)?(\.[a-z]{2})?\.?$/), Ms = { cl: ["ecl"], customPixels: ["nonGooglePixels"], ecl: ["cl"], ehl: ["hl"], gaawc: ["googtag"], hl: ["ehl"], html: ["customScripts", "customPixels", "nonGooglePixels", "nonGoogleScripts", "nonGoogleIframes"], customScripts: ["html", "customPixels", "nonGooglePixels", "nonGoogleScripts", "nonGoogleIframes"], nonGooglePixels: [], nonGoogleScripts: ["nonGooglePixels"], nonGoogleIframes: ["nonGooglePixels"] }, Ns = {
    cl: ["ecl"], customPixels: ["customScripts",
      "html"], ecl: ["cl"], ehl: ["hl"], gaawc: ["googtag"], hl: ["ehl"], html: ["customScripts"], customScripts: ["html"], nonGooglePixels: ["customPixels", "customScripts", "html", "nonGoogleScripts", "nonGoogleIframes"], nonGoogleScripts: ["customScripts", "html"], nonGoogleIframes: ["customScripts", "html", "nonGoogleScripts"]
  }, Os = "google customPixels customScripts html nonGooglePixels nonGoogleScripts nonGoogleIframes".split(" ");
  function Ps() {
    var a = Re("gtm.allowlist") || Re("gtm.whitelist"); a && L(9); xe && (a = ["google", "gtagfl", "lcl", "zone"]); Ls.test(F.location && F.location.hostname) && (xe ? L(116) : (L(117), Qs && (a = [], window.console && window.console.log && window.console.log("GTM blocked. See go/13687728.")))); var b = a && mb(fb(a), Ms), c = Re("gtm.blocklist") || Re("gtm.blacklist"); c || (c = Re("tagTypeBlacklist")) && L(3); c ? L(8) : c = []; Ls.test(F.location && F.location.hostname) && (c = fb(c), c.push("nonGooglePixels", "nonGoogleScripts", "sandboxedScripts"));
    fb(c).indexOf("google") >= 0 && L(2); var d = c && mb(fb(c), Ns), e = {}; return function (f) {
      var h = f && f[gc.na]; if (!h || typeof h !== "string") return !0; h = h.replace(/^_*/, ""); if (e[h] !== void 0) return e[h]; var l = He[h] || [], m = !0; if (a) { var n; if (n = m) a: { if (b.indexOf(h) < 0) if (l && l.length > 0) for (var p = 0; p < l.length; p++) { if (b.indexOf(l[p]) < 0) { L(11); n = !1; break a } } else { n = !1; break a } n = !0 } m = n } var q = !1; if (c) { var t = d.indexOf(h) >= 0; if (t) q = t; else { var r = ab(d, l || []); r && L(10); q = r } } var u = !m || q; u || !(l.indexOf("sandboxedScripts") >= 0) || b && b.indexOf("sandboxedScripts") !==
        -1 || (u = ab(d, Os)); return e[h] = u
    }
  } var Qs = !1; Qs = !0;
  function Rs() { Mf && Fs(function (a) { var b = {}; b[gc.na] = "__" + a.entityId; for (var c in void 0) (void 0).hasOwnProperty(c) && (b["vtp_" + c] = (void 0)[c]); var d; if (Sc(b)) { var e = b[gc.na]; if (!e) throw Error("Error: No function name given for function call."); var f = Ic[e]; d = !!f && !!f.runInSiloedMode } else d = !!Es(b[gc.na], 4); return d }) } var Ts = function (a, b, c, d) {
    if (!Ss()) {
      var e = d.siloed ? Qf(a) : a; if (!Jf().container[e]) {
        var f = "?id=" + encodeURIComponent(a) + "&l=" + re.Ga, h = a.indexOf("GTM-") === 0; h || (f += "&cx=c"); R(61) && (f += "&gtm=" + gk()); var l = yf(); l && (f += "&sign=" + re.Fe); var m = c ? "/gtag/js" : "/gtm.js", n = Ke.F || ze ? xf(b, m + f) : void 0; if (!n) { var p = re.Nc + m; l && Eb && h ? (p = Eb.replace(/^(?:https?:\/\/)?/i, "").split(/[?#]/)[0], n = On("https://", "http://", p + f)) : n = Ke.F ? Le() + m + f : On("https://", "http://", p + f) } d.siloed && eg({ ctid: e, isDestination: !1 }); var q = Yf(); Jf().container[e] =
          { state: 1, context: d, parent: q }; If({ ctid: e, isDestination: !1 }); Lb(n)
      }
    }
  }, Us = function (a, b, c) {
    if (!Ss()) {
      var d = c.siloed ? Qf(a) : a, e = Jf().destination[d]; if (!e || !e.state) if (!c.siloed && fg()) Jf().destination[d] = { state: 0, transportUrl: b, context: c, parent: Yf() }, If({ ctid: d, isDestination: !0 }), L(91); else {
        var f = "/gtag/destination?id=" + encodeURIComponent(a) + "&l=" + re.Ga + "&cx=c"; R(61) && (f += "&gtm=" + gk()); yf() && (f += "&sign=" + re.Fe); var h = Ke.F || ze ? xf(b, f) : void 0; h || (h = Ke.F ? Le() + f : On("https://", "http://", re.Nc + f)); c.siloed && eg({
          ctid: d,
          isDestination: !0
        }); Jf().destination[d] = { state: 1, context: c, parent: Yf() }; If({ ctid: d, isDestination: !0 }); Lb(h)
      }
    }
  }; function Ss() { if (ek()) { return !0 } return !1 } var Vs = !1, Ws = 0, Xs = []; function Ys(a) { if (!Vs) { var b = H.createEventObject, c = H.readyState === "complete", d = H.readyState === "interactive"; if (!a || a.type !== "readystatechange" || c || !b && d) { Vs = !0; for (var e = 0; e < Xs.length; e++)J(Xs[e]) } Xs.push = function () { for (var f = za.apply(0, arguments), h = 0; h < f.length; h++)J(f[h]); return 0 } } } function Zs() { if (!Vs && Ws < 140) { Ws++; try { var a, b; (b = (a = H.documentElement).doScroll) == null || b.call(a, "left"); Ys() } catch (c) { F.setTimeout(Zs, 50) } } } function $s(a) { Vs ? a() : Xs.push(a) } function at(a, b) { return { entityType: 1, indexInOriginContainer: a, nameInOriginContainer: b, originContainerId: Tf() } } var ct = function (a, b) { this.D = !1; this.K = []; this.eventData = { tags: [] }; this.P = !1; this.F = this.H = 0; bt(this, a, b) }, dt = function (a, b, c, d) { if (ue.hasOwnProperty(b) || b === "__zone") return -1; var e = {}; Ka(d) && (e = g(d, e)); e.id = c; e.status = "timeout"; return a.eventData.tags.push(e) - 1 }, et = function (a, b, c, d) { var e = a.eventData.tags[b]; e && (e.status = c, e.executionTime = d) }, ft = function (a) { if (!a.D) { for (var b = a.K, c = 0; c < b.length; c++)b[c](); a.D = !0; a.K.length = 0 } }, bt = function (a, b, c) {
    b !== void 0 && gt(a, b); c && F.setTimeout(function () { ft(a) },
      Number(c))
  }, gt = function (a, b) { var c = kb(function () { J(function () { b(Tf(), a.eventData) }) }); a.D ? c() : a.K.push(c) }, ht = function (a) { a.H++; return kb(function () { a.F++; a.P && a.F >= a.H && ft(a) }) }, it = function (a) { a.P = !0; a.F >= a.H && ft(a) }; var jt = {}, lt = function () { return F[kt()] };
  function kt() { return F.GoogleAnalyticsObject || "ga" }
  var ot = function () { var a = Tf(); }, pt = function (a, b) {
    return function () {
      var c = lt(), d = c && c.getByName && c.getByName(a); if (d) {
        var e = d.get("sendHitTask"); d.set("sendHitTask", function (f) {
          var h = f.get("hitPayload"), l = f.get("hitCallback"), m = h.indexOf("&tid=" + b) < 0; m && (f.set("hitPayload", h.replace(/&tid=UA-[0-9]+-[0-9]+/, "&tid=" + b), !0), f.set("hitCallback", void 0, !0)); e(f);
          m && (f.set("hitPayload", h, !0), f.set("hitCallback", l, !0), f.set("_x_19", void 0, !0), e(f))
        })
      }
    }
  }; var ut = ["es", "1"], vt = {}, wt = {}; function xt(a, b) { if (Gf) { var c; c = b.match(/^(gtm|gtag)\./) ? encodeURIComponent(b) : "*"; vt[a] = [["e", c], ["eid", a]]; Bg(a) } } function zt(a) { var b = a.eventId, c = a.Oa; if (!vt[b]) return []; var d = []; wt[b] || d.push(ut); d.push.apply(d, oa(vt[b])); c && (wt[b] = !0); return d } var At = {}, Bt = {}, Ct = {}; function Dt(a, b, c, d) { Gf && R(70) && ((d === void 0 ? 0 : d) ? (Ct[b] = Ct[b] || 0, ++Ct[b]) : c !== void 0 ? (Bt[a] = Bt[a] || {}, Bt[a][b] = Math.round(c)) : (At[a] = At[a] || {}, At[a][b] = (At[a][b] || 0) + 1)) } function Et(a) { var b = a.eventId, c = a.Oa, d = At[b] || {}, e = [], f; for (f in d) d.hasOwnProperty(f) && e.push("" + f + d[f]); c && delete At[b]; return e.length ? [["md", e.join(".")]] : [] }
  function Ft(a) { var b = a.eventId, c = a.Oa, d = Bt[b] || {}, e = [], f; for (f in d) d.hasOwnProperty(f) && e.push("" + f + d[f]); c && delete Bt[b]; return e.length ? [["mtd", e.join(".")]] : [] } function Gt() { for (var a = [], b = ma(Object.keys(Ct)), c = b.next(); !c.done; c = b.next()) { var d = c.value; a.push("" + d + Ct[d]) } return a.length ? [["mec", a.join(".")]] : [] } var Ht = {}, It = {}; function Jt(a, b, c) { if (Gf && b) { var d = Bf(b); Ht[a] = Ht[a] || []; Ht[a].push(c + d); var e = (Sc(b) ? "1" : "2") + d; It[a] = It[a] || []; It[a].push(e); Bg(a) } } function Kt(a) { var b = a.eventId, c = a.Oa, d = [], e = Ht[b] || []; e.length && d.push(["tr", e.join(".")]); var f = It[b] || []; f.length && d.push(["ti", f.join(".")]); c && (delete Ht[b], delete It[b]); return d } function Lt(a, b, c, d) { var e = Gc[a], f = Mt(a, b, c, d); if (!f) return null; var h = Tc(e[gc.Zh], c, []); if (h && h.length) { var l = h[0]; f = Lt(l.index, { onSuccess: f, onFailure: l.ri === 1 ? b.terminate : f, terminate: b.terminate }, c, d) } return f }
  function Mt(a, b, c, d) {
    function e() {
      if (f[gc.Qj]) l(); else {
        var w = Rc(f, c, []), y = w[gc.Ti]; if (y != null) for (var x = 0; x < y.length; x++)if (!T(y[x])) { l(); return } var B = dt(c.Tb, String(f[gc.na]), Number(f[gc.vd]), w[gc.Rj]), z = !1; w.vtp_gtmOnSuccess = function () { if (!z) { z = !0; var D = ib() - G; Jt(c.id, Gc[a], "5"); et(c.Tb, B, "success", D); R(62) && ls(c, f, Tq.J.fi); h() } }; w.vtp_gtmOnFailure = function () { if (!z) { z = !0; var D = ib() - G; Jt(c.id, Gc[a], "6"); et(c.Tb, B, "failure", D); R(62) && ls(c, f, Tq.J.ei); l() } }; w.vtp_gtmTagId = f.tag_id; w.vtp_gtmEventId =
          c.id; c.priorityId && (w.vtp_gtmPriorityId = c.priorityId); Jt(c.id, f, "1"); var C = function () { Ch(3); var D = ib() - G; Jt(c.id, f, "7"); et(c.Tb, B, "exception", D); R(62) && ls(c, f, Tq.J.di); z || (z = !0, l()) }; R(62) && ks(c, f); var G = ib(); try { Uc(w, { event: c, index: a, type: 1 }) } catch (D) { C(D) } R(62) && ls(c, f, Tq.J.gi)
      }
    } var f = Gc[a], h = b.onSuccess, l = b.onFailure, m = b.terminate; if (c.isBlocked(f)) return null; var n = Tc(f[gc.hi], c, []); if (n && n.length) {
      var p = n[0], q = Lt(p.index, { onSuccess: h, onFailure: l, terminate: m }, c, d); if (!q) return null; h = q; l = p.ri ===
        2 ? m : q
    } if (f[gc.Rh] || f[gc.Tj]) { var t = f[gc.Rh] ? Hc : c.Rl, r = h, u = l; if (!t[a]) { e = kb(e); var v = Nt(a, t, e); h = v.onSuccess; l = v.onFailure } return function () { t[a](r, u) } } return e
  } function Nt(a, b, c) { var d = [], e = []; b[a] = Ot(d, e, c); return { onSuccess: function () { b[a] = Pt; for (var f = 0; f < d.length; f++)d[f]() }, onFailure: function () { b[a] = Qt; for (var f = 0; f < e.length; f++)e[f]() } } } function Ot(a, b, c) { return function (d, e) { a.push(d); b.push(e); c() } } function Pt(a) { a() } function Qt(a, b) { b() } var Tt = function (a, b) {
    for (var c = [], d = 0; d < Gc.length; d++)if (a[d]) { var e = Gc[d]; var f = ht(b.Tb); try { var h = Lt(d, { onSuccess: f, onFailure: f, terminate: f }, b, d); if (h) { var l = e[gc.na]; if (!l) throw Error("Error: No function name given for function call."); var m = Ic[l]; c.push({ Li: d, Ei: (m ? m.priorityOverride || 0 : 0) || Es(e[gc.na], 1) || 0, execute: h }) } else Rt(d, b), f() } catch (p) { f() } } c.sort(St); for (var n = 0; n < c.length; n++)c[n].execute(); return c.length >
      0
  }; function St(a, b) { var c, d = b.Ei, e = a.Ei; c = d > e ? 1 : d < e ? -1 : 0; var f; if (c !== 0) f = c; else { var h = a.Li, l = b.Li; f = h > l ? 1 : h < l ? -1 : 0 } return f }
  function Rt(a, b) { if (Gf) { var c = function (d) { var e = b.isBlocked(Gc[d]) ? "3" : "4", f = Tc(Gc[d][gc.Zh], b, []); f && f.length && c(f[0].index); Jt(b.id, Gc[d], e); var h = Tc(Gc[d][gc.hi], b, []); h && h.length && c(h[0].index) }; c(a) } } var Wt = !1, Ut;
  var au = function (a) {
    var b = a["gtm.uniqueEventId"], c = a["gtm.priorityId"], d = a.event; if (R(62)) { } if (d === "gtm.js") { if (Wt) return !1; Wt = !0 } var e = !1, f = Is(), h = g(a); if (!f.every(function (r) { return r({ originalEventData: h }) })) { if (d !== "gtm.js" && d !== "gtm.init" && d !== "gtm.init_consent") return !1; e = !0 } xt(b, d); var l = a.eventCallback, m = a.eventTimeout, n = {
      id: b,
      priorityId: c, name: d, isBlocked: Xt(h, e), Rl: [], logMacroError: function () { L(6); Ch(0) }, cachedModelValues: Yt(), Tb: new ct(function () {
        if (R(62)) { } l &&
          l.apply(l, [].slice.call(arguments, 0))
      }, m), originalEventData: h
    }; R(70) && Gf && (n.reportMacroDiscrepancy = Dt); R(62) && gs(n.id, n.name); var p = Xc(n); R(62) && hs(n.id, n.name); e && (p = Zt(p)); if (R(62)) { } var q = Tt(p, n), t = !1; it(n.Tb); d !== "gtm.js" && d !== "gtm.sync" || ot(); return $t(p, q) || t
  }; function Yt() { var a = {}; a.event = Xe("event", 1); a.ecommerce = Xe("ecommerce", 1); a.gtm = Xe("gtm"); a.eventModel = Xe("eventModel"); return a }
  function Xt(a, b) { var c = Ps(); return function (d) { if (c(d)) return !0; var e = d && d[gc.na]; if (!e || typeof e != "string") return !0; e = e.replace(/^_*/, ""); var f, h = Vf(); f = Gs().getRestrictions(0, h); var l = a; b && (l = g(a), l["gtm.uniqueEventId"] = Number.MAX_SAFE_INTEGER); for (var m = He[e] || [], n = ma(f), p = n.next(); !p.done; p = n.next()) { var q = p.value; try { if (!q({ entityId: e, securityGroups: m, originalEventData: l })) return !0 } catch (t) { return !0 } } return !1 } }
  function Zt(a) { for (var b = [], c = 0; c < a.length; c++)if (a[c]) { var d = String(Gc[c][gc.na]); if (te[d] || Gc[c][gc.Uj] !== void 0 || Es(d, 2)) b[c] = !0 } return b } function $t(a, b) { if (!b) return b; for (var c = 0; c < a.length; c++)if (a[c] && Gc[c] && !ue[String(Gc[c][gc.na])]) return !0; return !1 } var bu = 0; function cu() { bu === 1 && Ag() } var du = function (a) { if (!a.oi || bu !== 1) return []; a.fc(); var b = vh(); b.push(["mcc", "1"]); bu = 3; return b }; function eu(a, b) { return arguments.length === 1 ? fu("config", a) : fu("config", a, b) } function gu(a, b, c) { c = c || {}; c[M.g.Rb] = a; return fu("event", b, c) } function fu() { return arguments } var hu = function () { this.messages = []; this.D = [] }; hu.prototype.enqueue = function (a, b, c) { var d = this.messages.length + 1; a["gtm.uniqueEventId"] = b; a["gtm.priorityId"] = d; var e = Object.assign({}, c, { eventId: b, priorityId: d, fromContainerExecution: !0 }), f = { message: a, notBeforeEventId: b, priorityId: d, messageContext: e }; this.messages.push(f); for (var h = 0; h < this.D.length; h++)try { this.D[h](f) } catch (l) { } }; hu.prototype.listen = function (a) { this.D.push(a) };
  hu.prototype.get = function () { for (var a = {}, b = 0; b < this.messages.length; b++) { var c = this.messages[b], d = a[c.notBeforeEventId]; d || (d = [], a[c.notBeforeEventId] = d); d.push(c) } return a }; hu.prototype.prune = function (a) { for (var b = [], c = [], d = 0; d < this.messages.length; d++) { var e = this.messages[d]; e.notBeforeEventId === a ? b.push(e) : c.push(e) } this.messages = c; return b }; function iu(a, b, c) { c.eventMetadata = c.eventMetadata || {}; c.eventMetadata.source_canonical_id = Nf.canonicalContainerId; ju().enqueue(a, b, c) }
  function ku() { var a = lu; ju().listen(a) } function ju() { var a = se.mb; a || (a = new hu, se.mb = a); return a } var mu = {}, nu = {}; function ou(a, b) { for (var c = [], d = [], e = {}, f = 0; f < a.length; e = { kg: void 0, Tf: void 0 }, f++) { var h = a[f]; if (h.indexOf("-") >= 0) { if (e.kg = ui(h, b), e.kg) { var l = Rf(); Za(l, function (t) { return function (r) { return t.kg.da === r } }(e)) ? c.push(h) : d.push(h) } } else { var m = mu[h] || []; e.Tf = {}; m.forEach(function (t) { return function (r) { t.Tf[r] = !0 } }(e)); for (var n = Of(), p = 0; p < n.length; p++)if (e.Tf[n[p]]) { c = c.concat(Rf()); break } var q = nu[h] || []; q.length && (c = c.concat(q)) } } return { ml: c, ol: d } }
  function pu(a) { A(mu, function (b, c) { var d = c.indexOf(a); d >= 0 && c.splice(d, 1) }) } function qu(a) { A(nu, function (b, c) { var d = c.indexOf(a); d >= 0 && c.splice(d, 1) }) } var ru = "HA GF G UA AW DC MC".split(" "), su = !1, tu = !1, uu = !1, vu = !1; function wu(a, b) { a.hasOwnProperty("gtm.uniqueEventId") || Object.defineProperty(a, "gtm.uniqueEventId", { value: Ie() }); b.eventId = a["gtm.uniqueEventId"]; b.priorityId = a["gtm.priorityId"]; return { eventId: b.eventId, priorityId: b.priorityId } } var xu = void 0, yu = void 0;
  function zu(a, b, c) { var d = g(a); d.eventId = void 0; d.inheritParentConfig = void 0; Object.keys(b).some(function (f) { return b[f] !== void 0 }) && L(136); var e = g(b); g(c, e); iu(eu(Of()[0], e), a.eventId, d) } function Au(a) { for (var b = ma([M.g.Ac, M.g.Eb]), c = b.next(); !c.done; c = b.next()) { var d = c.value, e = a && a[d] || ij.F[d]; if (e) return e } }
  var Bu = [M.g.Ac, M.g.Eb, M.g.vc, M.g.ib, M.g.qb, M.g.za, M.g.ra, M.g.Ma, M.g.Ra, M.g.wb], Cu = {
    config: function (a, b) {
      var c = wu(a, b); if (!(a.length < 2) && k(a[1])) {
        var d = {}; if (a.length > 2) { if (a[2] != void 0 && !Ka(a[2]) || a.length > 3) return; d = a[2] } var e = ui(a[1], b.isGtmEvent); if (e) {
          var f, h, l; a: { if (!Lf.pd) { var m = Xf(Yf()); if (hg(m)) { var n = m.parent, p = n.isDestination; l = { vl: Xf(n), kl: p }; break a } } l = void 0 } var q = l; q && (f = q.vl, h = q.kl); xt(c.eventId, "gtag.config"); var t = e.da, r = e.id !== t; if (r ? Rf().indexOf(t) === -1 : Of().indexOf(t) === -1) {
            if (!b.inheritParentConfig &&
              !d[M.g.Ab]) { var u = Au(d); if (r) Us(t, u, { source: 2, fromContainerExecution: b.fromContainerExecution }); else if (f !== void 0 && f.containers.indexOf(t) !== -1) { var v = d; xu ? zu(b, v, xu) : yu || (yu = g(v)) } else Ts(t, u, !0, { source: 2, fromContainerExecution: b.fromContainerExecution }) }
          } else {
            if (f && (L(128), h && L(130), b.inheritParentConfig)) { var w = d; yu ? zu(b, yu, w) : !w[M.g.Sb] && we && xu || (xu = g(w)); return } var y = d; if (!uu && (uu = !0, tu)) for (var x = ma(Bu), B = x.next(); !B.done; B = x.next())if (y.hasOwnProperty(B.value)) { Ah("erc"); break } Hf && !Mf && (bu ===
              1 && Qb(F, "pagehide", cu), bu = 2); if (we && !r && !d[M.g.Sb]) { var z = vu; vu = !0; if (z) return } su || L(43); if (!b.noTargetGroup) if (r) { qu(e.id); var C = e.id, G = d[M.g.je] || "default"; G = String(G).split(","); for (var D = 0; D < G.length; D++) { var E = nu[G[D]] || []; nu[G[D]] = E; E.indexOf(C) < 0 && E.push(C) } } else { pu(e.id); var K = e.id, I = d[M.g.je] || "default"; I = I.toString().split(","); for (var N = 0; N < I.length; N++) { var Q = mu[I[N]] || []; mu[I[N]] = Q; Q.indexOf(K) < 0 && Q.push(K) } } delete d[M.g.je]; var aa = b.eventMetadata || {}; aa.hasOwnProperty("is_external_event") ||
                (aa.is_external_event = !b.fromContainerExecution); b.eventMetadata = aa; delete d[M.g.fd]; for (var W = r ? [e.id] : Rf(), O = 0; O < W.length; O++) { var ha = d, ea = W[O], ca = g(b), da = ui(ea, ca.isGtmEvent); da && ij.push("config", [ha], da, ca) }
          }
        }
      }
    }, consent: function (a, b) { if (a.length === 3) { L(39); var c = wu(a, b), d = a[1], e = a[2]; b.fromContainerExecution || (e[M.g.N] && L(139), e[M.g.oa] && L(140)); d === "default" ? li(e) : d === "update" ? mi(e, c) : d === "declare" && b.fromContainerExecution && ki(e) } }, event: function (a, b) {
      var c = a[1]; if (!(a.length < 2) && k(c)) {
        var d;
        if (a.length > 2) { if (!Ka(a[2]) && a[2] != void 0 || a.length > 3) return; d = a[2] } var e = d, f = {}, h = (f.event = c, f); e && (h.eventModel = g(e), e[M.g.fd] && (h.eventCallback = e[M.g.fd]), e[M.g.fe] && (h.eventTimeout = e[M.g.fe])); var l = wu(a, b), m = l.eventId, n = l.priorityId; h["gtm.uniqueEventId"] = m; n && (h["gtm.priorityId"] = n); if (c === "optimize.callback") return h.eventModel = h.eventModel || {}, h; var p; var q = d, t = q && q[M.g.Rb]; t === void 0 && (t = Re(M.g.Rb, 2), t === void 0 && (t = "default")); if (k(t) || Array.isArray(t)) {
          var r; r = b.isGtmEvent ? k(t) ? [t] : t : t.toString().replace(/\s+/g,
            "").split(","); var u = ou(r, b.isGtmEvent), v = u.ml, w = u.ol; if (w.length) for (var y = Au(q), x = 0; x < w.length; x++) { var B = ui(w[x], b.isGtmEvent); B && Us(B.da, y, { source: 3, fromContainerExecution: b.fromContainerExecution }) } p = vi(v, b.isGtmEvent)
        } else p = void 0; var z = p; if (z) {
          var C; !z.length || ((C = b.eventMetadata) == null ? 0 : C.em_event) || (tu = !0); xt(m, c); for (var G = [], D = 0; D < z.length; D++) {
            var E = z[D], K = g(b); if (ru.indexOf($f(E.prefix)) !== -1) {
              var I = g(d), N = K.eventMetadata || {}; N.hasOwnProperty("is_external_event") || (N.is_external_event =
                !K.fromContainerExecution); K.eventMetadata = N; delete I[M.g.fd]; hj(c, I, E.id, K); Hf && !Mf && bu === 0 && (Pb(F, "pagehide", cu), bu = 1)
            } G.push(E.id)
          } h.eventModel = h.eventModel || {}; z.length > 0 ? h.eventModel[M.g.Rb] = G.join() : delete h.eventModel[M.g.Rb]; su || L(43); b.noGtmEvent === void 0 && b.eventMetadata && b.eventMetadata.syn_or_mod && (b.noGtmEvent = !0); h.eventModel[M.g.zb] && (b.noGtmEvent = !0); return b.noGtmEvent ? void 0 : h
        }
      }
    }, get: function (a, b) {
      L(53); if (a.length === 4 && k(a[1]) && k(a[2]) && Wa(a[3])) {
        var c = ui(a[1], b.isGtmEvent), d = String(a[2]),
        e = a[3]; if (c) { su || L(43); var f = Au(); if (!Za(Rf(), function (l) { return c.da === l })) Us(c.da, f, { source: 4, fromContainerExecution: b.fromContainerExecution }); else if (ru.indexOf($f(c.prefix)) !== -1) { wu(a, b); var h = {}; g((h[M.g.nb] = d, h[M.g.xb] = e, h)); jj(d, function (l) { J(function () { return e(l) }) }, c.id, b) } }
      }
    }, js: function (a, b) { if (a.length == 2 && a[1].getTime) { su = !0; var c = wu(a, b), d = c.eventId, e = c.priorityId, f = {}; return f.event = "gtm.js", f["gtm.start"] = a[1].getTime(), f["gtm.uniqueEventId"] = d, f["gtm.priorityId"] = e, f } }, policy: function () { },
    set: function (a, b) { var c; a.length == 2 && Ka(a[1]) ? c = g(a[1]) : a.length == 3 && k(a[1]) && (c = {}, Ka(a[2]) || Array.isArray(a[2]) ? c[a[1]] = g(a[2]) : c[a[1]] = a[2]); if (c) { var d = wu(a, b), e = d.eventId, f = d.priorityId; g(c); var h = g(c); ij.push("set", [h], void 0, b); c["gtm.uniqueEventId"] = e; f && (c["gtm.priorityId"] = f); delete c.event; b.overwriteModelFields = !0; return c } }
  }, Du = { policy: !0 }; var Fu = function (a) { if (Eu(a)) return a; this.value = a }; Fu.prototype.getUntrustedMessageValue = function () { return this.value }; var Eu = function (a) { return !a || Ha(a) !== "object" || Ka(a) ? !1 : "getUntrustedMessageValue" in a }; Fu.prototype.getUntrustedMessageValue = Fu.prototype.getUntrustedMessageValue; var Gu = !1, Hu = []; function Iu() { if (!Gu) { Gu = !0; for (var a = 0; a < Hu.length; a++)J(Hu[a]) } } function Ju(a) { Gu ? J(a) : Hu.push(a) } var Ku = 0, Lu = {}, Mu = [], Nu = [], Ou = !1, Pu = !1; function Qu(a, b) { return a.messageContext.eventId - b.messageContext.eventId || a.messageContext.priorityId - b.messageContext.priorityId } var Ru = function (a, b) { if (!Xa(b) || b < 0) b = 0; var c = se[re.Ga], d = 0, e = !1, f = void 0; f = F.setTimeout(function () { e || (e = !0, a()); f = void 0 }, b); return function () { var h = c ? c.subscribers : 1; ++d === h && (f && (F.clearTimeout(f), f = void 0), e || (a(), e = !0)) } };
  function Su(a, b) { var c = a._clear || b.overwriteModelFields; A(a, function (e, f) { e !== "_clear" && (c && We(e), We(e, f)) }); Ee || (Ee = a["gtm.start"]); var d = a["gtm.uniqueEventId"]; if (!a.event) return !1; typeof d !== "number" && (d = Ie(), a["gtm.uniqueEventId"] = d, We("gtm.uniqueEventId", d)); return au(a) } function Tu(a) { if (a == null || typeof a !== "object") return !1; if (a.event) return !0; if (cb(a)) { var b = a[0]; if (b === "config" || b === "event" || b === "js" || b === "get") return !0 } return !1 }
  function Uu() {
    var a; if (Nu.length) a = Nu.shift(); else if (Mu.length) a = Mu.shift(); else return; var b; var c = a; if (Ou || !Tu(c.message)) b = c; else {
      Ou = !0; var d = c.message["gtm.uniqueEventId"]; typeof d !== "number" && (d = c.message["gtm.uniqueEventId"] = Ie()); var e = {}, f = { message: (e.event = "gtm.init_consent", e["gtm.uniqueEventId"] = d - 2, e), messageContext: { eventId: d - 2 } }, h = {}, l = { message: (h.event = "gtm.init", h["gtm.uniqueEventId"] = d - 1, h), messageContext: { eventId: d - 1 } }; Mu.unshift(l, c); if (Hf) {
        var m = Nf.ctid; if (m) {
          var n, p = Xf(Yf());
          n = p && p.context; var q = ph(!0), t = Nf.canonicalContainerId, r = uh(), u = n && n.fromContainerExecution, v = Lf.pd, w = n && n.source; qh || (qh = r); sh.push(m + ";" + t + ";" + (u ? 1 : 0) + ";" + (w || 0) + ";" + (v ? 1 : 0)); rh = q; Mg()
        }
      } b = f
    } return b
  }
  function Vu() {
    for (var a = !1, b; !Pu && (b = Uu());) {
      Pu = !0; delete Oe.eventModel; Qe(); var c = b, d = c.message, e = c.messageContext; if (d == null) Pu = !1; else {
        if (e.fromContainerExecution) for (var f = ["gtm.allowlist", "gtm.blocklist", "gtm.whitelist", "gtm.blacklist", "tagTypeBlacklist"], h = 0; h < f.length; h++) { var l = f[h], m = Re(l, 1); if (Array.isArray(m) || Ka(m)) m = g(m); Pe[l] = m } try {
          if (Wa(d)) try { d.call(Se) } catch (B) { } else if (Array.isArray(d)) {
            var n = d; if (k(n[0])) {
              var p = n[0].split("."), q = p.pop(), t = n.slice(1), r = Re(p.join("."), 2); if (r != null) try {
                r[q].apply(r,
                  t)
              } catch (B) { }
            }
          } else { var u = void 0; if (cb(d)) a: { if (d.length && k(d[0])) { var v = Cu[d[0]]; if (v && (!e.fromContainerExecution || !Du[d[0]])) { u = v(d, e); break a } } u = void 0 } else u = d; u && (a = Su(u, e) || a) }
        } finally { e.fromContainerExecution && Qe(!0); var w = d["gtm.uniqueEventId"]; if (typeof w === "number") { for (var y = Lu[String(w)] || [], x = 0; x < y.length; x++)Nu.push(Wu(y[x])); y.length && Nu.sort(Qu); delete Lu[String(w)]; w > Ku && (Ku = w) } Pu = !1 }
      }
    } return !a
  }
  function Xu() {
    if (R(62)) { var a = Yu(); } var b = Vu(); if (R(62)) { } try {
      var c = Tf(), d = F[re.Ga].hide; if (d && d[c] !== void 0 && d.end) {
        d[c] = !1; var e = !0, f; for (f in d) if (d.hasOwnProperty(f) && d[f] ===
          !0) { e = !1; break } e && (d.end(), d.end = null)
      }
    } catch (h) { } return b
  } function lu(a) { if (Ku < a.notBeforeEventId) { var b = String(a.notBeforeEventId); Lu[b] = Lu[b] || []; Lu[b].push(a) } else Nu.push(Wu(a)), Nu.sort(Qu), J(function () { Pu || Vu() }) } function Wu(a) { return { message: a.message, messageContext: a.messageContext } }
  var Zu = function () {
    function a(f) { var h = {}; if (Eu(f)) { var l = f; f = Eu(l) ? l.getUntrustedMessageValue() : void 0; h.fromContainerExecution = !0 } return { message: f, messageContext: h } } var b = Fb(re.Ga, []), c = se[re.Ga] = se[re.Ga] || {}; c.pruned === !0 && L(83); Lu = ju().get(); ku(); $s(function () { if (!c.gtmDom) { c.gtmDom = !0; var f = {}; b.push((f.event = "gtm.dom", f)) } }); Ju(function () { if (!c.gtmLoad) { c.gtmLoad = !0; var f = {}; b.push((f.event = "gtm.load", f)) } }); c.subscribers = (c.subscribers || 0) + 1; var d = b.push; b.push = function () {
      var f; if (se.SANDBOXED_JS_SEMAPHORE >
        0) { f = []; for (var h = 0; h < arguments.length; h++)f[h] = new Fu(arguments[h]) } else f = [].slice.call(arguments, 0); var l = f.map(function (q) { return a(q) }); Mu.push.apply(Mu, l); var m = d.apply(b, f), n = Math.max(100, Number("1000") || 300); if (this.length > n) for (L(4), c.pruned = !0; this.length > n;)this.shift(); var p = typeof m !== "boolean" || m; return Vu() && p
    }; var e = b.slice(0).map(function (f) { return a(f) }); Mu.push.apply(Mu, e); if (Yu()) { if (R(62)) { } J(Xu) }
  }, Yu = function () { var a = !0; return a }; function $u(a) { if (a == null || a.length === 0) return !1; var b = Number(a), c = ib(); return b < c + 3E5 && b > c - 9E5 } var av = !1; function bv() { var a = Wf(); if (a) { var b; return a.canonicalContainerId || "_" + (a.scriptContainerId || ((b = a.destinations) == null ? void 0 : b[0])) } } var cv = function (a) { if (av) return []; var b = [], c = bv(); c && b.push(["pcid", c]); a.Oa && (av = !0, b.length && a.fc()); return b }; var dv = /gtag[.\/]js/, ev = /gtm[.\/]js/, fv = !1; function gv(a) { if (a.scriptSource) { var b; try { var c; b = (c = ac()) == null ? void 0 : c.getEntriesByType("resource") } catch (l) { } if (b) { for (var d = {}, e = 0; e < b.length; ++e) { var f = b[e], h = f.initiatorType; if (h === "script" && f.name === a.scriptSource) return { Jl: e, Kl: d }; d[h] = 1 + (d[h] || 0) } L(146) } else L(145) } } function hv(a) { if (fv) return "1"; var b = a.scriptSource; if (b) { if (dv.test(b)) return "3"; if (ev.test(b)) return "2" } return "0" }
  function iv() {
    if (Hf) {
      var a = Zf(); if (!a) L(144); else if (a.canonicalContainerId) {
        var b = gv(a); if (b) {
          var c = !1; kg.push(function (d) {
            if (c) return []; d.Oa && (c = !0); d.fc(); var e = [["rtg", String(a.canonicalContainerId)], ["rlo", String(b.Jl)], ["slo", String(b.Kl.script || "0")]]; if (R(66)) {
              var f = e.push, h; a: {
                if ((a.scriptContainerId || "").indexOf("GTM-") >= 0) {
                  var l; b: {
                    if (a.scriptSource) {
                      for (var m = Ke.K, n = tf(a.scriptSource), p = m ? n.pathname : "" + n.hostname + n.pathname, q = H.scripts, t = "", r = 0; r < q.length; ++r) {
                        var u = q[r]; if (!(u.innerHTML.length ===
                          0 || !m && u.innerHTML.indexOf(a.scriptContainerId || "SHOULD_NOT_BE_SET") < 0 || u.innerHTML.indexOf(p) < 0)) { if (u.innerHTML.indexOf("(function(w,d,s,l,i)") >= 0) { l = String(r); break b } t = String(r) }
                      } if (t) { l = t; break b }
                    } l = void 0
                  } var v = l; if (v) { fv = !0; h = v; break a }
                } var w = [].slice.call(document.scripts); h = a.scriptElement ? String(w.indexOf(a.scriptElement)) : "-1"
              } f.call(e, ["hlo", h]); e.push(["lst", hv(a)])
            } return e
          })
        }
      }
    }
  }
  var Dv = function () { }; var Ev = function () { }; Ev.prototype.toString = function () { return "undefined" }; var Fv = new Ev; function Mv(a, b) { function c(h) { var l = tf(h), m = pf(l, "protocol"), n = pf(l, "host", !0), p = pf(l, "port"), q = pf(l, "path").toLowerCase().replace(/\/$/, ""); if (m === void 0 || m === "http" && p === "80" || m === "https" && p === "443") m = "web", p = "default"; return [m, n, p, q] } for (var d = c(String(a)), e = c(String(b)), f = 0; f < d.length; f++)if (d[f] !== e[f]) return !1; return !0 }
  function Nv(a) { return Ov(a) ? 1 : 0 }
  function Ov(a) {
    var b = a.arg0, c = a.arg1; if (a.any_of && Array.isArray(c)) { for (var d = 0; d < c.length; d++) { var e = g(a, {}); g({ arg1: c[d], any_of: void 0 }, e); if (Nv(e)) return !0 } return !1 } switch (a["function"]) {
      case "_cn": return String(b).indexOf(String(c)) >= 0; case "_css": var f; a: { if (b) try { for (var h = 0; h < od.length; h++) { var l = od[h]; if (b[l]) { f = b[l](c); break a } } } catch (v) { } f = !1 } return f; case "_ew": var m, n; m = String(b); n = String(c); var p = m.length - n.length; return p >= 0 && m.indexOf(n, p) === p; case "_eq": return String(b) === String(c);
      case "_ge": return Number(b) >= Number(c); case "_gt": return Number(b) > Number(c); case "_lc": return String(b).split(",").indexOf(String(c)) >= 0; case "_le": return Number(b) <= Number(c); case "_lt": return Number(b) < Number(c); case "_re": var q; a: { var t = a.ignore_case ? "i" : void 0; try { var r = String(c) + t, u = pd.get(r); u || (u = new RegExp(c, t), pd.set(r, u)); q = u.test(b); break a } catch (v) { q = !1; break a } } return q; case "_sw": return String(b).indexOf(String(c)) === 0; case "_um": return Mv(b, c)
    }return !1
  } function Pv() { var a; a = a === void 0 ? "" : a; var b, c; return ((b = data) == null ? 0 : (c = b.blob) == null ? 0 : c.hasOwnProperty(1)) ? String(data.blob[1]) : a } function Qv() { var a = [["cv", R(78) ? Pv() : "1"], ["rv", re.Bf], ["tc", Gc.filter(function (b) { return b }).length]]; re.sd && a.push(["x", re.sd]); Ke.D && a.push(["tag_exp", Ke.D]); return a } function Rv() { var a = Dh["0"] || ""; return a.length ? [["exp_geo", a]] : [] } var Sv; function Tv() { try { Sv != null || (Sv = (new Intl.DateTimeFormat).resolvedOptions().timeZone) } catch (b) { } var a; return ((a = Sv) == null ? 0 : a.length) ? [["exp_tz", Sv]] : [] } function gw(a) { var b = se.zones; return b ? b.getIsAllowedFn(Of(), a) : function () { return !0 } }
  function hw() { Hs(function (a) { var b = a.originalEventData["gtm.uniqueEventId"], c = se.zones; return c ? c.isActive(Of(), b) : !0 }); Fs(function (a) { var b, c; b = a.entityId; c = a.securityGroups; return gw(Number(a.originalEventData["gtm.uniqueEventId"]))(b, c) }) } var Uw = function () { var a = !0; Oj(7) && Oj(9) && Oj(10) || (a = !1); return a }; Hh(); function Vx() { return F.gaGlobal = F.gaGlobal || {} } var Wx = function () { var a = Vx(); a.hid = a.hid || $a(); return a.hid }, Xx = function (a, b) { var c = Vx(); if (c.vid == void 0 || b && !c.from_cookie) c.vid = a, c.from_cookie = b }; var Ey = function (a) { this.F = a; this.H = ""; this.D = this.F }, Fy = function (a, b) { a.D = b; return a }, Gy = function (a, b) { a.K = b; return a }; function Hy(a) { var b = a.search; return a.protocol + "//" + a.hostname + a.pathname + (b ? b + "&richsstsse" : "?richsstsse") } function Iy(a, b, c) { if (a) { var d = a || []; if (Array.isArray(d)) for (var e = Ka(b) ? b : {}, f = ma(d), h = f.next(); !h.done; h = f.next())c(h.value, e) } } var Yy = window, Zy = document, $y = function (a) {
    var b = Yy._gaUserPrefs; if (b && b.ioo && b.ioo() || Zy.documentElement.hasAttribute("data-google-analytics-opt-out") || a && Yy["ga-disable-" + a] === !0) return !0; try { var c = Yy.external; if (c && c._gaUserPrefs && c._gaUserPrefs == "oo") return !0 } catch (p) { } for (var d = [], e = String(Zy.cookie).split(";"), f = 0; f < e.length; f++) { var h = e[f].split("="), l = h[0].replace(/^\s*|\s*$/g, ""); if (l && l == "AMP_TOKEN") { var m = h.slice(1).join("=").replace(/^\s*|\s*$/g, ""); m && (m = decodeURIComponent(m)); d.push(m) } } for (var n =
      0; n < d.length; n++)if (d[n] == "$OPT_OUT") return !0; return Zy.getElementById("__gaOptOutExtension") ? !0 : !1
  };
  function kz(a) { A(a, function (c) { c.charAt(0) === "_" && delete a[c] }); var b = a[M.g.Xa] || {}; A(b, function (c) { c.charAt(0) === "_" && delete b[c] }) } var sz = function (a, b) { }; function rz(a, b) { var c = function () { }; return c }
  function tz(a, b, c) { } var uz = rz; var wz = encodeURI, V = encodeURIComponent, xz = Array.isArray, yz = function (a, b, c) { Ob(a, b, c) }, zz = function (a, b) {
    if (!a) return !1;
    var c = pf(tf(a), "host"); if (!c) return !1; for (var d = 0; b && d < b.length; d++) { var e = b[d] && b[d].toLowerCase(); if (e) { var f = c.length - e.length; f > 0 && e.charAt(0) != "." && (f--, e = "." + e); if (f >= 0 && c.indexOf(e, f) == f) return !0 } } return !1
  }, Az = function (a, b, c) { for (var d = {}, e = !1, f = 0; a && f < a.length; f++)a[f] && a[f].hasOwnProperty(b) && a[f].hasOwnProperty(c) && (d[a[f][b]] = a[f][c], e = !0); return e ? d : null }; var Jz = F.clearTimeout, Kz = F.setTimeout, X = function (a, b, c) { if (ek()) { b && J(b) } else return Lb(a, b, c) }, Lz = function () { return F.location.href }, Rz = function (a) { return pf(tf(a), "fragment") }, bA = function (a, b) { return Re(a, b || 2) }, cA = function (a, b, c) { var d; b ? (a.eventCallback = b, c && (a.eventTimeout = c), d = F[re.Ga].push(a)) : d = F[re.Ga].push(a); return d }, dA = function (a, b) { F[a] = b }, Y = function (a, b, c) { b && (F[a] === void 0 || c && !F[a]) && (F[a] = b); return F[a] },
    eA = function (a, b) { if (ek()) { b && J(b) } else Nb(a, b) }, fA = function (a) { return !!Vv(a, "init", !1) }, gA = function (a) { Uv(a).init = !0 }; var hA = {}; var Z = { securityGroups: {} };

  Z.securityGroups.v = ["google"], Z.__v = function (a) { var b = a.vtp_name; if (!b || !b.replace) return !1; var c = bA(b.replace(/\\\./g, "."), a.vtp_dataLayerVersion || 1); return c !== void 0 ? c : a.vtp_defaultValue }, Z.__v.C = "v", Z.__v.isVendorTemplate = !0, Z.__v.priorityOverride = 0, Z.__v.isInfrastructure = !0, Z.__v.runInSiloedMode = !1;
















  Z.securityGroups.get = ["google"], Z.__get = function (a) { var b = a.vtp_settings, c = b.eventParameters || {}, d = String(a.vtp_eventName), e = {}; e.eventId = a.vtp_gtmEventId; e.priorityId = a.vtp_gtmPriorityId; a.vtp_deferrable && (e.deferrable = !0); var f = gu(String(b.streamId), d, c); iu(f, e.eventId, e); a.vtp_gtmOnSuccess() }, Z.__get.C = "get", Z.__get.isVendorTemplate = !0, Z.__get.priorityOverride = 0, Z.__get.isInfrastructure = !1, Z.__get.runInSiloedMode = !1;



  var iA = {}; iA.dataLayer = Se; iA.callback = function (a) { Ge.hasOwnProperty(a) && Wa(Ge[a]) && Ge[a](); delete Ge[a] }; iA.bootstrap = 0; iA._spx = !1;
  function jA() { se[Tf()] = se[Tf()] || iA; dg(); fg() || A(gg(), function (d, e) { Us(d, e.transportUrl, e.context); L(92) }); lb(He, Z.securityGroups); var a = Xf(Yf()), b, c = a == null ? void 0 : (b = a.context) == null ? void 0 : b.source; c !== 2 && c !== 4 && c !== 3 || L(142); Nc = Yc }
  (function (a) {
    function b() { n = H.documentElement.getAttribute("data-tag-assistant-present"); $u(n) && (m = l.Hh) } function c() { m && Eb ? h(m) : a() } if (!F["__TAGGY_INSTALLED"]) { var d = !1; if (H.referrer) { var e = tf(H.referrer); d = of(e, "host") === "cct.google" } if (!d) { var f = qk("googTaggyReferrer"); d = !(!f.length || !f[0].length) } d && (F["__TAGGY_INSTALLED"] = !0, Lb("https://cct.google/taggy/agent.js")) } var h = function (u) {
      var v = "GTM", w = "GTM"; xe && (v = "OGT", w = "GTAG"); var y = F["google.tagmanager.debugui2.queue"]; y || (y =
        [], F["google.tagmanager.debugui2.queue"] = y, Lb("https://" + re.Nc + "/debug/bootstrap?id=" + Nf.ctid + "&src=" + w + "&cond=" + u + "&gtm=" + gk())); var x = { messageType: "CONTAINER_STARTING", data: { scriptSource: Eb, containerProduct: v, debug: !1, id: Nf.ctid, targetRef: { ctid: Nf.ctid, isDestination: Lf.pd }, aliases: Pf(), destinations: Sf() } }; x.data.resume = function () { a() }; re.Ui && (x.data.initialPublish = !0); y.push(x)
    }, l = { Lj: 1, Jh: 2, Wh: 3, Fg: 4, Hh: 5 }; l[l.Lj] = "GTM_DEBUG_LEGACY_PARAM"; l[l.Jh] = "GTM_DEBUG_PARAM"; l[l.Wh] = "REFERRER"; l[l.Fg] = "COOKIE"; l[l.Hh] = "EXTENSION_PARAM";
    var m = void 0, n = void 0, p = pf(F.location, "query", !1, void 0, "gtm_debug"); $u(p) && (m = l.Jh); if (!m && H.referrer) { var q = tf(H.referrer); of(q, "host") === "tagassistant.google.com" && (m = l.Wh) } if (!m) { var t = qk("__TAG_ASSISTANT"); t.length && t[0].length && (m = l.Fg) } m || b(); if (!m && n && n.indexOf("pending:") === 0 && $u(n.substr(8))) { var r = !1; Pb(H, "TADebugSignal", function () { r || (r = !0, b(), c()) }, !1); F.setTimeout(function () { r || (r = !0, b(), c()) }, 200) } else c()
  })(function () {
    try {
      bg(); if (R(62)) { } Ph().F(); Mj(); ri(); var a = Vf(); if (Jf().canonical[a]) { var b = se.zones; b && b.unregisterChild(Of()); Gs().removeExternalRestrictions(Vf()); } else {
        { } Ke.D = "0"; Ke.F = Ke.K; Ke.H = ""; Ke.U = "ad_storage|analytics_storage|ad_user_data|ad_personalization"; Ke.P = "ad_storage|analytics_storage|ad_user_data"; Ke.Ja = "";
        Rs(); for (var c = data.resource || {}, d = c.macros || [], e = 0; e < d.length; e++)Dc.push(d[e]); for (var f = c.tags || [], h = 0; h < f.length; h++)Gc.push(f[h]); for (var l = c.predicates || [], m = 0; m < l.length; m++)Fc.push(l[m]); for (var n = c.rules || [], p = 0; p < n.length; p++) { for (var q = n[p], t = {}, r = 0; r < q.length; r++) { var u = q[r][0]; t[u] = Array.prototype.slice.call(q[r], 1); u !== "if" && u !== "unless" || Mc(t[u]) } Ec.push(t) } Ic = Z; Jc = Nv; jA(); if (!Be) for (var v = Dh["6"] !== !1 ? Me(Ke.P) : Me(Ke.U), w = 0; w < hi.length; w++) {
          var y = hi[w], x = y, B = v[y] ? "granted" : "denied";
          Kh().implicit(x, B)
        } Zu(); Vs = !1; Ws = 0; if (H.readyState === "interactive" && !H.createEventObject || H.readyState === "complete") Ys(); else { Pb(H, "DOMContentLoaded", Ys); Pb(H, "readystatechange", Ys); if (H.createEventObject && H.documentElement.doScroll) { var z = !0; try { z = !F.frameElement } catch (E) { } z && Zs() } Pb(F, "load", Ys) } Gu = !1; H.readyState === "complete" ? Iu() : Pb(F, "load", Iu);
        Gf && (og(Eg), F.setInterval(Dg, 864E5)); Pg(); og(Qv); og(zt); og(Uq); og(gj); og(Kt); kg.push(Ni); og(Dp); xh(); R(70) && (og(Et), og(Ft), og(Gt)); Gf && R(58) && (og(Rv), og(Tv)); iv(); R(41) || kg.push(Bh); kg.push(du); if (R(41)) { var G = bv(); G && Jg("pcid", G) } else kg.push(cv); Dv(); Ch(1); hw(); Fe = ib(); iA.bootstrap = Fe;
        if (R(62)) { }
      }
    } catch (E) { if (Ch(4), Gf) { var D = wg(!1, !0, !0); Ob(D) } }
  });

})()

