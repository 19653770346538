import sdkConfig from "@/config/web.config";
import { setmMxpanelValue, setmMxpanelData, setmMxpanelUserInfo } from "@/utils/mixpanel"


// 创建混入对象 
const mixinTemplate = {
    props: {
        templateData: {
            default: null
        },
        animationLoading: {
            default: false
        }
    },
    data() {
        return {
            activeIndex: null, // 当前点击的 item 索引
            onBoardingInfo: {},
            templateValue: [],
            isSubmit: false,
            sdkConfig: {}
        };
    },
    computed: {
        getTemplatePaddingTop() {
            return this.$store.getters.currentTemplateFlow.isStepPage ? '80px' : '50px'
        }
    },
    //获取当前模版选项数据
    watch: {
        "animationLoading": {
            handler(bool) {
                if (bool) {
                    window.scrollTo(0, 0); //浏览器
                    this.initTemplate(); // 初始化之前的选择记录
                }
            },
            immediate: true,
            deep: true,
        },
        "templateData": {
            handler(templateData) {
                if (templateData && templateData.screenName && sdkConfig.onboardingViewScreenNameList.includes(templateData.screenName)) {
                    //加载时需要埋点
                    this.setmMxpanelValue(sdkConfig.mixpanelConfig.onboardingEventKey, templateData.screenName);
                }
            },
            immediate: true,
            deep: true,
        }
    },
    created() {
        this.sdkConfig = sdkConfig;
    },
    methods: {
        //设置按钮动点击缩放效果
        setOptionClickAnimation(index) {
            this.activeIndex = index; // 更新当前点击的项
            // 重置动画效果
            setTimeout(() => {
                this.activeIndex = null;
            }, 300); // 动画持续时间
        },
        // 存储用户属性
        setmMxpanelUserInfoClick() {
            let userInfo = sdkConfig.onboardingUserInfoScreenNameList.find(item => {
                return item.screenName == this.templateData.screenName
            });
            let value
            if (userInfo) {
                if (userInfo.type == 1) {
                    value = this.templateValue
                } else {
                    if (typeof this.templateValue == 'string') {
                        value = [this.templateValue.toLowerCase()]
                    } else {
                        value = []
                        if (this.templateValue.length) {
                            this.templateValue.forEach(item => {
                                value.push(item.toLowerCase())
                            })
                        }
                    }
                }
                let obj = {};
                obj[userInfo.key] = value;
                setmMxpanelUserInfo(obj)
            }
        },
        setmMxpanelValue(eventKey, key, value) {
            setmMxpanelValue(eventKey, key, value)
        },
        setmMxpanelData() {
            //不需要埋点
            if (!sdkConfig.onboardingClickNoNeedScreenNameList.includes(this.templateData.screenName)) {
                setmMxpanelData(this.sdkConfig.mixpanelConfig.onboardingEventKey, this.templateData.screenName, this.templateValue)
            }
        },

        //模版初始化
        initTemplate() {
            if (!this.templateData) return
            let key = sdkConfig.onBoardingKey[this.templateData.screenName];
            if (!key) key = this.templateData.screenName;
            //获取之前收集的记录
            let recordInfo = this.$store.state.onBoardingRecordInfo[key];
            if (recordInfo) {
                //时间戳转日期
                if (this.templateData.screenName == 'eventDateScreen') {
                    this.templateValue = this.$utils.formattedDate(recordInfo, 'MM/DD/YYYY');
                } else {
                    this.templateValue = recordInfo;
                }
            } else {
                if (['emailScreen', 'emailConfirmScreen', 'nameScreen'].includes(this.templateData.screenName)) {
                    this.templateValue = ""
                } else {
                    this.templateValue = []
                }

            }
            this.isSubmit = false; //避免同一模版无法提交

        },
        // 单选
        selectOption(option, isNext = true, languageOption) {
            let key = sdkConfig.onBoardingKey[this.templateData.screenName];
            if (!key) key = this.templateData.screenName;
            if (this.isSubmit) return
            this.isSubmit = true;
            //时间戳转日期
            if (key == 'eventDateScreen') {
                this.templateValue = this.$utils.formattedDate(option, 'MM/DD/YYYY');
            } else {
                this.templateValue = option;
            }
            //存入当前语言内容，多语言展示
            if (sdkConfig.summaryOptionList.includes(this.templateData.screenName) && option) {
                this.$store.commit("updateOnBoardingRecord", {
                    key: this.templateData.screenName + 'Option',
                    value: languageOption

                });
            }
            this.updateOnBoardingRecord({
                key: this.templateData.screenName,
                value: option
            })
            if (isNext) {
                this.setmMxpanelData()//埋点
                this.contimueClick();
            } else {
                //只保存答案不跳转
                this.updateOnBoardingRecord({
                    key: this.templateData.screenName,
                    value: option
                })

            }
        },
        // 多选
        selectOptions(option, index) {
            this.templateValue[index] ?
                this.$set(this.templateValue, index, null)
                : this.$set(this.templateValue, index, option)
        },
        //记录信息
        updateOnBoardingRecord(info) {
            this.$store.commit('updateOnBoardingRecord', info)
        },
        contimueClick(record) {
            if (record) {
                if (this.isSubmit) return;
                let bool = this.templateValue.filter(item => item).length;//多选是否选择了选项
                if (!bool) return
                this.isSubmit = true;
                this.updateOnBoardingRecord({
                    key: this.templateData.screenName,
                    value: this.templateValue
                })
                this.setmMxpanelData()//埋点
            } else {
                this.isSubmit = true;
            }
            this.setmMxpanelUserInfoClick();
            setTimeout(() => {
                this.jump();
            }, 500);
        },

        // 跳转下一个模版
        jump() {
            this.$store.commit('nextTemplate');
        },
    }

}
export default mixinTemplate
