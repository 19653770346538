<template>
    <div ref="container" class="progress-container">
        <canvas class="canvas" ref="canvas">
        </canvas>
        <div class="circle-box">
            <div ref="circle" class="circle">
                <img class="circle_icon" src="@img/circle_icon.png" alt="">
            </div>
            <div>
                <div class="circle_titel title_1 ">{{ title }}</div>
                <div class="circle_text text_1">{{ text }}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        title: { //标题内容
            type: String,
            default: ""
        },
        text: { //文本内容
            type: String,
            default: ""
        },
        progress: { //当前进度
            type: Number,
            required: true,
            default: 0
        },
        lineWidth: { //轨道宽度
            type: Number,
            default: 12
        },
        strokeColor: {  //滑块背景色
            type: String,
            default: "#ff5722"
        },
        backgroundColor: { //轨道背景色
            type: String,
            default: "#ddd"
        },
        circleRadius: {
            type: Number,
            default: 10 // 跟随进度的圆圈半径
        },
        circleFillColor: {
            type: String,
            default: "#fff" // 跟随进度的圆圈填充颜色
        },
        circleStrokeColor: {
            type: String,
            default: "#ff5722" // 跟随进度的圆圈边框颜色
        },
        circleLineWidth: {
            type: Number,
            default: 4 // 圆圈的边框宽度
        },
        animationDuration: {
            type: Number,
            default: 10 // 动画时长，单位毫秒
        },
        padding: {
            type: Number,
            default: 30 // 内部 padding
        }
    },
    data() {
        return {

            currentProgress: 0 // 当前进度值，用于动画
        };
    },
    watch: {
        progress: {
            immediate: true,
            handler(newValue) {
                this.animateProgress(newValue);
            }
        }
    },
    methods: {
        setCanvasSize() {
            const container = this.$refs.container;
            const circle = this.$refs.circle;
            const canvas = this.$refs.canvas;
            const diffWidth = 100 + this.padding;
            const dpr = window.devicePixelRatio || 1;
            const width = container.clientWidth;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${width}px`;
            canvas.width = width * dpr;
            canvas.height = width * dpr;
            const ctx = canvas.getContext('2d');
            ctx.scale(dpr, dpr);
            circle.style.width = `${width - diffWidth}px`;
            circle.style.height = `${width - diffWidth}px`;
            circle.style.marginLeft = `${(width - (width - diffWidth)) / 2}px`;

            this.size = width;

            this.drawProgress(this.currentProgress);
        },
        drawProgress(progress) {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext("2d");

            const radius = (this.size - this.lineWidth) / 2 - this.padding;
            const centerX = this.size / 2;
            const centerY = (this.size / 2) - this.padding / 2; // 调整centerY以适应上下padding

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // 绘制背景弧线 (仅绘制半圆)
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, Math.PI, 0, false); // 从左边开始到右边绘制
            ctx.lineWidth = this.lineWidth;
            ctx.strokeStyle = this.backgroundColor;
            ctx.stroke();

            // 计算进度的结束角度
            let endAngle = Math.PI * (1 + Number(progress / 100));
            if (endAngle > Math.PI * 2) {
                endAngle = Math.PI * 2;
            } else if (endAngle < Math.PI) {
                endAngle = Math.PI
            }

            // 绘制进度弧线 (仅绘制半圆)
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, Math.PI, endAngle, false);
            ctx.lineWidth = this.lineWidth;
            ctx.strokeStyle = this.strokeColor;
            ctx.stroke();

            // 确保圆圈在进度为 0 和 100 时显示完整
            let circleX, circleY;
            circleX = centerX + radius * Math.cos(endAngle);
            circleY = centerY + radius * Math.sin(endAngle);
            if (progress === 0) {
                circleX = centerX - radius;
                circleY = centerY;
            } else if (progress >= 100) {
                circleX = centerX + radius;
                circleY = centerY;
            }

            // 绘制圆圈填充颜色
            ctx.beginPath();
            ctx.arc(circleX, circleY, this.circleRadius, 0, 2 * Math.PI, false);
            ctx.fillStyle = this.circleFillColor;
            ctx.fill();

            // 绘制圆圈边框
            ctx.lineWidth = this.circleLineWidth;
            ctx.strokeStyle = this.circleStrokeColor;
            ctx.stroke();
        }
        ,
        animateProgress(targetProgress) {
            const startProgress = this.currentProgress;
            const startTime = performance.now();

            const animate = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const progressRatio = Math.min(elapsedTime / this.animationDuration, 1);
                this.currentProgress = startProgress + (targetProgress - startProgress) * progressRatio;

                this.drawProgress(this.currentProgress);

                if (progressRatio < 1) {
                    requestAnimationFrame(animate);
                }
            };

            requestAnimationFrame(animate);
        }
    },
    mounted() {
        this.setCanvasSize();

        window.addEventListener('resize', this.setCanvasSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setCanvasSize);
    }
};
</script>

<style scoped lang="scss">
.progress-container {
    width: 100%;
    max-width: 400px;
    margin: 40px auto;
    position: relative;

    .circle-box {
        width: 100%;
        position: absolute;
        z-index: 99;
        top: 50px;

        .circle {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            background-color: var(--bgColor);
            box-shadow: 0 0 30px #d8d8d9;
            border-radius: 50%;

            .circle_icon {
                height: 25%;
            }
        }

        .circle_titel {
            margin-top: 30px;

            text-align: center;
            color: var(--theme_01);

        }

        .circle_text {
            font-weight: 400;
            text-align: center;
            color: var(--theme_02);
        }

    }
}
</style>
