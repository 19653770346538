<template>
  <div class="templateMultiTag  ">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
        <div class="sub-title text_2 tc" v-html="templateData.languageConfig[$language].mainText"></div>
        <van-checkbox-group class="option-group flex" v-model="templateValue">
          <van-checkbox icon="-" class="option-item" :name="item.originName"
            :class="{ 'shrink-grow-effect': activeIndex === index }" @click="setOptionClickAnimation(index)""
            v-for=" (item, index) in templateData.languageConfig[$language].optionList" :key="index">
            <div class="tc text_1">
              {{ item.option }}
            </div>
          </van-checkbox>
        </van-checkbox-group>
      </div>
    </div>
    <div class="btn-warp" v-show="animationLoading && templateData">
      <div @click="contimueClick(true), setOptionClickAnimation(-1)" class="button text_1"
        :class="{ active: templateValue != '', 'shrink-grow-effect': activeIndex === -1 }"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";

export default {
  name: "templateMultiTag",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
};
</script>
<style scoped lang="scss">
.templateMultiTag {
  .option-group {
    max-width: 540px;
    margin: 30px auto;
    justify-content: center;
    text-align: center;

    .option-item {
      min-height: 60px !important;
      width: auto !important;
      margin: 0 8px 16px 8px;
      padding: 16px;

      /deep/.van-checkbox__icon {
        display: none !important;
      }

      /deep/.van-checkbox__label {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
