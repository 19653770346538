<template>
    <div class="slider-container">
        <div class="range-container">
            <input type="range" :min="min" :max="max" :step="step" v-model="currentValue" @input="handleInput"
                @change="handleChange">
            <div class="range-ticks">
                <div v-for=" item in points" :key="item" @click.stop="scaleClick(item)"></div>
            </div>
        </div>
        <!-- 标签 -->
        <div class="range-sacle text_2">
            <span v-for="item in pointLabels" :key="item">{{ item }}</span>
        </div>
    </div>
</template>
<script>

export default {
    name: "sliderRange",
    props: {
        value: {
            type: Number,
            required: true
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        points: {
            type: Array,
            default: () => [0, 33, 66, 100]
        },
        pointLabels: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            modelValue: 0, // 当前点位置
            currentValue: 0, // 当前滑块值，用于检测滑动位置
        }
    },

    methods: {
        // 刻度点击
        scaleClick(item) {
            this.modelValue = item;
            this.currentValue = item;
            this.updateValue()

        },
        updateValue() {
            this.$emit('input', Number(this.currentValue));
        },


        handleInput() {
            const closestPoint = this.getClosestPoint(this.currentValue);
            const diff = this.currentValue - closestPoint;
            // 如果滑块超过最近点的一半，移动到下一个点
            if (Math.abs(diff) >= 16.5) {
                this.modelValue = diff > 0 ? this.points[this.points.indexOf(closestPoint) + 1] : this.points[this.points.indexOf(closestPoint) - 1];
            } else {
                this.modelValue = closestPoint;
            }
            this.updateValue()
        },

        handleChange() {
            // 更新滑块位置以匹配新位置
            this.currentValue = this.modelValue;
            this.updateValue()
        },
        getClosestPoint(modelValue) {
            return this.points.reduce((prev, curr) => {
                return Math.abs(curr - modelValue) < Math.abs(prev - modelValue) ? curr : prev;
            });
        },
    }


};
</script>
<style scoped lang="scss">
.templateSider {
    /deep/.van-slider__button-wrapper {
        z-index: 9;
    }

    /deep/ .van-slider__button {
        background-color: var(--bgColor) !important;

    }

    /* 容器样式 */
    .range-container {
        position: relative;
        width: 100%;
    }

    /* 自定义滑块样式 */
    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 8px;
        /* background: #ddd; */
        background: none;
        outline: none;
        border-radius: 4px;
        margin: 0;
        position: relative;
        z-index: 2;
        /* 滑块的层级高于刻度标记 */
    }

    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 8px;
        /* background: #007bff; */
        background: none;
        border-radius: 4px;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        background: var(--bgColor);
        border: 3px solid var(--mainColor);
        border-radius: 50%;
        cursor: pointer;
        margin-top: 8px;
        position: relative;
        z-index: 3;
        /* 手柄的层级更高，确保覆盖刻度标记 */
    }

    /* 刻度线容器 */
    .range-ticks {
        padding: 0 5px;
        position: absolute;
        /* 位置调整为与手柄底部对齐 */
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        z-index: 1;
        /* 刻度标记的层级低于滑块 */
        background: #f2eaec;
        border-radius: 10px;
    }

    /* 刻度标记 */
    .range-ticks div {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #bea4ab;
        position: relative;
        top: 7px;
    }

    .range-sacle {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--theme_02);
    }


}
</style>