<template>
  <div class="templateWeight ">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
        <div class="type-tabs">
          <div class="type-item" @click="typeChange(item)" :class="{ active: currentType == item }"
            v-for="item in typeList" :key="item">
            {{ item }}
          </div>
        </div>
        <div class="ipt-warp flex" v-show="currentType == 'kg'">
          <div class="ipt-item title_3">
            <van-field type="number" @focus="clearDefaultValue($event, 1)" @blur="clearDefaultValue($event, 2)"
              :style="{ width: inputWidth + 'px' }" ref="kgIptRef" class="ipt" :class="{ noData: kgValue == 0 }"
              v-model="kgValue" />
            <div>kg</div>
          </div>
        </div>
        <div class="ipt-warp flex" v-show="currentType == 'lbs'">
          <div class="ipt-item title_3">
            <van-field @focus="clearDefaultValue($event, 1)" @blur="clearDefaultValue($event, 2)" type="number"
              :style="{ width: inputWidth + 'px' }" class="ipt" :class="{ noData: lbsValue == 0 }" v-model="lbsValue" />
            <div>lbs</div>
          </div>
        </div>
        <span class="weightInputSpan" ref="weightInputSpanRef"></span>
        <div class="ipt-tip title_3 tip-color tc">
          <span class="amimation-height" :class="isTipVisible ? 'tip-visible' : 'hidden'">
            {{ getInputTip }}</span>
        </div>

        <div class="amimation-height" :class="heigthTip ? 'weight-visible' : 'hidden'">
          <div class="height-tip " v-if="tipInfo" :style="{ background: tipInfo.background }">
            <div class="flex align-center">
              <img class="icon" :src="require(`@img/${tipInfo.icon}`)" alt="">
              <div class="title text_1">{{ $utils.replaceTextValue(tipInfo.YourBmi, bmi) }} <span
                  :style="{ color: tipInfo.color }">
                  {{ tipInfo.name }}
                </span>
                {{ tipInfo.name == $t.normal ? $t.BMI : '' }}
              </div>
            </div>
            <div class="sub-title text_3">
              {{ tipInfo.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-warp" v-show="animationLoading && templateData">
      <div @click="validateClick(), setOptionClickAnimation(-1)"
        :class="{ active: validInputValue(), 'shrink-grow-effect': activeIndex === -1 }" class="button text_1"
        v-html="templateData.languageConfig[$language].buttonText">
      </div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import webConfig from "@/config/web.config/index";
import { setmMxpanelUserValue } from "@/utils/mixpanel"
export default {
  name: "templateWeight",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      heigthTip: false,
      inputWidth: 40,
      typeList: ["lbs", "kg"],
      currentType: "lbs",
      kgValue: 0,
      lbsValue: 0,
      minKg: 30,
      maxKg: 250,
      minLbs: 66,
      maxLbs: 551,
      bmi: 0,
      tipInfo: null,
      isActive: false
    };
  },
  watch: {
    kgValue(newValue, oldValue) {
      this.updateWeight('kg', newValue, oldValue);
    },
    lbsValue(newValue, oldValue) {
      this.updateWeight('lbs', newValue, oldValue);
    }
  },
  /**
   * 当组件挂载后，根据用户单位偏好设置体重值
   * 此函数的目的是根据用户的单位偏好（公斤或磅），从状态中获取体重信息，并更新组件的体重值
   */
  mounted() {
    // 根据别名获取体重键的值
    const key = this.$utils.getKey(this.templateData.screenName);
    // 获取重量单位键的值
    const weightUnitKey = this.$utils.getKey('weightUnit');
    // 从状态中获取体重值
    const weight = this.$store.state.onBoardingRecordInfo[key];

    // 如果体重值存在
    if (weight) {
      // 从状态中获取用户选择的体重单位
      const weightUnit = this.$store.state.onBoardingRecordInfo[weightUnitKey];
      // 根据体重单位设置当前类型为公斤或磅
      this.currentType = (weightUnit === webConfig.weightKgUnitNumber) ? 'kg' : 'lbs';
      // 如果网页配置的体重类型为0（表示使用公斤）
      if (webConfig.weightType === 0) {
        // 直接将获取的体重值赋给公斤值
        this.kgValue = weight;
        // 将公斤值转换为磅，并赋给磅值
        this.lbsValue = this.$utils.kgToLbs(weight, this.minLbs, this.maxLbs);
      } else {
        // 否则，将获取的体重值赋给磅值
        this.lbsValue = weight;
        // 将磅值转换为公斤，并赋给公斤值
        this.kgValue = this.$utils.lbsToKg(this.lbsValue, this.minKg, this.maxKg);
      }
    }
  },
  computed: {
    //获取inputTips文本提示
    getInputTip() {
      const unit = this.currentType === 'kg' ? 'KG' : 'LBS';
      const minValue = this.currentType === 'kg' ? this.minKg : this.minLbs;
      const maxValue = this.currentType === 'kg' ? this.maxKg : this.maxLbs;
      return `${this.$t.EnterAValueFrom} ${minValue} ${this.$t.to} ${maxValue} ${unit}`;
    },
    // 返回提示的可见性状态
    isTipVisible() {
      const value = this.currentType === 'kg' ? this.kgValue : this.lbsValue;
      return Number(value) && !this.validInputValue();
    }

  },
  methods: {
    /**
 * 更新体重信息，并重新计算BMI指数
 * @param {string} type - 体重的单位类型，可以是'kg'或'lbs'
 * @param {string|number} newValue - 输入的新体重值
 * @param {string|number} oldValue - 输入的旧体重值
 */
    updateWeight(type, newValue, oldValue) {
      // 获取当前存储的身高信息
      const height = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('heightScreen')];
      let weight;

      // 根据传入的单位类型，更新对应的体重值
      if (type === 'kg') {
        this.kgValue = this.inputValueWatch(newValue, oldValue);
        weight = this.kgValue;
      } else if (type === 'lbs') {
        this.lbsValue = this.inputValueWatch(newValue, oldValue);
        weight = this.$utils.lbsToKg(this.lbsValue)
      }

      // 根据新的身高和体重值计算BMI指数
      this.bmi = this.$utils.calculateBMI(height, weight);

      // 更新输入框的宽度
      this.updateInputWidth();

      // 重新获取BMI的配置信息，以更新展示
      this.getBMIConfig();
    },
    // 更新输入框宽度
    updateInputWidth() {
      this.$nextTick(() => {
        const span = this.$refs.weightInputSpanRef;
        const value = this.currentType === 'kg' ? this.kgValue : this.lbsValue;
        if (span) {
          span.textContent = value || '0';
          this.inputWidth = span.offsetWidth + 10;// 这里的 10 是额外宽度，用于确保足够的空间
        }
      })
    },
    // 焦点事件 获取焦点时值为0则改为‘’，失去焦点值为‘’改为0
    clearDefaultValue(e, type) {
      if (type == 1) {
        e.target.value == 0 ? e.target.value = '' : ''
      } else {
        e.target.value = e.target.value ? e.target.value : 0
      }
    },
    // 监听输入框value变化
    inputValueWatch(newValue, oldValue) {
      newValue = newValue ? newValue.toString() : ''
      // 去除前导零（只针对整数部分）
      if (newValue.includes('.')) {
        const parts = newValue.split('.');
        parts[0] = parseInt(parts[0], 10).toString(); // 处理整数部分
        newValue = parts.join('.');
      } else {
        newValue = parseInt(newValue, 10).toString(); // 处理纯整数
      }
      // 只允许数字和小数点
      let formattedValue = newValue.replace(/[^\d.]/g, '');
      // 限制只能有一个小数点
      const parts = formattedValue.split('.');
      if (parts.length > 2) {
        formattedValue = `${parts[0]}.${parts[1]}`;
      }
      // 限制小数位数为1位
      if (parts[1] && parts[1].length > 1) {
        formattedValue = `${parts[0]}.${parts[1].slice(0, 1)}`;
      }
      // 限制最多3位数字（包括整数和小数部分）
      if (formattedValue.length > 3 && !formattedValue.includes('.')) {
        formattedValue = formattedValue.slice(0, 3);
      } else if (formattedValue.length > 5) {  // 例子: "999.9"
        //防止输入完成后重新聚焦修整数部分数字大于max
        if (Math.floor(formattedValue)) {
          formattedValue = oldValue
        } else {
          formattedValue = formattedValue.slice(0, 5);
        }
      }
      return formattedValue
    },

    // 获取bmi描述信息
    getBMIConfig() {
      this.heigthTip = !!this.bmi;
      if (!this.heigthTip) return;
      const bmiRanges = [
        { condition: this.bmi <= this.$utils.initUnderweight, config: { name: this.$t.underweight, YourBmi: this.$t.YourBmi1, icon: 'bmi01.png', background: '#EED6D6', color: '#F24822', text: this.$t.weightBmiDescription1 } },
        { condition: this.bmi > this.$utils.initUnderweight && this.bmi <= this.$utils.initNormal, config: { name: this.$t.normal, YourBmi: this.$t.YourBmi2, icon: 'bmi02.png', background: '#CFDED1', color: '#34C759', text: this.$t.weightBmiDescription2 } },
        { condition: this.bmi > this.$utils.initNormal && this.bmi <= this.$utils.initOverweight, config: { name: this.$t.overweight, YourBmi: this.$t.YourBmi3, icon: 'bmi03.png', background: '#F3EBD4', color: '#FFA800', text: this.$t.weightBmiDescription3 } },
        { condition: this.bmi > this.$utils.initOverweight, config: { name: this.$t.obese, YourBmi: this.$t.YourBmi4, icon: 'bmi01.png', background: '#EED6D6', color: '#F24822', text: this.$t.weightBmiDescription4 } }
      ];
      const matchingRange = bmiRanges.find(range => range.condition);
      if (matchingRange) {
        this.tipInfo = matchingRange.config;
      }
    },

    // 切换体重类型
    typeChange(item) {
      this.currentType = item;
      if (this.currentType == "kg") {
        this.kgValue = this.$utils.lbsToKg(this.lbsValue, this.minKg, this.maxKg);
      } else {
        this.lbsValue = this.$utils.kgToLbs(this.kgValue, this.minLbs, this.maxLbs);
      }
      this.updateInputWidth()
    },
    //验证输入值是否在可填范围内
    validInputValue() {
      const value = this.currentType === 'lbs' ? this.lbsValue : this.kgValue;
      const minValue = this.currentType === 'lbs' ? this.minLbs : this.minKg;
      const maxValue = this.currentType === 'lbs' ? this.maxLbs : this.maxKg;
      return value && value >= minValue && value <= maxValue;
    },
    validateClick(isNext = true) {
      if (this.validInputValue()) {
        //输入是否符合规则
        if (this.currentType == "lbs") {
          this.kgValue = this.$utils.lbsToKg(this.lbsValue);
        } else {
          this.lbsValue = this.$utils.kgToLbs(this.kgValue);
        }

        this.updateOnBoardingRecord({
          key: 'weightUnit',
          value: this.currentType == 'kg' ? webConfig.weightKgUnitNumber : (webConfig.weightKgUnitNumber == 1 ? 0 : 1)
        })
        setmMxpanelUserValue('user_bmi', this.bmi)
        this.selectOption(webConfig.weightType == 0 ? this.kgValue * 1 : this.lbsValue * 1, isNext);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.templateWeight {
  .type-tabs {
    margin: 50px auto;
    padding: 2px;
    display: flex;
    width: 220px;
    height: 45px;
    background-color: var(--theme_03);
    border-radius: 8px;

    .type-item {
      width: 50%;
      font-size: 14px;
      font-weight: 600;
      line-height: 41px;
      text-align: center;
      color: var(--theme_01);
      border-radius: 8px;
      cursor: pointer;
    }

    .active {
      color: var(--theme_03);
      background-color: var(--mainColor);
    }
  }

  .ipt-warp {
    margin: 0 auto;
    margin-top: 80px;
    text-align: center;
    justify-content: center;

    .ipt-item {
      display: flex;
      align-items: flex-end;
      font-weight: 700;

      color: var(--theme_01);

      div {
        font-size: 14px;
        font-weight: 600;
      }

      .ipt {
        background: none;
        min-width: 35px;
        margin-right: 5px;

        /deep/.van-field__control {
          display: inline;
          width: 100%;
          height: 55px;
          font-size: 48px;
          min-width: 30px;
          line-height: 52px;
          font-weight: 700;
          text-align: center;
          color: var(--theme_01);
        }


      }

      /deep/.van-cell {
        padding: 0 !important;
      }

      /deep/ .van-cell::after {
        border: none;
      }

      .noData {
        /deep/.van-field__control {
          color: var(--theme_02);
        }
      }


    }
  }

  .weightInputSpan {
    visibility: hidden;
    white-space: pre;
    font-size: 48px;
    font-weight: 700;
    line-height: 52px;

  }

  .ipt-tip {
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    height: 18px;
  }



  .height-tip {
    margin: 20px auto;
    padding: 12px 16px;
    width: 100%;
    border-radius: 12px;

    .icon {
      margin-right: 5px;
      height: 24px;
    }

    .title {
      flex: 1;
      position: relative;
      margin-top: 0;
    }
  }

}
</style>
