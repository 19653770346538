// 参考文档：https://firebase.google.cn/docs/web/setup?hl=zh-cn
import webConfig from "@/config/web.config/index.js"
import { initializeApp } from 'firebase/app';
import { mergeMixpanelinfo } from "@/utils/mixpanel"
import store from "@/store";
import utils from "./utils";
import {
    signInWithCustomToken,
    getAuth, signInAnonymously, EmailAuthProvider, linkWithCredential,
    signInWithEmailAndPassword, signOut,
    fetchSignInMethodsForEmail, updateEmail, onAuthStateChanged,
    updatePassword, sendPasswordResetEmail
} from "firebase/auth";
import { getFirestore, collection, getDocs, setDoc, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore/lite';

//项目配置
const firebaseConfig = webConfig.firebaseConfig;
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
let currentPath, currentPathUid;
let userOnAuthInfo = null;
const routes = [
    '/brand-page',
    '/generated-questionnaire-page',
    '/paywall-page',
    '/discount-paywall-page',
    '/payment-succeed-page',
    '/user-profile-page',
]
let redirectPath = '/get-start-page';
//侦听器 监听用户登陆状态
onAuthStateChanged(auth, (user) => {
    console.log(user);

    currentPath = window.location.pathname;
    currentPathUid = getUrlParams(window.location).get("uid");
    if (user && user.uid) {
        window.sessionStorage.setItem('uid', user.uid);
        userOnAuthInfo = user;
    }
    //登陆失效清除数据从新回到onbording流程
    if ((routes.findIndex(item => item == currentPath) != -1) && !user) {
        //从邮箱跳入不验证
        if (currentPath == '/paywall-page' && currentPathUid) return
        resetPage();//重置页面
    }

    if (user && !user.isAnonymous) {
        getFirestoreDataByUid(
            user.uid
        ).then(res => {
            //账号被删除
            if (res && res.isDeletedAccount) {
                store.commit("updateuserInfo", null);
                signOutWithEmail();// 退出登陆
            } else {
                mergeMixpanelinfo(user.uid)
                store.commit("updateuserInfo", user)
            }
        })

    }
    refreshFirebaseToken(user);
    // 定期刷新令牌
    setInterval(() => {
        refreshFirebaseToken(user);
    }, 2 * 60 * 1000); // 每 2 分钟刷新一次令牌

});
// 重置页面权限
function resetPage() {
    window.sessionStorage.removeItem("onBoardingRecordInfo");
    window.sessionStorage.removeItem("paywallCountDownTime")
    window.sessionStorage.removeItem("paywallDiscountCountDownTime")
    if (currentPath == '/brand-page') {
        redirectPath = '/brand-page'
    } else if (currentPath == '/user-profile-page') {
        store.commit("updateuserInfo", null);
        redirectPath = '/main-page';
    }
    utils.routerReplace(redirectPath)

}
//获取路由参数
function getUrlParams(url) {
    const search = url.search;
    const params = new URLSearchParams(search);
    return params;
}
// 刷新idtoken--token过期为1小时过期 
export function refreshFirebaseToken(user = userOnAuthInfo) {
    if (user) {
        user.getIdToken(true).then((idToken) => {
            console.log("Refreshed token:", idToken);
        }).catch((error) => {
            console.error("Error refreshing token:", error);
        });
    }
}
// 删除用户token--用于测试，模拟token失效
export function deleteUserToken(user = userOnAuthInfo) {
    if (user) {
        user.delete().then((idToken) => {
            console.log("delete token:", idToken);

        }).catch((error) => {
            console.log("delete token error:", error);

        });
    }
}

// 邮箱登陆
export function signInWithEmail(email, password) {
    return new Promise(function (resolve, reject) {
        signInWithEmailAndPassword(auth, email, password).then(({ user }) => {
            resolve(user)
        }).catch(err => {
            reject(err)
        })
    })
}

//更新邮箱密码
export function updateEmailPassword(newPassword) {
    return new Promise(function (resolve, reject) {
        updatePassword(auth.currentUser, newPassword).then(res => {

            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
//发送邮件重置密码
export function sendEmailRestPassword(email) {
    return new Promise(function (resolve, reject) {
        sendPasswordResetEmail(auth, email).then(res => {
            resolve(true)
        }).catch(err => {
            console.log('发送邮件重置密码失败');
            reject(err)
        })
    })
}

//退出邮箱登陆
export async function signOutWithEmail(isClear = true) {
    return new Promise(function (resolve, reject) {
        signOut(auth).then(res => {
            if (isClear) {
                window.sessionStorage.removeItem("onBoardingRecordInfo");
                window.sessionStorage.removeItem("paywallCountDownTime")
                window.sessionStorage.removeItem("paywallDiscountCountDownTime")
            }
            resolve(true)
        }).catch(err => {
            reject(false)
        })
    })


}

// 更改邮箱
export async function updateEmailInfo(email) {
    return new Promise(function (resolve, reject) {
        updateEmail(auth.currentUser, email).then(res => {
            resolve(true)
        }).catch(err => {
            reject(err)
        })
    })

}
//通过邮箱验证账号是否为正式账号
export async function signInMethodsForEmail(email) {
    return new Promise(function (resolve, reject) {
        fetchSignInMethodsForEmail(auth, email).then((signInMethods) => {
            resolve(signInMethods)
        }).catch(err => {
            reject([])
        })
    })
}


//创建模拟账号
export async function createAnonymousAccount() {
    try {
        let { user } = (await signInAnonymously(auth));

        console.log('创建的匿名账号：' + user.uid);
        return user.uid;
    } catch (error) {
        console.log('创建模拟账号，获取uid错误：' + error);
    }
}
//获取匿名用户登陆状态
export function getAnonymousAuth() {
    return auth.currentUser;
}
// 匿名账号转正式账号
export async function createAccount(email, password) {
    const credential = EmailAuthProvider.credential(email, password);
    linkWithCredential(auth.currentUser, credential);
    return new Promise(function (resolve, reject) {
        linkWithCredential(auth.currentUser, credential).then(res => {
            console.log(JSON.stringify(res));
            resolve(res)
        }).catch(err => {
            console.log('匿名账号转正式账号失败：' + JSON.stringify(err));
            reject(err)
        })
    })
}
//获取集合类数据列表
export async function getFirestoreCollList(collectionPath = firebaseConfig.defaultCollectionPath) {
    try {
        const firestoreColection = collection(db, collectionPath);
        const snapshot = await getDocs(firestoreColection);
        const list = snapshot.docs.map(doc => doc.data());
        return list;
    } catch (error) {
        console.log('获取firestore集合数据错误：' + error);
    }
}
// 通过documentId获取数据
export async function getFirestoreDataByUid(documentId, collectionPath = firebaseConfig.defaultCollectionPath) {
    try {
        const docRef = doc(db, collectionPath, documentId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null
    } catch (error) {
        console.log('通过ducumenntId获取firestore数据错误：' + error);
    }
}
//firestore添加数据
export async function addFirestoreData(documentId, data, collectionPath = firebaseConfig.defaultCollectionPath) {
    try {
        const documentRef = doc(db, collectionPath, documentId);
        await setDoc(documentRef, data);
    } catch (error) {
        console.log(`${collectionPath}/${documentId}新增数据错误：` + error);
    }
}
//firestore修改数据
export function updateFirestoreData(documentId, data, collectionPath = firebaseConfig.defaultCollectionPath) {
    try {
        const documentRef = doc(db, collectionPath, documentId);
        updateDoc(documentRef, data);
    } catch (error) {
        console.log(`${collectionPath}/${documentId}修改数据错误：` + error);
    }
}
//firestore删除数据
export async function deleteFirestoreData(documentId, collectionPath = firebaseConfig.defaultCollectionPath) {
    try {
        await deleteDoc(doc(db, collectionPath, documentId));
    } catch (error) {
        console.log(`${collectionPath}/${documentId}删除数据错误：` + error);
    }
}
