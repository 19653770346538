// 文档地址：https://docs.statsig.com/client/javascript-sdk
import { StatsigClient } from '@statsig/js-client';
import webConfig from "@/config/web.config";
import store from '@/store';
import utils from '@/utils/utils';
// 初始化Statsig
export async function initStatsig() {
    let userID = window.localStorage.getItem('statsigUserID') || utils.generateUUID();
    window.localStorage.setItem('statsigUserID', userID);
    const statsigClient = new StatsigClient(
        webConfig.statsigConfig.statsigClientKey,
        { userID },
        { environment: { tier: webConfig.statsigConfig.tier } }
    );
    try {
        // 初始化并等待获取最新的值
        await statsigClient.initializeAsync();
        return statsigClient; // 返回客户端实例
    } catch (error) {
        console.error('Failed to initialize Statsig Client', error);
        return null; // 初始化失败时返回 null
    }
}
// 获取参数
export async function getStatsigExperimentParam(experimentName, paramName, defaultValue = webConfig.statsigConfig.defaultValue) {
    try {
        if (store.state.statsigClient) {
            const experiment = store.state.statsigClient.getExperiment(experimentName);
            return experiment.get(paramName, defaultValue);
        } else {
            const statsigClient = await initStatsig();
            const experiment = statsigClient.getExperiment(experimentName);
            return experiment.get(paramName, defaultValue);
        }



    } catch (error) {
        console.error(`Failed to get experiment param for ${experimentName}`, error);
        return defaultValue; // 如果出现错误，返回默认值
    }
}


//发送事件
export async function sendStatsigEvent(eventName, value, metadata = {}) {
    eventName = `OB ${eventName} Web`
    try {
        if (store.state.statsigClient) {
            store.state.statsigClient.logEvent({
                eventName,
                value,
                metadata,
            });
        }

    } catch (error) {
        console.error(`Failed to log event "${eventName}"`, error);
    }
}

