<template>
    <div class="logo-box ">
        <div class="logo-group flex align-center">
            <van-icon v-show="!showBack && show" @click="back()" name="arrow-left" class="back" />
            <img src="@img/logo.png" height="24px" width="24px" class="logo" alt="">
            <span class="logo-text title_2">{{ webConfig.websiteName }}</span>
        </div>
    </div>
</template>
<script>
import webConfig from '@/config/web.config/index'
export default {
    name: 'logo',

    data() {
        return {
            webConfig: null
        }
    },
    props: {
        templalteIntro: {
            default: null
        },
        show: {
            default: true
        }
    },
    computed: {
        showBack() {
            //过滤不展示返回键的模版别名
            return this.templalteIntro ? webConfig.onboardingHiddenBackScreenNameList.includes(this.templalteIntro.screenName) : false
        }
    },
    created() {
        this.webConfig = webConfig
    },
    methods: {
        back() {

            let eventScreenJumpNumber = window.sessionStorage.getItem('eventScreenJumpNumber');
            if (eventScreenJumpNumber) {
                window.sessionStorage.removeItem('eventScreenJumpNumber');
                this.$store.commit('lastTemplate', Number(eventScreenJumpNumber))
            } else {
                this.$store.commit('lastTemplate')
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.logo-box {
    height: 44px;
    background-color: var(--bgColor);
}

.logo-group {
    min-width: 340px;
    padding: 0px 20px;
    width: 100%;
    // max-width: 768px;
    height: 100%;

    .back {
        margin-left: -5px;
        padding-right: 10px;
        color: var(--theme_01);
        font-size: 24px;
        font-weight: 700;
        cursor: pointer;
    }

    .logo {
        margin-right: var(--logoMarginRight);
        height: 24px;
    }

}

@media (max-width: 576px) {
    .logo-group {

        .logo {
            height: 24px;
        }

        .logo-text {
            font-size: 17px;
        }

    }

}
</style>