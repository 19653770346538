<template>
  <div class="templatePromo    tc">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="content">
          <img class="bg_img left" src="@img/Vector_left.png" alt="" />
          <img class="bg_img right" src="@img/Vector_right.png" alt="" />
          <div class="title_1" v-html="templateData.languageConfig[$language].mainTitle"></div>
          <div class="heading_1 title" v-html="templateData.languageConfig[$language].customersNum"></div>
          <div class="text_1" v-html="templateData.languageConfig[$language].subTitle"></div>
          <div class="text_2 theme_02 tips_1"
            v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].tips1)"></div>
        </div>
        <div class="text_2 theme_02 tips_2" v-html="templateData.languageConfig[$language].tips2"></div>
      </div>
    </div>
    <div class="btn-warp" v-show="animationLoading && templateData">
      <div @click="contimueClick(), setOptionClickAnimation(-1)" class="button active text_1"
        :class="{ 'shrink-grow-effect': activeIndex === -1 }"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";

export default {
  name: "templatePromo",
  mixins: [mixinTemplate],
  components: {
    Head,
  },

};
</script>
<style scoped lang="scss">
.templatePromo {
  .text_1 {
    margin-top: 10px;
  }

  .content {
    position: relative;
    padding: 84px 20px;
    height: 406px;
    background: var(--theme_03);
    border-radius: 8px;
    border: 1px solid #e4e4e4;

    .title {
      margin-top: 36px;
    }

    .tips_1 {
      margin-top: 50px;
    }

    .bg_img {
      width: 55px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .left {
      left: 35px;
    }

    .right {
      right: 35px;
    }
  }

  .tips_2 {
    margin-top: 25px;
  }
}

@media (max-width: 576px) {
  .templatePromo .content {
    .bg_img {
      width: 35px;
    }
  }
}

@media (max-width: 375px) {
  .templatePromo .content {
    .left {
      left: 20px;
    }

    .right {
      right: 20px;
    }
  }
}
</style>
