<template>
    <div class="templateConfirm">

        <Head></Head>
        <div class="template-content" v-if="animationLoading && templateData">
            <div class="animation">
                <div class="title title_1 tc"
                    v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"></div>
                <div class="btn-warp">
                    <div @click="submitClick()" class="button active text_1">
                        <van-loading v-if="isLoading" color="" />
                        <span v-else>{{ templateData.languageConfig[$language].buttonText }}</span>
                    </div>
                </div>
                <div @click="selectOption(false)" class="tip text_3 theme_01 tc  pointer">
                    {{ templateData.languageConfig[$language].rejectText }}
                </div>
            </div>
        </div>
    </div>
</template>
</template>
<script>

import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import mainConfig from "@/config/onboarding.config/main"
import { sendTempMail } from "@/api/system"

export default {
    name: "templateConfirm",
    mixins: [mixinTemplate],
    components: {
        Head
    },
    data() {
        return {
            isLoading: false
        }
    },
    methods: {

        async submitClick() {
            let mixpanelSessionId = window.sessionStorage.getItem('mixpanelDeviceId'); //优先读取缓存
            this.isLoading = true;
            const urlParams = this.$utils.getUrlParams(window.location.href);
            let urlQuery = JSON.parse(window.sessionStorage.getItem('urlQuery')) || {};
            urlQuery = Object.assign(urlParams, urlQuery);
            urlQuery.uid = this.$store.state.onBoardingRecordInfo.uid;
            urlQuery.mixpanelDeviceId = mixpanelSessionId;
            let path = '/paywall-page'
            // 将 params 转换为 URL 查询参数
            let queryString = new URLSearchParams(urlQuery).toString();
            // 将查询参数附加到路径
            path = `${path}?${queryString}`;
            let weightUnit = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('weightUnit')] ? 'lbs' : 'kg'
            let data = {
                data: {
                    //兼容oog120：age weightBefore weightAfter payLink
                    //兼容00g104 :pay_link_url download_link_url privacy_link_url
                    age: this.$store.state.onBoardingRecordInfo[this.$utils.getKey('ageScreen')], //年龄
                    weightBefore: this.$store.state.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')] + ' ' + weightUnit, //当前体重
                    weightAfter: this.$store.state.onBoardingRecordInfo[this.$utils.getKey('targetWeightScreen')] + ' ' + weightUnit, //目标体重
                    payLink: window.location.origin + path, //邮箱跳回网站地址
                    pay_link_url: window.location.origin + path, //邮箱跳回网站地址  
                    download_link_url: mainConfig.iosDeepLinkUrl, //下载地址
                    privacy_link_url: window.location.origin + '/policy-page?policyType=privacyPolicy', //隐私政策地址
                    uid: this.$store.state.onBoardingRecordInfo.uid
                },
                dest: this.$store.state.onBoardingRecordInfo[this.$utils.getKey('emailScreen')],//邮箱地址
                type: 1,//	type, 1->link 2->createAccount 3->paySuccess
            }
            await sendTempMail(data);
            this.isLoading = false;
            this.selectOption(true)
        }
    }
}
</script>
<style scoped lang="scss">
.templateConfirm {

    .btn-warp {
        position: relative !important;

        padding: 50px 0 20px 0 !important;

        .button {
            position: relative;

            .loading {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, - 50%);
                z-index: 99;
                background: none;
            }
        }
    }
}
</style>