<template>
  <div class="templateSider ">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="heading_1 title tc"
          v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"></div>
        <div class="title_2 sub-title tc" v-html="templateData.languageConfig[$language].subTitle"></div>
        <div class="text_1 tc" v-html="templateData.languageConfig[$language].tips"></div>
        <circleProgress :progress="sliderValue" :title="$t.circleProgressTitel" :text="$t.circleProgressText" />
        <slider-range v-model="sliderValue" :points="[0, 33, 66, 100]" :pointLabels="$t.pointLabel1" />
      </div>
    </div>
  </div>
</template>
<script>
import mixinTemplate from "@/mixin/index";
import Head from "./components/head.vue"
import circleProgress from "@/components/echarts/circleProgress.vue";
import sliderRange from "@/components/echarts/sliderRange.vue";
export default {
  name: "templateSider",
  mixins: [mixinTemplate],
  components: {
    Head,
    circleProgress,
    sliderRange
  },

  data() {
    return {

      sliderValue: 0, // 当前点位置
      images: [
        require('@img/template/focus-image-1.png'), // 根据路径更新图片
        require('@img/template/focus-image-2.png'), // 根据路径更新图片
        require('@img/template/focus-image-3.png'), // 根据路径更新图片
        require('@img/template/focus-image-4.png'), // 根据路径更新图片

      ],
    }
  },
  methods: {
    getImgIndex() {
      this.imgIndex = this.points.findIndex(item => item == this.value)
    },    // 主要点点击

  }


};
</script>