<template>
  <div class="templateEmail ">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc"
          v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"></div>
        <van-field left-icon="/img/email.png"
          :right-icon="emailValidate ? (emailValidate == 2 ? 'checked' : 'clear') : ''" class="ipt" @input="emailInput"
          v-model="templateValue" :placeholder="$t.emailPage.emailPlaceholder">
        </van-field>
        <div class="ipt-tip text_4 tip-color tl">
          <span v-show="emailValidate == 1">{{ $t.createAccount.tips1 }}</span>
        </div>
        <div class="perfect text_4" v-show="emailValidate == 2">{{ $t.Perfect }}</div>
        <div class="chosen-us flex align-center">
          <van-icon name="star" class="icon" size="24" />
          <span class="flex-auto" v-html="templateData.languageConfig[$language].richText"> </span>
        </div>
        <div class="policy text_4 flex align-center">
          <img src="@img/lock.png" width="20" class="icon" alt="" />
          <span class="flex-auto text_4 theme_02">{{ $t.WeRespect }}
            <span class="link" @click="$utils.routerPush(`/policy-page?policyType=${$t.menuInfo.links[0].type}`)">{{
              $t.menuInfo.links[0].name
            }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="btn-warp" v-show="animationLoading && templateData">
      <div @click="emailValidateClick(), setOptionClickAnimation(-1)"
        :class="{ active: $utils.emailValidate(templateValue), 'shrink-grow-effect': activeIndex === -1 }"
        class="button text_1">
        {{ templateData.languageConfig[$language].buttonText }}
      </div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";

export default {
  name: "templateEmail",
  mixins: [mixinTemplate],
  data() {
    return {
      templateValue: "",
      emailValidate: 0,
    };
  },
  components: {
    Head,
  },
  mounted() {
    setTimeout(() => {
      if (this.templateValue) {
        this.emailInput(this.templateValue);
      }
    }, 100);
  },
  methods: {
    emailInput(val) {
      this.emailValidate = val ? (this.$utils.emailValidate(val) ? 2 : 1) : 0;
    },
    emailValidateClick() {
      if (this.templateValue && this.emailValidate == 2) {
        this.selectOption(this.templateValue);
      } else {
        this.emailValidate = 1;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.templateEmail {


  .ipt {
    margin-top: 32px;
    padding: 15px;
    height: 58px;
    line-height: 58px;
    border-radius: 8px;
    border: 1px solid #e4e4e4;
    background: var(--theme_03);

    /deep/.van-field__control {
      font-size: 15px;
      color: var(--theme_01);
    }
  }

  .ipt-tip {
    margin-top: 5px;
  }

  .van-cell {
    color: var(--theme_02);
  }

  /deep/.van-field__left-icon {
    margin-top: 5px;
  }

  /deep/.van-field__body {
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
  }

  /deep/.van-icon-envelop-o {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: var(--theme_02);
  }

  /deep/.van-icon-clear {
    font-size: 24px;
    color: rgba(218, 75, 75, 1);
  }

  /deep/.van-icon-checked {
    font-size: 24px;
    color: #26c934;
  }

  .perfect {
    color: #57d01f;
  }

  .chosen-us {
    margin: 40px 0;
    padding: 12px 10px;
    border-radius: 12px;
    background: #e2ebee;
    font-size: 15px;
    font-weight: 600;
  }

  .icon {
    margin-right: 10px;
    color: #5e9e90;
  }

  .policy {
    padding: 12px 10px;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
